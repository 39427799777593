import { apiServer, ngChatAPI } from './../config/AppConfig';
import axios from 'axios';

function setHeaderXDevice() {
    return {
        "X-DEVICE": "web",
    };
}

const LoginService = {
    login(username, password) {
        return axios({
            method: 'post',
            url: apiServer + '/api/login',
            crossOrigin: true,
            data: {
                id: username,
                password: password
            },
        })
    },
    loginChat(username, password) {
        return axios({
            method: 'post',
            url: apiServer + '/v1/user/login',
            timeout: 5000,
            data: {
                userID: username,
                password: password
            },
        })
    },
    loginNgChat(username, password) {
        return axios({
            method: 'post',
            url: ngChatAPI + '/tara/v5/auth/login',
            headers: setHeaderXDevice(),
            crossOrigin: true,
            data: {
                userName: username,
                password: password
            },
        })
    },
}

export default LoginService
