import React from 'react'
import styles from './forgotpassword.module.css'

class ForgotPassword extends React.Component{
    render(){
        return(
            <div className={styles.container}>
                <div className={styles.marginTop}></div>
                <div className={styles.formContainer}>
                    <div className={styles.forgotPasswordCaption}><p>Masukkan email untuk mengirim</p>
                        <p>link reset password</p></div>
                    <div className={styles.emailCaption}>
                        Email
                    </div>
                    <div>
                        <input type="text" className={styles.emailInputText}/>
                    </div>
                    <div className={styles.sendButtonContainer}>
                        <input type="button" value="Kirim" className={styles.sendButton}/>
                    </div>
                </div>
            </div>
        )
    }
}
export default ForgotPassword
