import { apiServer } from "./../config/AppConfig";
import axios from "axios";

function setHeaderToken(token) {
  return {
    "X-AUTH-TOKEN-GC": token,
  };
}

const ReportService = {
  getAllDistributorList(token) {
    return axios({
      method: "get",
      url: apiServer + "/lo/sif/distributor/allRealActiveList",
      timeout: 5000,
      headers: setHeaderToken(token),
      crossOrigin: true,
    });
  },

  getAllPrincipal(token) {
    return axios({
      method: "get",
      url: apiServer + "/lo/sif/principal/allActiveList",
      timeout: 5000,
      headers: setHeaderToken(token),
      crossOrigin: true,
    });
  },

  getDistributorListByPrincipal(token, principalId) {
    return axios({
      method: "get",
      url: apiServer + "/lo/sif/distributor/allRealActiveList",
      params: {
        principalId: principalId,
      },
      timeout: 5000,
      headers: setHeaderToken(token),
      crossOrigin: true,
    });
  },

  downloadDailyTransactionPdfReport(token, startDate, endDate, sellerId, principalId) {
    if (sellerId === "") sellerId = null;
    if (principalId === "") principalId = null;
    return axios({
      method: "get",
      url: apiServer + "/lo/report/dailyTransaction/pdf",
      params: {
        startDate: startDate,
        endDate: endDate,
        sellerId: sellerId,
        principalId: principalId,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadDailyTransactionCsvReport(token, startDate, endDate, sellerId, principalId) {
    if (sellerId === "") sellerId = null;
    if (principalId === "") principalId = null;
    return axios({
      method: "get",
      url: apiServer + "/lo/report/dailyTransaction/csv",
      params: {
        startDate: startDate,
        endDate: endDate,
        sellerId: sellerId,
        principalId: principalId,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadDailyTransactionExcelReport(token, startDate, endDate, sellerId, principalId) {
    if (sellerId === "") sellerId = null;
    if (principalId === "") principalId = null;
    return axios({
      method: "get",
      url: apiServer + "/lo/report/dailyTransaction/excel",
      params: {
        startDate: startDate,
        endDate: endDate,
        sellerId: sellerId,
        principalId: principalId,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadDailyTransactionDetailPdfReport(token, startDate, endDate, sellerId, principalId) {
    if (sellerId === "") sellerId = null;
    if (principalId === "") principalId = null;
    return axios({
      method: "get",
      url: apiServer + "/lo/report/dailyTransaction/detail/pdf",
      params: {
        startDate: startDate,
        endDate: endDate,
        sellerId: sellerId,
        principalId: principalId,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadDailyTransactionDetailCsvReport(token, startDate, endDate, sellerId, principalId) {
    if (sellerId === "") sellerId = null;
    if (principalId === "") principalId = null;
    return axios({
      method: "get",
      url: apiServer + "/lo/report/dailyTransaction/detail/csv",
      params: {
        startDate: startDate,
        endDate: endDate,
        sellerId: sellerId,
        principalId: principalId,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadDailyTransactionDetailExcelReport(token, startDate, endDate, sellerId, principalId) {
    if (sellerId === "") sellerId = null;
    if (principalId === "") principalId = null;
    return axios({
      method: "get",
      url: apiServer + "/lo/report/dailyTransaction/detail/excel",
      params: {
        startDate: startDate,
        endDate: endDate,
        sellerId: sellerId,
        principalId: principalId,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadDailyTransactionBySellerPdfReport(
    token,
    startDate,
    endDate,
    sellerId,
    principalId
  ) {
    if (sellerId === "") sellerId = null;
    if (principalId === "") principalId = null;
    return axios({
      method: "get",
      url: apiServer + "/lo/report/dailyTransaction/seller/pdf",
      params: {
        startDate: startDate,
        endDate: endDate,
        sellerId: sellerId,
        principalId: principalId,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadDailyTransactionBySellerCsvReport(
    token,
    startDate,
    endDate,
    sellerId,
    principalId
  ) {
    if (sellerId === "") sellerId = null;
    if (principalId === "") principalId = null;
    return axios({
      method: "get",
      url: apiServer + "/lo/report/dailyTransaction/seller/csv",
      params: {
        startDate: startDate,
        endDate: endDate,
        sellerId: sellerId,
        principalId: principalId,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadDailyTransactionBySellerExcelReport(
    token,
    startDate,
    endDate,
    sellerId,
    principalId
  ) {
    if (sellerId === "") sellerId = null;
    if (principalId === "") principalId = null;
    return axios({
      method: "get",
      url: apiServer + "/lo/report/dailyTransaction/seller/excel",
      params: {
        startDate: startDate,
        endDate: endDate,
        sellerId: sellerId,
        principalId: principalId,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadActiveBuyerPdfReport(token, startDate, endDate) {
    return axios({
      method: "get",
      url: apiServer + "/lo/report/buyer/active/pdf",
      params: {
        startDate: startDate,
        endDate: endDate,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadActiveBuyerCsvReport(token, startDate, endDate) {
    return axios({
      method: "get",
      url: apiServer + "/lo/report/buyer/active/csv",
      params: {
        startDate: startDate,
        endDate: endDate,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadActiveBuyerExcelReport(token, startDate, endDate) {
    return axios({
      method: "get",
      url: apiServer + "/lo/report/buyer/active/excel",
      params: {
        startDate: startDate,
        endDate: endDate,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadActiveSellerPdfReport(token, startDate, endDate) {
    return axios({
      method: "get",
      url: apiServer + "/lo/report/seller/active/pdf",
      params: {
        startDate: startDate,
        endDate: endDate,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadActiveSellerCsvReport(token, startDate, endDate) {
    return axios({
      method: "get",
      url: apiServer + "/lo/report/seller/active/csv",
      params: {
        startDate: startDate,
        endDate: endDate,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadActiveSellerExcelReport(token, startDate, endDate) {
    return axios({
      method: "get",
      url: apiServer + "/lo/report/seller/active/excel",
      params: {
        startDate: startDate,
        endDate: endDate,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadFulfillmentRatePdfReport(token, startDate, endDate, sellerId, principalId) {
    if (sellerId === "") sellerId = null;
    if (principalId === "") principalId = null;
    return axios({
      method: "get",
      url: apiServer + "/lo/report/fulfillmentRate/pdf",
      params: {
        startDate: startDate,
        endDate: endDate,
        sellerId: sellerId,
        principalId: principalId,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadFulfillmentRateCsvReport(token, startDate, endDate, sellerId, principalId) {
    if (sellerId === "") sellerId = null;
    if (principalId === "") principalId = null;
    return axios({
      method: "get",
      url: apiServer + "/lo/report/fulfillmentRate/csv",
      params: {
        startDate: startDate,
        endDate: endDate,
        sellerId: sellerId,
        principalId: principalId,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadFulfillmentRateExcelReport(token, startDate, endDate, sellerId, principalId) {
    if (sellerId === "") sellerId = null;
    if (principalId === "") principalId = null;
    return axios({
      method: "get",
      url: apiServer + "/lo/report/fulfillmentRate/excel",
      params: {
        startDate: startDate,
        endDate: endDate,
        sellerId: sellerId,
        principalId: principalId,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadDailyTransactionByBuyerSellerPdfReport(
    token,
    startDate,
    endDate,
    sellerId,
    principalId
  ) {
    if (sellerId === "") sellerId = null;
    if (principalId === "") principalId = null;
    return axios({
      method: "get",
      url: apiServer + "/lo/report/dailyTransaction/buyerSeller/pdf",
      params: {
        startDate: startDate,
        endDate: endDate,
        sellerId: sellerId,
        principalId: principalId,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadDailyTransactionByBuyerSellerCsvReport(
    token,
    startDate,
    endDate,
    sellerId,
    principalId
  ) {
    if (sellerId === "") sellerId = null;
    if (principalId === "") principalId = null;
    return axios({
      method: "get",
      url: apiServer + "/lo/report/dailyTransaction/buyerSeller/csv",
      params: {
        startDate: startDate,
        endDate: endDate,
        sellerId: sellerId,
        principalId: principalId,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadDailyTransactionByBuyerSellerExcelReport(
    token,
    startDate,
    endDate,
    sellerId,
    principalId
  ) {
    if (sellerId === "") sellerId = null;
    if (principalId === "") principalId = null;
    return axios({
      method: "get",
      url: apiServer + "/lo/report/dailyTransaction/buyerSeller/excel",
      params: {
        startDate: startDate,
        endDate: endDate,
        sellerId: sellerId,
        principalId: principalId,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadCustomerRegistrationPdfReport(token, startDate, endDate) {
    return axios({
      method: "get",
      url: apiServer + "/lo/report/buyer/register/pdf",
      params: {
        startDate: startDate,
        endDate: endDate,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadCustomerRegistrationCsvReport(token, startDate, endDate) {
    return axios({
      method: "get",
      url: apiServer + "/lo/report/buyer/register/csv",
      params: {
        startDate: startDate,
        endDate: endDate,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadCustomerRegistrationExcelReport(token, startDate, endDate) {
    return axios({
      method: "get",
      url: apiServer + "/lo/report/buyer/register/excel",
      params: {
        startDate: startDate,
        endDate: endDate,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },
};

export default ReportService;
