import React from "react";
import {
  LOCALIZATION_STRING_EN,
  LOCALIZATION_STRING_ID,
} from "../../localization_string/localization_global";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import {
  Select,
  Form,
  Space,
  Input,
  Button,
  Divider,
  DatePicker,
  Radio,
  Upload,
  Modal
}
  from "antd";
import moment from "moment";
import { compose } from "redux";
import { withRouter, Link } from "react-router-dom";
import { USER_PROFILE } from "../../_constants/userProfile.constants";
import "./user_profile.css";
import "../user_management/user_management.css"
import AreaService from "../../services/areaService";
import UploadService from "../../services/upload.service";
import { PlusOutlined } from '@ant-design/icons';
import UserProfileService from "../../services/userProfileService";
import { errorNotificationAlert } from "../notificationAlert/notificationAlert";
import { USER_MANAGEMENT } from "../../_constants/userManagement.constants";
import { modalConfirmation, modalInfo } from "../modalCustom/modalCustom.js";
import UserManagementService from "../../services/userManagementService";
import { userReducerAction } from "../../store/reducers/userReducer";

class UserProfileForm extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      locale: "id",
      locale_string: {},
      headerLabel: "",
      pageEdit: false,
      form: {
        principalName: null,
        userLevel: null,
        email: null,
        username: null,
        firstName: null,
        lastName: null,
        placeOfBirth: null,
        phoneNumber: null,
        gender: null,
        dateOfBirth: null,
        rt_rw: null,
        address1: null,
        address2: null,
        address3: null,
        address4: null,
        kabupatenId: null,
        provinceId: null,
        kelurahanId: null,
        kecamatanId: null,
        nik: null,
        postalCode: null,
        imageNik: null
      },
      selectUserLevel: "",
      principalId: "",
      rw: "",
      rt: "",
      kabupatenList: [],
      provinsiList: [],
      kelurahanList: [],
      kecamatanList: [],
      fileList: [],
      previewVisible: false,
      authUserId: null,
    };
  }

  componentDidMount() {
    let token = this.props.token;
    this.getLocaleString();
    this.initialListValue(token);
    let authClientId = this.props.authClientId;
    let pageEdit = false;
    this.getUserProfileDetail(token, authClientId, pageEdit, USER_PROFILE.USER_PROFILE_FORM_VIEW_HEADER);
  }

  getLocaleString = () => {
    if (this.state.locale === "id") {
      this.setState({ locale_string: LOCALIZATION_STRING_ID });
    } else {
      this.setState({ locale_string: LOCALIZATION_STRING_EN });
    }
  };

  getUserProfileDetail = (token, authClientId, pageEditBoolean, headerLabel) => {
    UserProfileService.getUserDetail(token, authClientId).then((response) => {
      let userProfileResponse = response.data.additionalEntity.result;
      this.props.dispatch(userReducerAction.setUserProfile(userProfileResponse));

      AreaService.getKabupaten(token, userProfileResponse.province_id)
        .then((responseAreaService) => {
          this.setState({
            kabupatenList: responseAreaService.data.additionalEntity.result,
          })
        })
        .catch(
          (error) => {
            errorNotificationAlert(
              USER_MANAGEMENT.USER_MANAGEMENT_ERROR_LABEL,
              USER_MANAGEMENT.USER_MANAGEMENT_ERROR_GET_KABUPATEN
            );
          });

      AreaService.getKecamatan(token, userProfileResponse.kabupaten_id)
        .then((responseAreaService) => {
          this.setState({
            kecamatanList: responseAreaService.data.additionalEntity.result,
          })
        })
        .catch(
          (error) => {
            errorNotificationAlert(
              USER_MANAGEMENT.USER_MANAGEMENT_ERROR_LABEL,
              USER_MANAGEMENT.USER_MANAGEMENT_ERROR_GET_KECAMATAN
            );
          });

      AreaService.getKelurahan(token, userProfileResponse.kecamatan_id)
        .then((responseAreaService) => {
          this.setState({
            kelurahanList: responseAreaService.data.additionalEntity.result,
          })
        })
        .catch(
          (error) => {
            errorNotificationAlert(
              USER_MANAGEMENT.USER_MANAGEMENT_ERROR_LABEL,
              USER_MANAGEMENT.USER_MANAGEMENT_ERROR_GET_KELURAHAN
            );
          });

      let splitedAddress = userProfileResponse.address.split(";");
      let splitedRTRW = userProfileResponse.rt_rw?.split("/") || [null, null];

      this.setState({
        pageEdit: pageEditBoolean,
        headerLabel: headerLabel,
        form: {
          ...this.state.form,
          principalId: userProfileResponse.principal_id,
          principalName: userProfileResponse.principal_name,
          userLevel: userProfileResponse.level,
          username: userProfileResponse.gromart_user_name,
          firstName: userProfileResponse.first_name,
          lastName: userProfileResponse.last_name,
          email: userProfileResponse.email,
          phoneNumber: userProfileResponse.phone,
          placeOfBirth: userProfileResponse.place_of_birth,
          dateOfBirth: userProfileResponse.date_of_birth,
          gender: userProfileResponse.gender,
          address1: splitedAddress[0],
          address2: splitedAddress[1],
          address3: splitedAddress[2],
          address4: splitedAddress[3],
          rt_rw: userProfileResponse.rt_rw,
          provinceId: userProfileResponse.province_id,
          kabupatenId: userProfileResponse.kabupaten_id,
          kecamatanId: userProfileResponse.kecamatan_id,
          kelurahanId: userProfileResponse.kelurahan_id,
          postalCode: userProfileResponse.zip_code,
          nik: userProfileResponse.ktp,
          imageNik: userProfileResponse.ktp_image
        },
        fileList: [
          {
            uid: "-1",
            name: "Preview File",
            status: "done",
            url: userProfileResponse.ktp_image
          }
        ],
        authUserId: userProfileResponse.auth_user_id,
      });

      this.formRef.current.setFieldsValue({
        principalName: userProfileResponse.principal_name,
        userLevel: this.convertUserLevelValueToLabel(userProfileResponse.level),
        username: userProfileResponse.gromart_user_name,
        firstName: userProfileResponse.first_name,
        lastName: userProfileResponse.last_name,
        email: userProfileResponse.email,
        phoneNumber: userProfileResponse.phone,
        placeOfBirth: userProfileResponse.place_of_birth,
        dateOfBirth: moment(userProfileResponse.date_of_birth, "YYYY-MM-DD"),
        gender: userProfileResponse.gender,
        address1: splitedAddress[0],
        address2: splitedAddress[1],
        address3: splitedAddress[2],
        address4: splitedAddress[3],
        rt: splitedRTRW[0],
        rw: splitedRTRW[1],
        provinceId: userProfileResponse.province_id,
        kabupatenId: userProfileResponse.kabupaten_id,
        kecamatanId: userProfileResponse.kecamatan_id,
        kelurahanId: userProfileResponse.kelurahan_id,
        postalCode: userProfileResponse.zip_code,
        nik: userProfileResponse.ktp,
        imageNik: userProfileResponse.ktp_image
      });
    }).catch((error) => {
      errorNotificationAlert(
        USER_PROFILE.USER_PROFILE_GET_DETAIL_USER_FAILED_TITLE,
        USER_PROFILE.USER_PROFILE_GET_DETAIL_USER_FAILED_CONTENT
      );
    });
  }

  initialListValue = (token) => {
    this.getProvinsiList(token);
  }

  convertUserLevelValueToLabel(userLevelData) {
    if (userLevelData === "0") {
      return "Admin"
    }
    if (userLevelData === "1") {
      return "Staff"
    }
  }

  convertUserLevelLabelToValue(userLevelData) {
    if (userLevelData === "Admin") {
      return "0"
    }
    if (userLevelData === "Staff") {
      return "1"
    }
  }

  handlePageEdit() {
    this.setState({
      pageEdit: true,
      headerLabel: USER_PROFILE.USER_PROFILE_FORM_EDIT_HEADER
    })
  }

  cancelEditPage() {
    this.getUserProfileDetail(
      this.props.token,
      this.props.authClientId,
      false,
      USER_PROFILE.USER_PROFILE_FORM_VIEW_HEADER
    );
  }

  handleChange(event) {
    let fieldName = event.target.name;
    let fieldValue = event.target.value;
    this.setState({ form: { ...this.state.form, [fieldName]: fieldValue } });
  }

  handleChangeDate(dateValue, fieldName) {
    let fieldValue = null;
    if (dateValue !== null) {
      fieldValue = moment(dateValue).format("YYYY-MM-DD");
    }
    this.setState({ form: { ...this.state.form, [fieldName]: fieldValue } });
  }

  handleChangeSelect(event, fieldName) {
    let fieldValue = event;
    this.setState({ form: { ...this.state.form, [fieldName]: fieldValue } });
  }

  handleChangeRTRW(event) {
    let rw = this.state.rw;
    let rt = this.state.rt;
    if (rw === null || rw === undefined) {
      rw = ""
    }

    if (rt === null || rt === undefined) {
      rt = ""
    }

    if (event.target.name === "rw") {
      rw = event.target.value;
      this.setState({ rw: rw });
      this.setState({ form: { ...this.state.form, rt_rw: rt + "/" + rw } });
    }

    if (event.target.name === "rt") {
      rt = event.target.value;
      this.setState({ rt: rt });
      this.setState({ form: { ...this.state.form, rt_rw: rt + "/" + rw } });
    }

    if (rt === "" && rw === "") {
      this.setState({ form: { ...this.state.form, rt_rw: null } });
    }
  }

  handleChangeProvinsi(event) {
    let token = this.props.token;
    this.getKabupatenListByProvinceId(token, event);
    this.formRef.current.setFieldsValue({
      kecamatanId: null,
      kabupatenId: null,
      kelurahanId: null
    });
  }

  handleChangeKabupaten(event) {
    let token = this.props.token;
    this.getKecamatanListByKabupatenId(token, event);
    this.formRef.current.setFieldsValue({
      kelurahanId: null,
      kecamatanId: null
    });
  }

  handleChangeKecamatan(event) {
    let token = this.props.token;
    this.getKelurahanListByKecamatanId(token, event);
    this.formRef.current.setFieldsValue({ kelurahanId: null });
  }

  handleChangeKelurahan(event) {
    this.setState({
      form: {
        ...this.state.form, kelurahanId: parseInt(event)
      },
    })
  }

  handleChangeFile(event) {
    if (event.fileList.length === 0) {
      this.setState({ form: { ...this.state.form, imageNik: null } });
      this.formRef.current.setFieldsValue({
        imageNik: null,
      });
    }
    this.setState({ fileList: event.fileList });
  }

  handleCancel() {
    this.setState({ previewVisible: false });
  }

  handlePreview() {
    this.setState({ previewVisible: true });
  }

  validationFile(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      this.setState({
        fileList: [
          {
            name: USER_PROFILE.USER_PROFILE_FORM_ERROR_FILETYPE_VALIDATION,
            status: 'error',
            uid: file.uid,
          }
        ]
      });
    }
    const isSizeLessThan2MB = file.size / 1024 / 1024 < 2;
    if (!isSizeLessThan2MB) {
      this.setState({
        fileList: [
          {
            name: USER_PROFILE.USER_PROFILE_FORM_ERROR_FILESIZE_VALIDATION,
            status: 'error',
            uid: file.uid,
          }
        ]
      });
    }
    return (isJpgOrPng && isSizeLessThan2MB) || Upload.LIST_IGNORE;
  }

  handleUploadFile(event) {
    let token = this.props.token;
    UploadService.uploadFile(token, event.file).then(
      response => {
        this.setState({ form: { ...this.state.form, imageNik: response.data.additionalEntity.result.path } });
        this.formRef.current.setFieldsValue({ imageNik: response.data.additionalEntity.result.path });
        event.onSuccess("File Upload Success");
      }
    ).catch(error => {
      this.setState({ form: { ...this.state.form, imageNik: null } });
      this.formRef.current.setFieldsValue({ imageNik: null });
      event.onError("File Upload Failed");
      errorNotificationAlert(
        USER_MANAGEMENT.USER_MANAGEMENT_ERROR_LABEL,
        USER_MANAGEMENT.USER_MANAGEMENT_ERROR_UPLOAD
      );
    })
  }

  handleDownload() {
    window.open(this.state.form.imageNik, "_blank");
  }

  changeApiUdpateByUserLevel(token, data) {
    switch (this.props.level) {
      case "0":
        return UserManagementService.updateUser(token, data);
      case "1":
        return UserProfileService.updateUserStaff(token, data);
      default:
        return UserProfileService.updateUserStaff(token, data);
    }
  }

  updateUserProfile = (token, authClientId, pageEdit, headerLabel) => {
    let dataForm = this.state.form;
    dataForm.lastName = dataForm.lastName || null;
    this.changeApiUdpateByUserLevel(this.props.token, dataForm).then((response) => {
      this.getUserProfileDetail(token, authClientId, pageEdit, headerLabel);
      modalInfo(
        USER_PROFILE.USER_PROFILE_MODAL_TITLE_INFO,
        USER_PROFILE.USER_PROFILE_MODAL_CONTENT_INFO_SUCCESS_UPDATEUSER,
        this.state.locale_string.okButton
      );
    }).catch((error) => {
      modalInfo(
        USER_PROFILE.USER_PROFILE_MODAL_TITLE_INFO_ALERT,
        error.response.data.description,
        this.state.locale_string.okButton
      );
    });
  }

  submitSuccessForm() {
    let token = this.props.token;
    let authClientId = this.props.authClientId;
    let pageEdit = false;
    let headerLabel = USER_PROFILE.USER_PROFILE_FORM_VIEW_HEADER;

    modalConfirmation(
      USER_PROFILE.USER_PROFILE_MODAL_TITLE_CONFIRMATION,
      USER_PROFILE.USER_PROFILE_MODAL_CONTENT_CONFIRMATION_USER_EDIT,
      this.state.locale_string.yesButton,
      this.state.locale_string.noButton,
      () => this.updateUserProfile(token, authClientId, pageEdit, headerLabel)
    );
  }

  handleErrorGetKelurahan = () => {
    errorNotificationAlert(
      USER_MANAGEMENT.USER_MANAGEMENT_ERROR_LABEL,
      USER_MANAGEMENT.USER_MANAGEMENT_ERROR_GET_KELURAHAN
    )
  }

  handleErrorGetKecamatan = () => {
    errorNotificationAlert(
      USER_MANAGEMENT.USER_MANAGEMENT_ERROR_LABEL,
      USER_MANAGEMENT.USER_MANAGEMENT_ERROR_GET_KECAMATAN
    )
  }

  handleErrorGetKabupaten = () => {
    errorNotificationAlert(
      USER_MANAGEMENT.USER_MANAGEMENT_ERROR_LABEL,
      USER_MANAGEMENT.USER_MANAGEMENT_ERROR_GET_KABUPATEN
    )
  }

  getProvinsiList(token) {
    AreaService.getProvinsi(token)
      .then((response) => {
        this.setState({ provinsiList: response.data.additionalEntity.result });
      })
      .catch((error) => {
        errorNotificationAlert(
          USER_MANAGEMENT.USER_MANAGEMENT_ERROR_LABEL,
          USER_MANAGEMENT.USER_MANAGEMENT_ERROR_GET_PROVINCE
        );
      });
  }

  getKabupatenListByProvinceId(token, provinceId) {
    AreaService.getKabupaten(token, provinceId)
      .then((response) => {
        this.setState({
          kabupatenList: response.data.additionalEntity.result,
          kelurahanList: [],
          kecamatanList: [],
          form: {
            ...this.state.form,
            provinceId: parseInt(provinceId),
            kecamatanId: null,
            kabupatenId: null,
            kelurahanId: null
          }
        })
      })
      .catch((error) => {
        this.handleErrorGetKabupaten();
      });
  }

  getKecamatanListByKabupatenId(token, kabupatenId) {
    AreaService.getKecamatan(token, kabupatenId)
      .then((response) => {
        this.setState({
          kecamatanList: response.data.additionalEntity.result,
          kelurahanList: [],
          form: {
            ...this.state.form,
            kabupatenId: parseInt(kabupatenId),
            kelurahanId: null,
            kecamatanId: null
          }
        })
      })
      .catch((error) => {
        this.handleErrorGetKecamatan();
      });
  }

  getKelurahanListByKecamatanId(token, kecamatanId) {
    AreaService.getKelurahan(token, kecamatanId)
      .then((response) => {
        this.setState({
          kelurahanList: response.data.additionalEntity.result,
          form: {
            ...this.state.form,
            kecamatanId: parseInt(kecamatanId),
            kelurahanId: null
          },
        })
      })
      .catch((error) => {
        this.handleErrorGetKelurahan();
      });
  }

  uploadButtonComponent() {
    return (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    )
  }

  checkUserLevel() {
    if (this.state.form.userLevel === "0") {
      return false;
    }
    return true;
  }

  openPWAGromate() {
    let pwaUrl = "https://staging-chat.gromart.club:7443";
    let activation = "true";
    let userId = this.state.authUserId;
    let phoneNumber = this.state.form.phoneNumber;
    let redirectUrlAfterSuccessActivation = window.location.href;
    let features = "menubar=yes,location=yes,resizeable=yes,scrollbars=yes,status=no"
    UserProfileService.getTokenChatServer()
      .then(response => {
        let tokenChatServer = response.data.data;
        window.open(`${pwaUrl}/login?token=${tokenChatServer}&activation=${activation}&userId=${userId}&phone=${phoneNumber}&activationURL=${redirectUrlAfterSuccessActivation}`,
          "_self",
          features
        )
      }).catch(error => {
        console.log(error);
      })
  }

  handleSubmit() {
    this.formRef.current.submit();
  }

  handleCancelEditPageConfirmation() {
    modalConfirmation(
      USER_PROFILE.USER_PROFILE_MODAL_TITLE_CONFIRMATION,
      USER_PROFILE.USER_PROFILE_MODAL_CONTENT_CONFIRMATION_CANCEL_EDIT,
      this.state.locale_string.yesButton,
      this.state.locale_string.noButton,
      () => this.cancelEditPage()
    );
  }

  render() {
    const { form } = this.state;
    const { Option } = Select;
    const { url } = this.props.match;

    return (
      <>
        <Row className="justify-content-md-center">
          <Col xs={12} md={12} style={{ marginTop: "10px" }}>
            <Row>
              <Col xs={12} md={5}>
                <div className="pageTitle">
                  {this.state.headerLabel}
                </div>
              </Col>
              <Col xs={12} md={7}>
                <div className="headerButton" hidden={this.state.pageEdit}>
                  <Space size="middle">

                    {/* TODO Keep It for future use when need activate gromate by phonenumber use PWA */}
                    {/* <Button
                      className="buttonEditMUIStyle"
                      disabled={this.checkUserLevel()}
                      onClick={() => this.openPWAGromate()}
                    >
                      {this.state.locale_string.activeGromateButton}
                    </Button> */}

                    <Link to={`${url}/changepassword`} style={{ textDecoration: "none" }}>
                      <Button className="buttonEditMUIStyle">
                        {this.state.locale_string.changePasswordButton}
                      </Button>
                    </Link>
                    <Button
                      className="buttonEditMUIStyle"
                      onClick={() => this.handlePageEdit()}
                    >
                      {this.state.locale_string.editButton}
                    </Button>
                  </Space>
                </div>
              </Col>
            </Row>
            <div className="white-box white-box-usermanagement">
              <div>
                <Form
                  initialValues={{ form }}
                  ref={this.formRef}
                  onFinish={() => this.submitSuccessForm()}
                >
                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle">
                        Principal Name
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Item
                        name="principalName"
                        rules={[
                          {
                            message: USER_PROFILE.USER_PROFILE_FORM_ERROR_PRINCIPAL_NAME,
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          name="principalName"
                          onChange={(event) => this.handleChange(event)}
                          value={this.state.form.principalName}
                          disabled={this.state.pageEdit}
                          readOnly={!this.state.pageEdit} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle required">
                        User Level
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Item
                        name="userLevel"
                        rules={[
                          {
                            message: USER_PROFILE.USER_PROFILE_FORM_ERROR_USERLEVEL,
                            required: true,
                          },
                        ]}
                      >
                        <Input
                          name="userLevel"
                          value={this.state.form.selectUserLevel}
                          disabled={this.state.pageEdit}
                          readOnly={!this.state.pageEdit}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle required">
                        Username
                      </div>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="username"
                        rules={[
                          {
                            pattern: new RegExp(/^(?=.*[a-z])(?=.*\d)[a-z\d]{8,}$/),
                            required: true,
                            message: USER_PROFILE.USER_PROFILE_FORM_ERROR_USERNAME,
                            validateTrigger: "onSubmit"
                          },
                        ]}
                      >
                        <Input
                          name="username"
                          onChange={(event) => this.handleChange(event)}
                          value={this.state.form.username}
                          readOnly={!this.state.pageEdit}
                          disabled={this.state.pageEdit}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle required">
                        First Name
                      </div>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="firstName"
                        rules={[
                          {
                            message: USER_PROFILE.USER_PROFILE_FORM_ERROR_FIRSTNAME,
                            required: true,
                            validateTrigger: "onSubmit"
                          },
                          {
                            pattern: new RegExp(/^[A-Z]{1}[a-z]+$/),
                            message: USER_PROFILE.USER_PROFILE_FORM_ERROR_PATTERN_FIRSTNAME,
                          },
                        ]}
                      >
                        <Input
                          name="firstName"
                          onChange={(event) => this.handleChange(event)}
                          value={this.state.form.firstName}
                          readOnly={!this.state.pageEdit}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle">
                        Last Name
                      </div>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="lastName"
                        rules={[
                          {
                            message: USER_PROFILE.USER_PROFILE_FORM_ERROR_PATTERN_LASTNAME,
                            pattern: new RegExp(/^[A-Z]{1}[a-z]+$/),
                          }
                        ]}
                      >
                        <Input
                          name="lastName"
                          onChange={(event) => this.handleChange(event)}
                          value={this.state.form.lastName}
                          readOnly={!this.state.pageEdit}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle required">
                        Email
                      </div>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            message: USER_PROFILE.USER_PROFILE_FORM_ERROR_NULL_EMAIL,
                            required: true,
                            validateTrigger: "onSubmit"
                          },
                          {
                            message: USER_PROFILE.USER_PROFILE_FORM_ERROR_VALID_EMAIL,
                            type: "email",
                          },
                        ]}
                      >
                        <Input
                          name="email"
                          onChange={(event) => this.handleChange(event)}
                          value={this.state.form.email}
                          readOnly={!this.state.pageEdit}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle required">
                        Phone Number
                      </div>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="phoneNumber"
                        rules={[
                          {
                            message: USER_PROFILE.USER_PROFILE_FORM_ERROR_NULL_PHONENUMBER,
                            required: true,
                            validateTrigger: "onSubmit"
                          },
                          {
                            message: USER_PROFILE.USER_PROFILE_FORM_ERROR_VALID_PHONENUMBER,
                            pattern: new RegExp(/^(\+62|62|0)8[1-9][0-9]{8,11}$/),
                          },
                        ]}
                      >
                        <Input
                          name="phoneNumber"
                          onChange={(event) => this.handleChange(event)}
                          value={this.state.form.phoneNumber}
                          readOnly={!this.state.pageEdit}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle">
                        Place of Birth
                      </div>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="placeOfBirth"
                      >
                        <Input
                          name="placeOfBirth"
                          onChange={(event) => this.handleChange(event)}
                          value={this.state.form.placeOfBirth}
                          readOnly={!this.state.pageEdit}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle">
                        Date of Birth
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <div hidden={this.state.pageEdit}>
                        <Form.Item name="dateOfBirth">
                          <DatePicker
                            onChange={(event) => this.handleChangeDate(event, "dateOfBirth")}
                            open={false}
                            inputReadOnly={!this.state.pageEdit}
                            allowClear={this.state.pageEdit}
                          />
                        </Form.Item>
                      </div>
                      <div hidden={!this.state.pageEdit}>
                        <Form.Item name="dateOfBirth">
                          <DatePicker
                            onChange={(event) => this.handleChangeDate(event, "dateOfBirth")}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle">
                        Gender
                      </div>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="gender"
                      >
                        <Radio.Group
                          name="gender"
                          onChange={(event) => this.handleChange(event)}
                          disabled={!this.state.pageEdit}
                        >
                          <Radio value={"1"}>Laki-laki</Radio>
                          <Radio value={"2"}>Perempuan</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle">
                        Address
                      </div>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="address1"
                      >
                        <Input
                          name="address1"
                          value={this.state.form.address1}
                          onChange={(event) => this.handleChange(event)}
                          readOnly={!this.state.pageEdit}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="address2"
                      >
                        <Input value={this.state.form.address2}
                          name="address2"
                          readOnly={!this.state.pageEdit}
                          onChange={(event) => this.handleChange(event)} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="address3"
                      >
                        <Input value={this.state.form.address3}
                          name="address3"
                          readOnly={!this.state.pageEdit}
                          onChange={(event) => this.handleChange(event)} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="address4"
                      >
                        <Input value={this.state.form.address4}
                          name="address4"
                          readOnly={!this.state.pageEdit}
                          onChange={(event) => this.handleChange(event)} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle">
                        RT/RW
                      </div>
                    </Col>
                    <Col xs={12} md={7}>
                      <Row>
                        <Col xs={4} md={3}>
                          <Form.Item
                            rules={[
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value &&
                                    getFieldValue("rw")) {
                                    return Promise.reject(
                                      new Error(USER_PROFILE.USER_PROFILE_FORM_ERROR_RT)
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                            name="rt"
                          >
                            <Input
                              name="rt"
                              maxLength={3}
                              onChange={(event) => this.handleChangeRTRW(event)}
                              value={this.state.rt}
                              readOnly={!this.state.pageEdit}
                            />
                          </Form.Item>
                        </Col>
                        <Col className="slashStyle" xs={1} md={1}>
                          /
                        </Col>
                        <Col xs={4} md={3}>
                          <Form.Item
                            name="rw"
                            rules={[
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value &&
                                    getFieldValue("rt")) {
                                    return Promise.reject(
                                      new Error(USER_PROFILE.USER_PROFILE_FORM_ERROR_RW)
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <Input
                              value={this.state.rw}
                              maxLength={3}
                              name="rw"
                              onChange={(event) => this.handleChangeRTRW(event)}
                              readOnly={!this.state.pageEdit}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle required">
                        Provinsi
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Item
                        name="provinceId"
                        rules={[
                          {
                            message: USER_PROFILE.USER_PROFILE_FORM_ERROR_PROVINSI,
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          placeholder={USER_PROFILE.USER_PROFILE_FORM_PLACEHOLDER_PROVINSI}
                          onChange={(event) => this.handleChangeProvinsi(event)}
                          name="provinceId"
                        >
                          {this.state.provinsiList.map(
                            (provinsiData, index) => (
                              <Option
                                key={`${provinsiData.pickerCode}-${index}`}
                                value={provinsiData.pickerCode}
                                disabled={!this.state.pageEdit}
                              >
                                {provinsiData.pickerDescription}
                              </Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle required">
                        Kabupaten
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Item
                        name="kabupatenId"
                        rules={[
                          {
                            message: USER_PROFILE.USER_PROFILE_FORM_ERROR_KABUPATEN,
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          onChange={(event) => this.handleChangeKabupaten(event)}
                          name="kabupatenId"
                          placeholder={USER_PROFILE.USER_PROFILE_FORM_PLACEHOLDER_KABUPATEN}
                        >
                          {this.state.kabupatenList.map(
                            (kabupatenData, index) => (
                              <Option
                                key={`${kabupatenData.id}-${index}`}
                                value={kabupatenData.id}
                                disabled={!this.state.pageEdit}
                              >
                                {kabupatenData.kabupatenName}
                              </Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle required">
                        Kecamatan
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Item
                        name="kecamatanId"
                        rules={[
                          {
                            message: USER_PROFILE.USER_PROFILE_FORM_ERROR_KECAMATAN,
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          placeholder={USER_PROFILE.USER_PROFILE_FORM_PLACEHOLDER_KECAMATAN}
                          onChange={(event) => this.handleChangeKecamatan(event)}
                          name="kecamatanId"
                        >
                          {this.state.kecamatanList.map(
                            (kecamatanData, index) => (
                              <Option
                                key={`${kecamatanData.id}-${index}`}
                                value={kecamatanData.id}
                                disabled={!this.state.pageEdit}
                              >
                                {kecamatanData.kecamatanName}
                              </Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle required">
                        Kelurahan
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Item
                        name="kelurahanId"
                        rules={[
                          {
                            message: USER_PROFILE.USER_PROFILE_FORM_ERROR_KELURAHAN,
                            required: true,
                          },
                        ]}
                      >
                        <Select
                          placeholder={USER_PROFILE.USER_PROFILE_FORM_PLACEHOLDER_KELURAHAN}
                          onChange={(event) => this.handleChangeKelurahan(event)}
                          name="kelurahanId"
                        >
                          {this.state.kelurahanList.map(
                            (kelurahanData, index) => (
                              <Option
                                value={kelurahanData.id}
                                key={`${kelurahanData.id}-${index}`}
                                disabled={!this.state.pageEdit}
                              >
                                {kelurahanData.kelurahanName}
                              </Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle required">
                        Postal Code
                      </div>
                    </Col>
                    <Col xs={5} md={2}>
                      <Form.Item
                        name="postalCode"
                        rules={[
                          {
                            message: USER_PROFILE.USER_PROFILE_FORM_ERROR_POSTAL_CODE,
                            required: true,
                            validateTrigger: "onSubmit"
                          },
                        ]}
                      >
                        <Input
                          name="postalCode"
                          maxLength={5}
                          onChange={(event) => this.handleChange(event)}
                          value={this.state.form.postalCode}
                          readOnly={!this.state.pageEdit}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle required">
                        NIK
                      </div>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="nik"
                        rules={[
                          {
                            message: USER_PROFILE.USER_PROFILE_FORM_ERROR_NIK,
                            required: true,
                            validateTrigger: "onSubmit"
                          },
                        ]}
                      >
                        <Input
                          name="nik"
                          maxLength={16}
                          onChange={(event) => this.handleChange(event)}
                          value={this.state.form.nik}
                          readOnly={!this.state.pageEdit}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle required">
                        Upload Foto NIK
                      </div>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="imageNik"
                        rules={[
                          {
                            message: USER_PROFILE.USER_PROFILE_FORM_ERROR_FOTO_NIK,
                            required: true,
                          },
                        ]}
                      >
                        <Upload
                          maxCount={1}
                          listType="picture-card"
                          accept="image/jpeg,image/png"
                          fileList={this.state.fileList}
                          customRequest={(event) => this.handleUploadFile(event)}
                          onDownload={() => this.handleDownload()}
                          beforeUpload={(event) => this.validationFile(event)}
                          onChange={(event) => this.handleChangeFile(event)}
                          onPreview={() => this.handlePreview()}
                          showUploadList={{ showDownloadIcon: true, showPreviewIcon: true }}
                          disabled={!this.state.pageEdit}
                        >
                          {this.state.fileList.length >= 1 ? null : this.uploadButtonComponent()}
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>

                  <div hidden={!this.state.pageEdit}>
                    <Divider className="dividerStyle" />
                    <Form.Item>
                      <Space size="large" className="buttonKeepRight">
                        <Button
                          className="buttonCancelMUIStyle"
                          onClick={() => this.handleCancelEditPageConfirmation()}
                          danger
                        >
                          {this.state.locale_string.cancelButton}
                        </Button>
                        <Button
                          onClick={() => this.handleSubmit()}
                          danger
                          type="primary"
                          className="buttonCreateMUIStyle"
                        >
                          {this.state.locale_string.createButton}
                        </Button>
                      </Space>
                    </Form.Item>
                  </div>
                </Form>

                <Modal
                  title={this.state.locale_string.nikModalTitle}
                  footer={null}
                  onCancel={() => this.handleCancel()}
                  visible={this.state.previewVisible}
                >
                  <img alt="Preview Foto NIK" style={{ width: '100%' }}
                    src={this.state.form.imageNik} />
                </Modal>

              </div>
            </div>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.userData.token,
    userID: state.userData.userID,
    status: state.userData.status,
    authClientId: state.userData.authClientId,
    level: state.userData.level,
  };
};

export default compose(connect(mapStateToProps), withRouter)
  ((UserProfileForm));