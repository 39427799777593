import { apiServer } from "../config/AppConfig";
import axios from "axios";

function setHeaderToken(token) {
  return {
    "X-AUTH-TOKEN-GC": token,
  };
}

const AreaService = {
  getProvinsi(token) {
    return axios({
      method: "get",
      url: apiServer + "/api/com/province/pickList",
      headers: setHeaderToken(token),
      crossOrigin: true,
    });
  },

  getKabupaten(token, provinceId) {
    return axios({
      method: "get",
      url: apiServer + "/api/com/kabupaten/pickList?provinceId=" + provinceId,
      headers: setHeaderToken(token),
      crossOrigin: true,
    });
  },

  getKecamatan(token, kabupatenId) {
    return axios({
      method: "get",
      url: apiServer + "/api/com/kecamatan/pickList?provinceId=&kabupatenId=" + kabupatenId,
      headers: setHeaderToken(token),
      crossOrigin: true,
    });
  },

  getKelurahan(token, kecamatanId) {
    return axios({
      method: "get",
      url: apiServer + "/api/com/kelurahan/pickList?provinceId&kabupatenId&kecamatanId=" + kecamatanId,
      headers: setHeaderToken(token),
      crossOrigin: true,
    });
  },

  getProvinsiById(token, provinceId) {
    return axios({
      method: "get",
      url: apiServer + "/api/com/province/" + provinceId,
      headers: setHeaderToken(token),
      crossOrigin: true,
    });
  },

  getKabupatenById(token, kabupatenId) {
    return axios({
      method: "get",
      url: apiServer + "/api/com/kabupaten/" + kabupatenId,
      headers: setHeaderToken(token),
      crossOrigin: true,
    });
  },

  getKecamatanById(token, kecamatanId) {
    return axios({
      method: "get",
      url: apiServer + "/api/com/kecamatan/" + kecamatanId,
      headers: setHeaderToken(token),
      crossOrigin: true,
    });
  },

  getKelurahanById(token, kelurahanId) {
    return axios({
      method: "get",
      url: apiServer + "/api/com/kelurahan/" + kelurahanId,
      headers: setHeaderToken(token),
      crossOrigin: true,
    });
  }
  
}

export default AreaService; 