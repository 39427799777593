import React from "react";
import {
  LOCALIZATION_STRING_EN,
  LOCALIZATION_STRING_ID,
} from "../../localization_string/localization_global";
import { Col, Row, Alert } from "react-bootstrap";
import "antd/dist/antd.css";
import VoucherService from "../../services/voucherService";
import { Select, Form, Table } from "antd";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ArrowDropDownOutlinedIcon from "@material-ui/icons/ArrowDropDownOutlined";
import "./voucher.css";
import moment from "moment";
import CustomPagination from "./CustomPagination";
import { VOUCHER_ENUM } from "../../_constants/voucher.constants";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#d81922",
        borderColor: "#d81922",
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

const style = (theme) => ({
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white",
    },
  },
});

class Voucher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: "id",
      locale_string: {},
      columns: [
        {
          title: VOUCHER_ENUM.VOUCHER_NUMBER,
          dataIndex: "voucherNumber",
          fixed: "left",
          width: "11vw",
          align: "center",
          sorter: true,
          render: (voucherNumber) => `${voucherNumber}`,
        },
        {
          title: VOUCHER_ENUM.VALID_FROM_VOUCHER,
          dataIndex: "validFrom",
          fixed: "left",
          width: "10vw",
          align: "center",
          sorter: true,
          render: (validFrom) => `${moment(validFrom).format("DD/MM/YYYY")}`,
        },
        {
          title: VOUCHER_ENUM.VALID_THROUGH_VOUCHER,
          dataIndex: "validThru",
          fixed: "left",
          width: "10vw",
          align: "center",
          sorter: true,
          render: (validThru) => `${moment(validThru).format("DD/MM/YYYY")}`,
        },
        {
          title: VOUCHER_ENUM.VOUCHER_TYPE,
          dataIndex: "voucherType",
          width: "10vw",
          align: "center",
          sorter: true,
          render: (voucherType) => `${voucherType}`,
        },
        {
          title: VOUCHER_ENUM.VOUCHER_FREEGOOD,
          dataIndex: "freeGoods",
          width: "10vw",
          align: "center",
          sorter: true,
          render: (freeGoods) => `${freeGoods}`,
        },
        {
          title: VOUCHER_ENUM.VOUCHER_VALUE,
          dataIndex: "value",
          width: "10vw",
          align: "center",
          sorter: true,
          render: (value) => `${value}`,
        },
        {
          title: VOUCHER_ENUM.VOUCHER_KABUPATEN_LIST,
          dataIndex: "kabupatenList",
          width: "10vw",
          align: "center",
          sorter: true,
          render: (kabupatenList) => `${kabupatenList}`,
        },
        {
          title: VOUCHER_ENUM.VOUCHER_ACTIVE,
          dataIndex: "active",
          width: "10vw",
          align: "center",
          sorter: true,
          render: (active) => `${active === "Y" ? "Yes" : "No"}`,
        },
        {
          title: VOUCHER_ENUM.VOUCHER_CUSTOMER_ID,
          dataIndex: "customerId",
          width: "10vw",
          align: "center",
          sorter: true,
          render: (customerId) => `${customerId}`,
        },
        {
          title: VOUCHER_ENUM.VOUCHER_CUSTOMER_NAME,
          dataIndex: "customerName",
          width: "10vw",
          align: "center",
          sorter: true,
          render: (customerName) => `${customerName}`,
        },
        {
          title: VOUCHER_ENUM.VOUCHER_ASSIGN_DATE,
          dataIndex: "assignDate",
          width: "10vw",
          align: "center",
          sorter: true,
          render: (assignDate) => `${moment(assignDate).format("DD/MM/YYYY")}`,
        },
        {
          title: VOUCHER_ENUM.VOUCHER_REDEEM_DATE,
          dataIndex: "redeemDate",
          width: "10vw",
          align: "center",
          sorter: true,
          render: (redeemDate) => `${redeemDate === null ? "" : moment(redeemDate).format("DD/MM/YYYY")}`,
        },
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      sorter: {
        orderBy: "voucherNumber",
        order: "",
      },
      distributorList: [],
      voucherList: [],
      sellerId: "1557113897390",
      isActive: true,
      promoCode: "PRM2020",
      voucherDetail: "",
      voucherCode: [],
      termncond: [],
    };
  }

  componentDidMount() {
    let token = this.props.token;
    this.getLocaleString();
    this.getDistributorList(token);
  }

  getLocaleString = () => {
    if (this.state.locale === "id") {
      this.setState({ locale_string: LOCALIZATION_STRING_ID });
    } else {
      this.setState({ locale_string: LOCALIZATION_STRING_EN });
    }
  };

  handleChangeTable = (pagination, filters, sorter) => {
    if (sorter.field && sorter.order) {
      this.setState(
        { sorter: { orderBy: sorter.field, order: sorter.order } },
        () => {
          this.getVoucherCodeList(
            this.props.token,
            this.state.promoCode,
            this.state.pagination.pageSize,
            this.state.pagination.current,
            this.state.sorter.orderBy,
            this.state.sorter.order
          );
        }
      );
    }
  };

  getDistributorList = (token) => {
    VoucherService.pickDistributor(token)
      .then((response) => {
        this.setState({
          distributorList: response.data.additionalEntity.result,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getVoucherList = (token, sellerId, isActive) => {
    VoucherService.pickVoucher(token, sellerId, isActive)
      .then((response) => {
        this.setState({
          voucherList: response.data.additionalEntity.result,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getVoucherDetailList = (token, promoCode) => {
    VoucherService.getVoucherDetail(token, promoCode)
      .then((response) => {
        if (response.data.additionalEntity.result) {
          this.setState({
            voucherDetail: response.data.additionalEntity.result,
          });
        }
        if (response.data.additionalEntity.result.termsAndCondition) {
          this.setState({
            termncond:
              response.data.additionalEntity.result.termsAndCondition.split(
                ";"
              ),
          });
        } else {
          this.setState({
            termncond: [],
          });
        }
      })
      .catch((error) => {
        this.setState({ voucherDetail: "" });
        console.log(error);
      });
  };

  getVoucherCodeList = (
    token,
    promoCode,
    pageSize,
    pageIndex,
    orderBy,
    order
  ) => {
    let valueOrderBy = "";
    if (orderBy && order) {
      if (orderBy === "freeGoods") {
        orderBy = "principalProductName";
      } else if (orderBy === "value") {
        orderBy = "voucherPrice";
      } else if (orderBy === "active") {
        orderBy = "isActive";
      } else if (orderBy === "voucherType") {
        orderBy = "typeVoucher";
      }

      if (order === "descend") {
        order = "-";
      } else {
        order = "";
      }

      valueOrderBy = order + orderBy;
    }

    VoucherService.getVoucherCode(
      token,
      promoCode,
      pageSize,
      pageIndex,
      valueOrderBy
    )
      .then((response) => {
        this.setState({
          voucherCode: response.data.additionalEntity.result.result,
          pagination: {
            ...this.state.pagination,
            total: response.data.additionalEntity.result.actualRowCount,
            pageSize: response.data.additionalEntity.result.pageSize,
            current: response.data.additionalEntity.result.currentPageIndex,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  resetData = () => {
    this.setState({
      voucherDetail: "",
      voucherCode: "",
    });
  };

  handleChangeDistributor = (value) => {
    this.setState({ sellerId: value });
    this.getVoucherList(this.props.token, value, this.state.isActive);
    this.resetData();
  };

  handleChangeCheckbox = (e) => {
    this.setState({ isActive: e.target.checked });
    if (this.state.sellerId) {
      this.getVoucherList(
        this.props.token,
        this.state.sellerId,
        e.target.checked
      );
      this.resetData();
    }
  };

  handleChangeAutoDistribute = (e) => {
    this.setState(
      {
        voucherDetail: {
          ...this.state.voucherDetail,
          autoDistribute: e.target.checked ? "Y" : "N",
        },
      },
      () => {
        VoucherService.patchAutoDistribute(
          this.props.token,
          this.state.promoCode,
          this.state.sellerId,
          this.state.voucherDetail.autoDistribute
        );
      }
    );
  };

  handleChangeVoucher = (value) => {
    this.setState({ promoCode: value });
    this.getVoucherDetailList(this.props.token, value);
    this.getVoucherCodeList(
      this.props.token,
      value,
      this.state.pagination.pageSize,
      this.state.pagination.current,
      this.state.sorter.orderBy,
      this.state.sorter.order
    );
  };

  handleSendVoucher = (event) => {
    event.preventDefault();
    if (this.state.sellerId && this.state.promoCode) {
      if (window.confirm("Are you sure you want to send this voucher?")) {
        VoucherService.sendVoucher(
          this.props.token,
          this.state.promoCode,
          this.state.sellerId
        )
          .then(() => {
            alert("You have successfully send the voucher!");
          })
          .catch((error) => {
            console.log(error);
            alert("Failed to send voucher. Please try again!");
          });
      }
    } else {
      alert("Distributor and voucher name cannot be empty");
    }
  };

  onPagination = (currentIndex, currentSize) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          current: currentIndex,
          pageSize: currentSize,
        },
      },
      () => {
        this.getVoucherCodeList(
          this.props.token,
          this.state.promoCode,
          this.state.pagination.pageSize,
          this.state.pagination.current,
          this.state.sorter.orderBy,
          this.state.sorter.order
        );
      }
    );
  };

  render() {
    const { Option } = Select;
    const { classes } = this.props;
    console.log(this.state.termncond);
    return (
      <>
        <Row className="justify-content-md-center">
          <Col xs md={12} style={{ marginTop: "10px" }}>
            <div className="white-box">
              <div align="right">
                <Button
                  onClick={this.props.openVoucherReportFilter}
                  variant="outlined"
                  color="secondary"
                  style={{ marginRight: "20px" }}
                >
                  {this.state.locale_string.downloadVoucher}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.handleSendVoucher}
                >
                  {this.state.locale_string.sendVoucher}
                </Button>
              </div>
              <div style={{ margin: "20px 0" }}>
                <Form size="large">
                  <Row>
                    <Col xs={12} md={3}>
                      <div className="title-voucher">
                        {this.state.locale_string.distributor}
                      </div>
                    </Col>
                    <Col xs={12} md={5}>
                      <Form.Item>
                        <Select
                          onChange={this.handleChangeDistributor}
                          placeholder="Select Distributor"
                        >
                          {this.state.distributorList.map(
                            (distributor, index) => (
                              <Option value={distributor.id} key={index}>
                                {distributor.name}
                              </Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "20px" }}>
                    <Col xs={12} md={3}>
                      <div className="title-voucher">
                        {this.state.locale_string.voucherName}
                      </div>
                    </Col>
                    <Col xs={12} md={5}>
                      <Form.Item>
                        <Select
                          onChange={this.handleChangeVoucher}
                          placeholder="Select Voucher"
                          disabled={this.state.sellerId ? false : true}
                        >
                          {this.state.voucherList.map((voucher, index) => (
                            <Option value={voucher.promoCode} key={index}>
                              {voucher.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Grid
                        component="label"
                        container
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>
                          <AntSwitch
                            checked={this.state.isActive}
                            onChange={this.handleChangeCheckbox}
                          />
                        </Grid>
                        <Grid item>{this.state.locale_string.activePromo}</Grid>
                      </Grid>
                    </Col>
                  </Row>
                </Form>
                <Accordion elevation={0} className={classes.MuiAccordionroot}>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownOutlinedIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className="accordion-title">
                      <b>{this.state.locale_string.voucherDetail}</b>
                    </div>
                  </AccordionSummary>
                  <hr></hr>
                  <AccordionDetails>
                    {this.state.voucherDetail ? (
                      <div style={{ width: "100%", height: "auto" }}>
                        <Row style={{ marginBottom: "20px" }}>
                          <Col xs={12} md={3}>
                            <div className="title-voucher">
                              {this.state.locale_string.voucherDistribution}
                            </div>
                          </Col>
                          <Col xs={9} md={9}>
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    <div
                                      style={{
                                        borderBottom: "1px solid black",
                                        margin: "0 10px 0 0",
                                      }}
                                    >
                                      <div className="title-voucher">
                                        {this.state.voucherDetail.noOfDistributed}
                                        {this.state.locale_string.of}
                                        {this.state.voucherDetail.noOfVoucher}
                                      </div>
                                    </div>
                                  </td>
                                  <td style={{ width: "40%", textAlign: "center" }}>
                                    <div className="title-voucher">
                                      {this.state.locale_string.redeemed}
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      style={{
                                        borderBottom: "1px solid black",
                                        margin: "0 10px",
                                      }}
                                    >
                                      <div className="title-voucher">
                                        {this.state.voucherDetail.noOfRedeemed}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </Col>
                        </Row>
                        <Row style={{ marginBottom: "20px" }}>
                          <Col xs={12} md={3}>
                            <div className="title-voucher">
                              {this.state.locale_string.autoDistribution}
                            </div>
                            <p
                              style={{
                                fontSize: "12px",
                                fontWeight: "normal",
                              }}
                            >
                              ({this.state.locale_string.withScheduler})
                            </p>
                          </Col>
                          <Col xs={12} md={5}>
                            <Grid
                              component="label"
                              container
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid item>
                                <AntSwitch
                                  checked={
                                    this.state.voucherDetail.autoDistribute ===
                                      "Y"
                                      ? true
                                      : false
                                  }
                                  onChange={this.handleChangeAutoDistribute}
                                />
                              </Grid>
                              <Grid item>
                                {this.state.voucherDetail.autoDistribute === "Y"
                                  ? "Yes"
                                  : "No"}
                              </Grid>
                            </Grid>
                          </Col>
                        </Row>
                        <Row style={{ marginBottom: "20px" }}>
                          <Col xs={12} md={3}>
                            <div className="title-voucher">
                              {this.state.locale_string.tnc}
                            </div>
                          </Col>
                          <Col xs={12} md={5}>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                overflow: "auto",
                                maxHeight: "190px",
                              }}
                            >
                              <div className="desc-voucher">
                                {this.state.termncond.length > 0 ? (
                                  <ol>
                                    {this.state.termncond.map((term, index) => (
                                      <li key={index}>{term}</li>
                                    ))}
                                  </ol>
                                ) : (
                                  "-"
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row style={{ marginBottom: "20px" }}>
                          <Col xs={12} md={3}>
                            <div className="title-voucher">
                              {this.state.locale_string.validDistrict}
                            </div>
                          </Col>
                          <Col xs={12} md={5}>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                overflow: "auto",
                                maxHeight: "190px",
                              }}
                            >
                              {this.state.voucherDetail.validKabupaten.map(
                                (kab, index) => (
                                  <div className="desc-voucher" key={index}>
                                    {kab}
                                  </div>
                                )
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row style={{ marginBottom: "20px" }}>
                          <Col xs={12} md={3}>
                            <div className="title-voucher">
                              {this.state.locale_string.validSubDistrict}
                            </div>
                          </Col>
                          <Col xs={12} md={5}>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                overflow: "auto",
                                maxHeight: "190px",
                              }}
                            >
                              {this.state.voucherDetail.validKecamatan.map(
                                (kec, index) => (
                                  <div className="desc-voucher" key={index}>
                                    {kec}
                                  </div>
                                )
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row style={{ marginBottom: "20px" }}>
                          <Col xs={12} md={3}>
                            <div className="title-voucher">
                              {this.state.locale_string.validUrbanVillage}
                            </div>
                          </Col>
                          <Col xs={12} md={5}>
                            <div
                              style={{
                                borderBottom: "1px solid black",
                                overflow: "auto",
                                maxHeight: "190px",
                              }}
                            >
                              {this.state.voucherDetail.validKelurahan.map(
                                (kel, index) => (
                                  <div className="desc-voucher" key={index}>
                                    {kel}
                                  </div>
                                )
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      <Alert
                        variant="danger"
                        style={{ width: "100%", height: "auto" }}
                      >
                        {this.state.locale_string.emptyAlert}
                      </Alert>
                    )}
                  </AccordionDetails>
                </Accordion>
                <hr></hr>
                <Accordion elevation={0} className={classes.MuiAccordionroot}>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownOutlinedIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <div className="accordion-title">
                      <b>{this.state.locale_string.voucherCode}</b>
                    </div>
                  </AccordionSummary>
                  <hr></hr>
                  <AccordionDetails>
                    {this.state.voucherCode &&
                      this.state.voucherCode.length > 0 ? (
                      <div className="roundedbox">
                        <Table
                          columns={this.state.columns}
                          dataSource={this.state.voucherCode}
                          scroll={{ x: 700 }}
                          pagination={false}
                          style={{ width: "100%" }}
                          onChange={this.handleChangeTable}
                          rowKey="voucherNumber"
                        />
                        <CustomPagination
                          pagination={this.state.pagination}
                          onPagination={this.onPagination}
                        />
                      </div>
                    ) : (
                      <Alert
                        variant="danger"
                        style={{ width: "100%", height: "auto" }}
                      >
                        {this.state.locale_string.emptyAlert}
                      </Alert>
                    )}
                  </AccordionDetails>
                </Accordion>
                <hr></hr>
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.userData.token,
    userID: state.userData.userID,
    status: state.userData.status,
  };
};
export default connect(mapStateToProps)(
  withStyles(style, { withTheme: true })(Voucher)
);
