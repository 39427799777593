import { notification } from "antd";

const notificationPosition = "Bottom";
const durationNotification = 10;

export const errorNotificationAlert = (title, content) => {
  notification.error({
    message: title,
    description: content,
    duration: durationNotification,
    placement: notificationPosition,
    style: {
      color: 'rgba(0, 0, 0, 0.65)',
      border: '1px solid #ffa39e',
      backgroundColor: '#fff1f0',
    },
  });
}
