import React from 'react'
import styles from './changepassword.module.css'

class ChangePassword extends React.Component{
    render(){
        return(
            <div className={styles.container}>
                <div className={styles.marginTop}></div>
                <div className={styles.formContainer}>
                    <div className={styles.forgotPasswordCaption}><p>Masukkan password baru</p>
                        </div>
                    <div className={styles.newpasswordContainer}>
                        Kata Sandi Baru
                    </div>
                    <div>
                        <input type="text" className={styles.emailInputText}/>
                    </div>
                    <div className={styles.confirmpasswordContainer}>
                        Konfirmasi Kata Sandi Baru
                    </div>
                    <div>
                        <input type="text" className={styles.emailInputText}/>
                    </div>
                    <div className={styles.sendButtonContainer}>
                        <input type="button" value="Kirim" className={styles.sendButton}/>
                    </div>
                </div>
            </div>
        )
    }
}
export default ChangePassword
