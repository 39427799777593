import React from "react";
import styles from "./dashboard.module.css";
import NumberFormat from "react-number-format";
import { Doughnut } from "react-chartjs-2";
import {
  LOCALIZATION_STRING_EN,
  LOCALIZATION_STRING_ID,
} from "../../localization_string/localization_global";
import "antd/dist/antd.css";
import { Col, Row } from "react-bootstrap";

class ContentDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: "id",
      locale_string: {},
    };
  }

  getLocaleString = () => {
    if (this.state.locale === "id") {
      this.setState({ locale_string: LOCALIZATION_STRING_ID });
    } else {
      this.setState({ locale_string: LOCALIZATION_STRING_EN });
    }
  };

  componentDidMount() {
    this.getLocaleString();
  }

  calculateLowerMillionTotal() {
    let result =
      this.props.totalTransactionData.amount -
      Math.floor(this.props.totalTransactionData.amount / 1000000) * 1000000;
    let newResult;
    if (parseInt(result).toString().length % 3 === 0) {
      newResult = result;
    } else {
      newResult = "0" + result;
    }
    return newResult;
  }

  calculateLowerMillionDropSize() {
    let result =
      this.props.dropSizeInvoiceData.invoice -
      Math.floor(this.props.dropSizeInvoiceData.invoice / 1000000) * 1000000;
    let newResult;
    if (parseInt(result).toString().length % 3 === 0) {
      newResult = result;
    } else {
      newResult = "0" + result;
    }
    return newResult;
  }

  render() {
    return (
      <>
        <Row className="justify-content-md-center">
          <Col xs md={6} style={{ marginTop: "10px" }}>
            <div className={styles.dashboardContainerBox}>
              <div className={styles.dashboardTopCaption}>
                {this.state.locale_string.dashboardTotalTransactionTitle}
              </div>
              <div className={styles.dashboardTotalTransactionContainer}>
                <div className={styles.dashboardTotalTransaction}>
                  {this.props.totalTransactionData.counter
                    ? this.props.totalTransactionData.counter
                    : 0}
                </div>
                <div className={styles.dashboardTotalTransactionCaption}>
                  {this.state.locale_string.dashboardTotalTransactionCaption}
                </div>
              </div>
              <div className={styles.dashboardJumlahTotalContainer}>
                <div className={styles.dashboardJumlahTotalCurrency}>
                  <sub>Rp.</sub>
                </div>
                <div className={styles.dashboardJumlahTotal}>
                  {this.props.totalTransactionData.amount ? (
                    this.props.totalTransactionData.amount > 1000000 ? (
                      <span>
                        <span className={styles.upperMillion}>
                          <NumberFormat
                            value={Math.floor(
                              this.props.totalTransactionData.amount / 1000000
                            )}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </span>
                        <span className={styles.lowerMillion}>
                          ,
                          <NumberFormat
                            value={this.calculateLowerMillionTotal()}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                          />
                        </span>
                      </span>
                    ) : (
                      <NumberFormat
                        value={this.props.totalTransactionData.amount}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    )
                  ) : (
                    0
                  )}
                </div>
                <div className={styles.dashboardTotalTransactionCaption}>
                  {this.state.locale_string.dashboardTotalAmount}
                </div>
              </div>
            </div>
          </Col>
          <Col xs md={6} style={{ marginTop: "10px" }}>
            <div className={styles.dashboardContainerBox}>
              <div className={styles.dashboardTopCaption}>
                {this.state.locale_string.dashboardVoucherTotal}
              </div>
              <div className={styles.dashboardTotalTransactionContainer}>
                <div className={styles.dashboardTotalTransaction}>
                  {this.props.totalVoucherData.distributedVoucher}
                </div>
                <div className={styles.dashboardTotalTransactionCaption}>
                  {this.state.locale_string.dashboardVoucherDistributed}
                </div>
              </div>
              <div className={styles.dashboardTotalTransactionContainer}>
                <div className={styles.dashboardTotalTransaction}>
                  {this.props.totalVoucherData.unDistributedVoucher}
                </div>
                <div className={styles.dashboardTotalTransactionCaption}>
                  {this.state.locale_string.dashboardVoucherUndistributed}
                </div>
              </div>
              <div className={styles.dashboardTotalTransactionContainer}>
                <div className={styles.dashboardTotalTransaction}>
                  {this.props.totalVoucherData.redeemedVoucher}
                </div>
                <div className={styles.dashboardTotalTransactionCaption}>
                  {this.state.locale_string.dashboardVoucherRedeemed}
                </div>
              </div>
              <div className={styles.dashboardTotalTransactionContainer}>
                <div className={styles.dashboardTotalTransaction}>
                  {this.props.totalVoucherData.totalVoucher}
                </div>
                <div className={styles.dashboardTotalTransactionCaption}>
                  {this.state.locale_string.dashboardVoucherTotal}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs md={6} style={{ marginTop: "10px" }}>
            <div className={styles.dashboardContainerBox}>
              <div className={styles.dashboardChartCaption}>
                {this.state.locale_string.dashboardTransactionFailedTitle}
              </div>
              <div className={styles.dashboardChartContainer}>
                <div className={styles.dashboardChartLeft}>
                  <Doughnut ref="chart" data={this.props.chartData} />
                </div>
                <div className={styles.dashboardChartRight}>
                  <div className={styles.dashboardTotalTransactionContainer}>
                    <div className={styles.dashboardTotalTransaction}>
                      {this.props.failedTransactionCounterData
                        ? this.props.failedTransactionCounterData
                        : 0}
                    </div>
                    <div className={styles.dashboardTotalTransactionCaption}>
                      {
                        this.state.locale_string
                          .dashboardTransactionFailedCaption
                      }
                    </div>
                  </div>
                  <div className={styles.dashboardChartLegendContainer}>
                    <div className={styles.dashboardChartLegendCircleOrange} />
                    <div className={styles.dashboardChartLegendText}>
                      <div className={styles.dashboardLegendCaption}>
                        {this.state.locale_string.dashboardStockout}
                      </div>
                      <div className={styles.dashboardLegendValue}>
                        {this.props.failedTransactionDetailData.stock
                          ? this.props.failedTransactionDetailData.stock
                          : 0}
                      </div>
                    </div>
                  </div>
                  <div className={styles.dashboardChartLegendContainer}>
                    <div className={styles.dashboardChartLegendCircleYellow} />
                    <div className={styles.dashboardChartLegendText}>
                      <div className={styles.dashboardLegendCaption}>
                        {this.state.locale_string.dashboardCreditLimit}
                      </div>
                      <div className={styles.dashboardLegendValue}>
                        {this.props.failedTransactionLimit
                          ? this.props.failedTransactionLimit
                          : 0}
                      </div>
                    </div>
                  </div>
                  <div className={styles.dashboardChartLegendContainer}>
                    <div className={styles.dashboardChartLegendCircleCyan} />
                    <div className={styles.dashboardChartLegendText}>
                      <div className={styles.dashboardLegendCaption}>
                        {this.state.locale_string.dashboardAllocation}
                      </div>
                      <div className={styles.dashboardLegendValue}>
                        {this.props.failedTransactionDetailData.allocation
                          ? this.props.failedTransactionDetailData.allocation
                          : 0}
                      </div>
                    </div>
                  </div>
                  <div className={styles.dashboardChartLegendContainer}>
                    <div className={styles.dashboardChartLegendCircleCrimson} />
                    <div className={styles.dashboardChartLegendText}>
                      <div className={styles.dashboardLegendCaption}>
                        {this.state.locale_string.dashboardRejected}
                      </div>
                      <div className={styles.dashboardLegendValue}>
                        {this.props.failedTransactionRejected
                          ? this.props.failedTransactionRejected
                          : 0}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs md={6} style={{ marginTop: "10px" }}>
            <div className={styles.dashboardContainerBox}>
              <div className={styles.dashboardTopCaption}>
                {this.state.locale_string.dashboardDropSizeTitle}
              </div>
              <div className={styles.dashboardTotalTransactionContainer}>
                <div className={styles.dashboardTotalTransaction}>
                  {this.props.dropSizeSKUData.totalSKU
                    ? this.props.dropSizeSKUData.totalSKU
                    : 0}
                </div>
                <div className={styles.dashboardTotalTransactionCaption}>
                  {this.state.locale_string.dashboardJumlahSKU}
                </div>
              </div>
              <div className={styles.dashboardJumlahTotalContainer}>
                <div className={styles.dashboardJumlahTotalCurrency}>
                  <sub>Rp.</sub>
                </div>
                <div className={styles.dashboardJumlahTotal}>
                  {this.props.dropSizeInvoiceData.invoice ? (
                    this.props.dropSizeInvoiceData.invoice > 1000000 ? (
                      <span>
                        <span className={styles.upperMillion}>
                          <NumberFormat
                            value={Math.floor(
                              this.props.dropSizeInvoiceData.invoice / 1000000
                            )}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </span>

                        <span className={styles.lowerMillion}>
                          ,
                          <NumberFormat
                            value={this.calculateLowerMillionDropSize()}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                          />
                        </span>
                      </span>
                    ) : (
                      <NumberFormat
                        value={this.props.dropSizeInvoiceData.invoice}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    )
                  ) : (
                    0
                  )}
                </div>
                <div className={styles.dashboardRataInvoiceCaption}>
                  {this.state.locale_string.dashboardRataInvoice}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}

export default ContentDashboard;
