import React from "react";
import styles from "./homePage.module.css";
import People from "../../support/homepage/PeopleAndroid.png";
import Here from "../../support/homepage/HereGetCloser.png";
import WhyJoinUs from "../../support/homepage/WhyJoinUs.png";
import OurBusiness from "../../support/homepage/OurBusiness.png";
import Contact from "../../support/homepage/ForeMoreInf.png";
import Marketplace from "../../support/homepage/Marketplace.png";
import Chat from "../../support/homepage/Chat.png";
import Salesman from "../../support/homepage/Salesman.png";
import Footer from "./footer";

class HomePage extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.bodyTop}>
          <div className={styles.bodyTopLeft}>
            <img alt={"Model"} src={People} className={styles.imagePeople} />
          </div>
          <div className={styles.bodyTopRight}>
            <div className={styles.bodyTopRightUpper}>
              <img alt={"Text1"} src={Here} className={styles.hereGetCloser} />
            </div>
          </div>
        </div>
        <div className={styles.bodyBottom}>
          <div className={styles.bodyBottomLeft}>
            <img
              alt={"Text2"}
              src={OurBusiness}
              className={styles.ourBusiness}
            />
            <img alt={"Text3"} src={Contact} className={styles.contact} />
          </div>
          <div className={styles.bodyBottomRight}>
            <div className={styles.bodyBottomRightUpper}>
              <img
                alt={"Text4"}
                src={WhyJoinUs}
                className={styles.whyJoinUs2}
              />
            </div>
            <div className={styles.bodyBottomRightLower}>
              <div className={styles.bodyBottomRightLowerChild}>
                <img
                  alt={"Marketplace"}
                  src={Marketplace}
                  className={styles.marketPlace}
                />
              </div>
              <div className={styles.bodyBottomRightLowerChild}>
                <img alt={"Chat"} src={Chat} className={styles.chat} />
              </div>
              <div className={styles.bodyBottomRightLowerChild}>
                <img
                  alt={"Salesman"}
                  src={Salesman}
                  className={styles.salesman}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.mobileMode}>
          <img alt={"Text2"} src={OurBusiness} className={styles.imageMobile} />
          <img alt={"Text4"} src={WhyJoinUs} className={styles.imageMobile} />
          <img
            alt={"Marketplace"}
            src={Marketplace}
            className={styles.imageMobile}
          />
          <img alt={"Chat"} src={Chat} className={styles.imageMobile} />
          <img alt={"Salesman"} src={Salesman} className={styles.imageMobile} />
        </div>
        <Footer />
      </div>
    );
  }
}

export default HomePage;
