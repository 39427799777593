export const ROUTES = {
  DASHBOARD: 'dashboard',
  VOUCHER: 'voucher',
  DAILY_TRANSACTION: 'daily-transaction',
  DAILY_TRANSACTION_DETAIL: 'daily-transaction-detail',
  DAILY_TRANSACTION_SELLER: 'daily-transaction-seller',
  DAILY_TRANSACTION_BUYER_SELLER: 'daily-transaction-buyer-seller',
  FULFILLMENT_RATE: 'fulfillment-rate',
  ACTIVE_BUYER: 'active-buyer',
  ACTIVE_SELLER: 'active-seller',
  CUSTOMER_REGISTRATION: 'customer-registration',
  USERPROFILE: 'userprofile',
  USERPROFILE_CHANGEPASSWORD: 'userprofile/changepassword',
  USERMANAGEMENT: 'usermanagement',
  USERMANAGEMENT_ID: 'usermanagement/:id',
};
