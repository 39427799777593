export const LOCALIZATION_STRING_EN = {
  loginTitle: "d a s h b o a r d",
  loginUsernameCaption: "Email",
  loginPasswordCaption: "Password",
  loginButton: "Login",
  loginErrorNoUsernamePassword: "Username dan Password field are empty",
  loginErrorWrongUsername: "Wrong Username / Password",
  loginErrorNoUsername: "Username field is empty",
  loginErrorNoPassword: "Password field is empty",
  loginErrorNoConnection:
    "No Internet Connection. Please Check your connection",
    dashboardLogout: "Logout",
  dashboardPeriode: "Period",
  dashboardDateFrom: "From",
  dashboardDateUntil: "Until",
  dashboardTotalTransactionTitle: "Total Transaction",
  dashboardTransactionFailedCaption: "Failed Transaction",
  dashboardTotalTransactionCaption: "Total Transaction",
  dashboardMillion: "M",
  dashboardTotalAmount: "Total Amount",
  dashboardTransactionFailedTitle: "Failed Transaction",
  dashboardStockout: "Stockout",
  dashboardCreditLimit: "Credit Limit",
  dashboardRataInvoice: "Average Invoice Value",
  dashboardAllocation: "Allocation",
  dashboardRejected: "Rejected",
  dashboardDropSizeTitle: "Drop Size",
  dashboardJumlahSKU: "SKU Amount",
  dashboardSideReport: "Report",
  dashboardDailyTransaction: "Daily Transaction",
  dashboardDailyTransactionBySeller: "Daily Transaction By Seller",
  dashboardDailyTransactionDetail: "Daily Transaction Detail",
  dashboardDailyTransactionByBuyerSeller:
    "Daily Transaction By Buyer And Seller",
  dashboardSideActiveBuyer: "Active Buyer List",
  dashboardSideActiveSeller: "Active Seller List",
  dashboardFulfillmentRate: "Fulfillment Rate",
  dashboardCustomerRegistration: "Customer Registration",
  dashboardReportNotAvailable: "Report Feature is not implemented yet",
  downloadFailed: "Download Failed",
  allDistributor: "All Merchant",
  allPrincipal: "All Principal",
  formatChoosen: "Pilihan Format",
  downloadPdf: "Download Pdf",
  downloadCsv: "Download Csv",
  downloadExcel: "Download Excel",
  voucher: "Voucher",
  voucherList: "Voucher List",
  distributor: "Distributor",
  principal: "Principal",
  download: "Download",
  pdf: "PDF",
  csv: "CSV",
  excel: "EXCEL",
  dashboardVoucher: "Voucher",
  dashboardVoucherList: "Voucher List",
  dashboardVoucherTotal: "Voucher Total",
  dashboardVoucherDistributed: "Distributed Voucher",
  dashboardVoucherRedeemed: "Redeemed Voucher",
  dashboardVoucherUndistributed: "Undistributed Voucher",
  dashboardHome: "Dashboard",
  voucherName: "Voucher Name",
  voucherDetail: "Voucher Detail",
  activePromo: "Only show active promotion",
  voucherDistribution: "Voucher Distribution",
  withScheduler: "with scheduler",
  autoDistribution: "Automatic Distribution",
  tnc: "Term and Condition",
  of: "of",
  voucherCode: "Voucher Table",
  redeemed: "Redeemed",
  
  emptyAlert: "Please select distributor and voucher name",
  systemConfiguration: "System Configuration",
  userManagement: "User Management",
  userProfile: "User Profile",

  searchFieldUserManagement: "Search by Name or Username...",
  createNewUser: "CREATE NEW USER",
  cancelButton: "CANCEL",
  createButton: "CREATE",
  changePasswordButton: "CHANGE PASSWORD",
  activeGromateButton: "ACTIVATE GROMATE",
  editButton: "EDIT",
  nikModalTitle: "NIK PHOTO",
  deleteButton: "DELETE",

  noButton: "No",
  yesButton: "Yes",
  okButton: "Ok",
};
