import React from "react";
import styles from "./feature.module.css";
import Gromart from "../../support/homepage/GromartFeature.png";
import GroUp from "../../support/homepage/WithGroUp.png";

class Feature extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.left}>
          <img alt={"Gromart"} src={Gromart} className={styles.text1} />
        </div>
        <div className={styles.center}>
          <div className={styles.image}></div>
        </div>
        <div className={styles.right}>
          <img alt={"GroUp"} src={GroUp} className={styles.text2} />
        </div>
      </div>
    );
  }
}

export default Feature;
