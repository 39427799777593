import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = { distributorList: [] };

const distributorReducer = createSlice({
    name: "Distributor",
    initialState: INITIAL_STATE,
    reducers: {
        setDistributorList(state, action) {
            state.distributorList = action.payload;
        },
        clearDistributor() {
            return INITIAL_STATE;
        }
    }
});

export const distributorReducerAction = distributorReducer.actions;

export default distributorReducer.reducer;