import { createSlice } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";

const INITIAL_STATE = { token: "", userID: "", status: "", authClientId: "", principalId : "", principalName: "", firstName: "", lastName: "", level: "", isMitrago: false };

const userReducer = createSlice({
    name: "User",
    initialState: INITIAL_STATE,
    reducers: {
        getLoginData(state, action) {
            state.token = action.payload.user_token;
            state.userID = action.payload.userModel.userID;
            state.status = action.payload.userModel.status;
            state.authClientId = action.payload.userModel.auth.client_id;
        },
        onLogout() {
            storage.removeItem('persist:root');
            return INITIAL_STATE;
        },
        setUserProfile(state, action) {
            state.principalId = action.payload.principal_id;
            state.principalName = action.payload.principal_name;
            state.firstName = action.payload.first_name;
            state.lastName = action.payload.last_name;
            state.level = action.payload.level;
            state.isMitrago = action.payload.is_mitragro;
        }
    }
});

export const userReducerAction = userReducer.actions;

export default userReducer.reducer;