import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import userReducer from './reducers/userReducer';
import distributorReducer from './reducers/distributorReducer';
import voucherReducer from './reducers/voucherReducer';
import localeReducer from './reducers/localeReducer'

const rootReducer = combineReducers({
    userData: userReducer,
    localeData: localeReducer,
    distributor: distributorReducer,
    voucher : voucherReducer
});

const persistConfig = {
    key: "root",
    version: 1,
    storage,
    whitelist: ['userData'],
    blacklist: []
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
  });

export default store;