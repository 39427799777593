import { apiServer, ngChatAPI } from "../config/AppConfig";
import axios from "axios";

function setHeaderToken(token) {
  return {
    "X-AUTH-TOKEN-GC": token,
  };
}

const UserProfileService = {
  getTokenChatServer() {
    return axios({
      method: "get",
      url: ngChatAPI + "/tara/v3/auth/token/internal/generate",
      crossOrigin: true,
    });
  },

  getUserDetail(token, authClientId) {
    return axios({
      method: "get",
      url: apiServer + "/lo/sif/principalstaff/admin/detail/staff/" + authClientId,
      headers: setHeaderToken(token),
      crossOrigin: true,
    });
  },

  updateUserStaff(token, data) {
    return axios({
      method: "post",
      url: apiServer + "/lo/sif/principalstaff/update",
      headers: setHeaderToken(token),
      crossOrigin: true,
      data: data
    })
  },

  updatePassword(token, data) {
    return axios({
      method: "post",
      url: apiServer + "/lo/sif/principalstaff/change_password",
      headers: setHeaderToken(token),
      crossOrigin: true,
      data: data
    })
  },
}

export default UserProfileService; 