import React from "react";
import {
  LOCALIZATION_STRING_EN,
  LOCALIZATION_STRING_ID,
} from "../../localization_string/localization_global";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { Table, Space } from "antd";
import Button from "@material-ui/core/Button";
import CustomPagination from "../voucher/CustomPagination";
import SearchIcon from '@material-ui/icons/Search';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import "./user_management.css";
import "../voucher/voucher.css"
import { withStyles } from "@material-ui/core/styles";
import { USER_MANAGEMENT } from "../../_constants/userManagement.constants";
import UserManagementService from "../../services/userManagementService";
import BorderColorIcon from '@material-ui/icons/BorderColor';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  Link,
  withRouter
} from "react-router-dom";
import { compose } from "redux";
import { modalInfo, modalConfirmation } from "../modalCustom/modalCustom";

const styles = (theme) => ({
  searchBarOutlineNoBorder: {
    border: "none",
  }
})

class UserManagement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: "id",
      locale_string: {},
      columns: [
        {
          title: USER_MANAGEMENT.USER_MANAGEMENT_NIK,
          dataIndex: "ktp",
          fixed: "left",
          width: "8vw",
          align: "center",
          sorter: true,
          render: (ktp) => `${ktp}`,
        },
        {
          title: USER_MANAGEMENT.USER_MANAGEMENT_FIRSTNAME,
          dataIndex: "firstName",
          fixed: "left",
          width: "8vw",
          align: "center",
          sorter: true,
          render: (firstName) => `${firstName}`,
        },
        {
          title: USER_MANAGEMENT.USER_MANAGEMENT_LASTNAME,
          dataIndex: "lastName",
          fixed: "left",
          width: "8vw",
          align: "center",
          sorter: true,
          render: (lastName) => `${lastName}`,
        },
        {
          title: USER_MANAGEMENT.USER_MANAGEMENT_USERNAME,
          dataIndex: "username",
          fixed: "left",
          width: "8vw",
          align: "center",
          sorter: true,
          render: (username) => `${username}`,
        },
        {
          title: USER_MANAGEMENT.USER_MANAGEMENT_LEVEL,
          dataIndex: "level",
          width: "5vw",
          align: "center",
          sorter: true,
          render: (level) => `${level === "0" ? "Admin" : "Staff"}`,
        },
        {
          title: USER_MANAGEMENT.USER_MANAGEMENT_GENDER,
          dataIndex: "gender",
          width: "6vw",
          align: "center",
          sorter: true,
          render: (gender) => `${gender === "1" ? "Male" : "Female"}`,
        },
        {
          title: USER_MANAGEMENT.USER_MANAGEMENT_EMAIL,
          dataIndex: "email",
          align: "center",
          render: (email) => `${email}`,
        },
        {
          title: USER_MANAGEMENT.USER_MANAGEMENT_PHONE,
          dataIndex: "phone",
          align: "center",
          render: (phone) => `${phone}`,
        },
        {
          title: USER_MANAGEMENT.USER_MANAGEMENT_STATUS,
          dataIndex: "status",
          width: "6vw",
          align: "center",
          render: (status) => `${status === "A" ? "Active" : "In Active"}`,
        },
        {
          title: USER_MANAGEMENT.USER_MANAGEMENT_ACTION,
          align: "center",
          render: (userData) => (
            <Space size='small'>
              <Link to={`usermanagement/${userData.id}`} style={{ textDecoration: "none" }}>
                <Button className="editAndDeleteButton">
                  <BorderColorIcon className="buttonSize" color="secondary" />
                </Button>
              </Link>
              <Button className="editAndDeleteButton" onClick={() => this.showDeleteConfirmation(userData)}>
                <DeleteIcon className="buttonSize" color="secondary" />
              </Button>
            </Space>
          ),
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      orderBy: "KTP",
      searchQuery: "",
      dataListUser: [],
    };
  }

  componentDidMount() {
    this.getLocaleString();
    let token = this.props.token;
    this.getUserList(token, this.initialParams());
  }

  initialParams = () => {
    return {
      pageSize: this.state.pagination.pageSize,
      pageIndex: this.state.pagination.current,
      query: this.state.searchQuery,
      orderBy: this.state.orderBy,
      principalId: this.props.userPrincipalId,
    };
  }

  getUserList = (token, params) => {
    UserManagementService.getUserManagementList(token, params).then((response) => {
      this.setState({
        dataListUser: response.data.additionalEntity.result.result,
        pagination: {
          ...this.state.pagination,
          total: response.data.additionalEntity.result.actualRowCount,
          pageSize: response.data.additionalEntity.result.pageSize,
          current: response.data.additionalEntity.result.currentPageIndex,
        },
      });
    }).catch((error) => {
      console.log(error);
    })
  }

  getLocaleString = () => {
    if (this.state.locale === "id") {
      this.setState({ locale_string: LOCALIZATION_STRING_ID });
    } else {
      this.setState({ locale_string: LOCALIZATION_STRING_EN });
    }
  };

  onPagination = (currentIndex, currentSize) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          current: currentIndex,
          pageSize: currentSize,
        },
      },

      () => {
        this.getUserList(
          this.props.token,
          this.initialParams()
        );
      }
    );
  };

  deactiveUserByPrincipalStaffId(token, userValue) {
    UserManagementService.deactiveByPrincipalStaffId(token, userValue.id).then(
      (response) => {
        modalInfo(
          USER_MANAGEMENT.USER_MANAGEMENT_MODAL_TITLE_INFO,
          userValue.username + USER_MANAGEMENT.USER_MANAGEMENT_MODAL_CONTENT_INFO_SUCCESS_DEACTIVATE,
          this.state.locale_string.okButton
        );
        this.getUserList(token, this.initialParams());
      }).catch((error) => {
        modalInfo(
          USER_MANAGEMENT.USER_MANAGEMENT_MODAL_TITLE_INFO_ALERT,
          error.response.data.description,
          this.state.locale_string.okButton
        );
      });
  }

  showDeleteConfirmation = (userValue) => {
    let token = this.props.token;
    modalConfirmation(
      USER_MANAGEMENT.USER_MANAGEMENT_MODAL_TITLE_CONFIRMATION_DELETE + userValue.username + "?",
      USER_MANAGEMENT.USER_MANAGEMENT_MODAL_CONTENT_CONFIRMATION_DELETE,
      this.state.locale_string.deleteButton,
      this.state.locale_string.cancelButton,
      () => this.deactiveUserByPrincipalStaffId(token, userValue)
    );
  }

  handleSearch = (event) => {
    this.setState(
      { searchQuery: event.target.value },
      () => {
        this.getUserList(this.props.token, this.initialParams())
      }
    );
  }

  filterValueField = (value) => {
    if (value === "firstName") {
      return "FIRST_NAME";
    }

    if (value === "lastName") {
      return "LAST_NAME";
    }

    return value;
  }

  handleChangeTable = (pagination, filters, sorter) => {
    let paramOrderBy = "KTP";
    if (sorter.order === "ascend") {
      paramOrderBy = this.filterValueField(sorter.field);
    }

    if (sorter.order === "descend") {
      paramOrderBy = "-" + this.filterValueField(sorter.field);
    }

    this.setState(
      { orderBy: paramOrderBy },
      () => {
        this.getUserList(this.props.token, this.initialParams());
      }
    );
  }

  render() {
    const { classes } = this.props;
    const url = this.props.match.url;
    return (
      <>
        <Row className="justify-content-md-center">
          <Col xs md={12} style={{ marginTop: "10px" }}>
            <div className="pageTitle">
              {this.state.locale_string.userManagement}
            </div>
            <div className="white-box white-box-usermanagement">
              <div>
                <TextField
                  placeholder={this.state.locale_string.searchFieldUserManagement}
                  type="search"
                  variant="outlined"
                  size="small"
                  className="searchBarUserManagement"
                  onChange={(event) => this.handleSearch(event)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon color="disabled" />
                      </InputAdornment>
                    ),
                    classes: { notchedOutline: classes.searchBarOutlineNoBorder }
                  }}
                />

                <Link to={`${url}/add`} style={{ textDecoration: "none" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    className="createNewUserButton"
                    size="large"
                  >
                    {this.state.locale_string.createNewUser}
                  </Button>
                </Link>
              </div>

              <br />

              <div className="roundedbox">
                <Table
                  columns={this.state.columns}
                  dataSource={this.state.dataListUser}
                  scroll={{ x: 900 }}
                  pagination={false}
                  style={{ width: "100%" }}
                  rowKey="id"
                  onChange={this.handleChangeTable}
                />
                <CustomPagination
                  pagination={this.state.pagination}
                  onPagination={this.onPagination}
                />
              </div>

            </div>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.userData.token,
    userID: state.userData.userID,
    status: state.userData.status,
    userPrincipalId: state.userData.principalId
  };
};

export default compose(connect(mapStateToProps), withRouter)
  (withStyles(styles, { withTheme: true })(UserManagement)
  );