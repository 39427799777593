import { apiServer } from "../config/AppConfig";
import axios from "axios";

function setHeaderTokenAndContentType(token) {
  return {
    "X-AUTH-TOKEN-GC": token,
    'Content-Type': 'multipart/form-data'
  };
}

const UploadService = {
  uploadFile(token, fileData) {
    let body = new FormData();
    body.append("file", fileData)
    return axios({
      method: "post",
      url: apiServer + "/api/file/upload",
      data: body,
      headers: setHeaderTokenAndContentType(token),
      crossOrigin: true,
    });
  }
}

export default UploadService; 