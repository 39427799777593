import React from "react";
import "./App.css";
import { Route, withRouter } from "react-router-dom";
import Dashboard from "./components/dashboard/dashboard";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Verification from "./components/login/verification/verification";
import InputOTPCode from "./components/login/verification/inputOTPCode";
import ForgotPassword from "./components/login/forgotpassword/forgotpassword";
import ChangePassword from "./components/login/changepassword/changepassword";
import Login from "./components/login/loginV2";
import Voucher from "./components/voucher/voucher";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import CustomPagination from "./components/voucher/CustomPagination";

const theme = createTheme({
  typography: {
    fontFamily: ["Rubik"].join(","),
  },
});

function App() {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <Route exact path="/" component={Login} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/voucher" component={Voucher} />
          <Route path="/verificationOTP" component={Verification} />
          <Route path="/inputOTPCode" component={InputOTPCode} />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/changepassword" component={ChangePassword} />
          <Route path="/pagination" component={CustomPagination} />
          <Route
            path="/admin"
            component={() => {
              window.location.href = "https://gromart.club/admin";
              return null;
            }}
          />
        </div>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default withRouter(App);
