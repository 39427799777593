export const apiServer = chooseEnviromentApiServer(process.env.REACT_APP_HOST_ENV);
export const ngChatAPI = chooseEnviromentNgChatApiServer(process.env.REACT_APP_HOST_ENV);

function chooseEnviromentApiServer(enviroment) {
  const defaultEnviroment = "https://10.10.8.61:8282/kenarok";
  if (enviroment === "localstaging") {
    return defaultEnviroment;
  }
  if (enviroment === "staging") {
    return "https://staging.gromart.club:7443/marketplace";
  }
  if (enviroment === "sandbox") {
    return "https://sandbox.gromart.club:8443/marketplace";
  }
  if (enviroment === "production") {
    return "https://www.gromart.club/marketplace";
  }
  return defaultEnviroment;
}

function chooseEnviromentNgChatApiServer(enviroment) {
  const defaultEnviromentStaging = "https://staging-ngchat.gromart.club";
  if (enviroment === "localstaging") {
    return defaultEnviromentStaging;
  }
  if (enviroment === "staging") {
    return defaultEnviromentStaging;
  }
  if (enviroment === "sandbox") {
    return "https://sandbox-ngchat.gromart.club:7443";
  }
  if (enviroment === "production") {
    return "https://grochat.id";
  }
  return defaultEnviromentStaging;
}