import React from 'react'
import styles from './verification.module.css'

class Verification extends React.Component{
    render(){
        return(
            <div className={styles.container}>
                <div className={styles.marginTop}></div>
                <div className={styles.formContainer}>
                    <div className={styles.otpCaption}>
                        <p>Masukkan link untuk mengirim</p>
                        <p>link reset password</p>
                    </div>
                    <div className={styles.emailCaption}>Email</div>
                    <div>
                        <input type="text" className={styles.emailInputText}/>
                    </div>
                    <div className={styles.sendOTPButtonContainer}>
                        <input className={styles.sendOTPButton} type="button" value="Kirim"/>
                    </div>
                </div>
            </div>
        )
    }
}
export default Verification
