import { apiServer } from "../config/AppConfig";
import axios from "axios";

function setHeaderToken(token) {
  return { 
    "X-AUTH-TOKEN-GC": token, 
  };
}

const UserManagementService = {
  getUserManagementList(token, param) {
    return axios({
      method: "get",
      url: apiServer + "/lo/sif/principalstaff/admin/list/",
      headers: setHeaderToken(token),
      crossOrigin: true,
      params: param
    });
  },

  getUserDetailByPrincipalId(token, principalId) {
    return axios({
      method: "get",
      url: apiServer + "/lo/sif/principalstaff/admin/detail/staff/userId/" + principalId,
      headers: setHeaderToken(token),
      crossOrigin: true,
    });
  },

  save(token, data) {
    return axios({
      method: "post",
      url: apiServer + "/lo/sif/principalstaff/admin/save",
      headers: setHeaderToken(token),
      crossOrigin: true,
      data: data
    })
  },

  deactiveByPrincipalStaffId(token, principalStaffId) {
    return axios({
      method: "put",
      url: apiServer + "/lo/sif/principalstaff/admin/deactivate_staff/" + principalStaffId,
      headers: setHeaderToken(token),
      crossOrigin: true
    });
  },

  updateUser(token, data) {
    return axios({
      method: "put",
      url: apiServer + "/lo/sif/principalstaff/admin/update/staff",
      headers: setHeaderToken(token),
      crossOrigin: true,
      data: data
    })
  },
 }

export default UserManagementService; 