export const VOUCHER_ENUM = {
    VOUCHER_NUMBER: 'Voucher No.',
    VALID_FROM_VOUCHER: 'Valid From',
    VALID_THROUGH_VOUCHER: 'Valid Through',
    VOUCHER_TYPE: 'Voucher Type',
    VOUCHER_FREEGOOD: 'Freegood',
    VOUCHER_VALUE: 'Value',
    VOUCHER_KABUPATEN_LIST: 'Kabupaten List',
    VOUCHER_ACTIVE: 'Active',
    VOUCHER_CUSTOMER_ID: 'Customer ID',
    VOUCHER_CUSTOMER_NAME: 'Customer Name',
    VOUCHER_ASSIGN_DATE: 'Assign Date',
    VOUCHER_REDEEM_DATE: 'Redeem Date',
};