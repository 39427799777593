export const USER_PROFILE = {
  USER_PROFILE_FORM_VIEW_HEADER: "Profile Pengguna",
  USER_PROFILE_FORM_EDIT_HEADER: "Ubah Profile Pengguna",

  USER_PROFILE_FORM_PLACEHOLDER_USERLEVEL: "Pilih User Level",
  USER_PROFILE_FORM_PLACEHOLDER_PROVINSI: "Pilih Provinsi",
  USER_PROFILE_FORM_PLACEHOLDER_KABUPATEN: "Pilih Kabupaten",
  USER_PROFILE_FORM_PLACEHOLDER_KECAMATAN: "Pilih Kecamatan",
  USER_PROFILE_FORM_PLACEHOLDER_KELURAHAN: "Pilih Kelurahan",

  USER_PROFILE_FORM_ERROR_PRINCIPAL_NAME: "Principal Name tidak boleh Kosong",
  USER_PROFILE_FORM_ERROR_USERLEVEL: "Silahkan pilih user level",
  USER_PROFILE_FORM_ERROR_USERNAME: "Silahkan masukan username minimal 6 karakter dengan kombinasi huruf dan angka",
  USER_PROFILE_FORM_ERROR_FIRSTNAME: "First Name tidak boleh kosong",
  USER_PROFILE_FORM_ERROR_PATTERN_FIRSTNAME : "Silahkan masukan First Name huruf pertama besar, hanya huruf diperbolehkan",
  USER_PROFILE_FORM_ERROR_PATTERN_LASTNAME : "Silahkan masukan Last Name huruf pertama besar, hanya huruf diperbolehkan",
  USER_PROFILE_FORM_ERROR_NULL_EMAIL: "Email tidak boleh kosong",
  USER_PROFILE_FORM_ERROR_VALID_EMAIL: "Silahkan Masukan Email yang valid (e.g: nama@alamatemail.com)",
  USER_PROFILE_FORM_ERROR_NULL_PHONENUMBER: "Phone Number tidak boleh kosong",
  USER_PROFILE_FORM_ERROR_VALID_PHONENUMBER: "Silahkan Masukan Phone Number yang valid (e.g: +62818080888)",
  USER_PROFILE_FORM_ERROR_RT: "Silahkan Isi RT",
  USER_PROFILE_FORM_ERROR_RW: "Silahkan Isi RW",
  USER_PROFILE_FORM_ERROR_PROVINSI: "Silahkan pilih provinsi",
  USER_PROFILE_FORM_ERROR_KABUPATEN: "Silahkan pilih kabupaten",
  USER_PROFILE_FORM_ERROR_KECAMATAN: "Silahkan pilih kecamatan",
  USER_PROFILE_FORM_ERROR_KELURAHAN: "Silahkan pilih kelurahan",
  USER_PROFILE_FORM_ERROR_POSTAL_CODE: "Postal Code tidak boleh kosong",
  USER_PROFILE_FORM_ERROR_NIK: "NIK tidak boleh kosong",
  USER_PROFILE_FORM_ERROR_FOTO_NIK: "Pastikan format foto yang diupload sudah benar (.jpg, .png) dan maximum 2MB",
  USER_PROFILE_FORM_ERROR_FILETYPE_VALIDATION: "Tipe File Salah",
  USER_PROFILE_FORM_ERROR_FILESIZE_VALIDATION: "File lebih dari 2MB",

  USER_PROFILE_CHANGE_PASSWORD_FORM_ERROR_CURRENT_PASSWORD: "Silahkan masukan kata sandi sekarang",
  USER_PROFILE_CHANGE_PASSWORD_FORM_ERROR_PASSWORD: "Silahkan masukan kata sandi baru",
  USER_PROFILE_CHANGE_PASSWORD_FORM_ERROR_CONFIRMATION_PASSWORD: "Silahkan masukan konfirmasi kata sandi baru",
  USER_PROFILE_CHANGE_PASSWORD_FORM_ERROR_NOT_MATCH_PASSWORD: "kata sandi baru dan konfirmasi kata sandi baru tidak sama",

  USER_PROFILE_GET_DETAIL_USER_FAILED_TITLE: "Alert",
  USER_PROFILE_GET_DETAIL_USER_FAILED_CONTENT: "Gagal mendapatkan profil pengguna",

  USER_PROFILE_MODAL_TITLE_CONFIRMATION: "Konfirmasi",
  USER_PROFILE_MODAL_CONTENT_CONFIRMATION_USER_EDIT: "Apakah anda yakin mau ubah user ini ?",
  USER_PROFILE_MODAL_CONTENT_CONFIRMATION_CANCEL_EDIT: "Apakah anda yakin mau batal ubah user ini ?",

  USER_PROFILE_MODAL_TITLE_INFO: "Notifikasi",
  USER_PROFILE_MODAL_TITLE_INFO_ALERT: "Alert",
  USER_PROFILE_MODAL_CONTENT_INFO_SUCCESS_UPDATEUSER: "User berhasil di ubah",
  USER_PROFILE_MODAL_CONTENT_INFO_SUCCESS_UPDATE_PASSWORD: "Kata sandi berhasil di ubah",

  USER_PROFILE_FORM_ERROR_PATTERN_PASSWORD: "Silahkan masukan password minimal 8 karakter, kombinasi 1 huruf besar, 1 huruf kecil, 1 angka dan 1 special karakter",
  USER_PROFILE_MODAL_CONTENT_CONFIRMATION_CANCEL_EDIT_PASSWORD: "Apakah anda yakin mau batal ubah kata sandi ?",
  USER_PROFILE_MODAL_CONTENT_CONFIRMATION_USER_EDIT_PASSWORD: "Apakah anda yakin mau ubah kata sandi ?",
};