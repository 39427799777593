import React from "react";
import {
  LOCALIZATION_STRING_EN,
  LOCALIZATION_STRING_ID,
} from "../../../localization_string/localization_global";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import {
  Form,
  Input,
  Space,
  Divider,
  Button,
  Select,
  DatePicker,
  Radio,
  Upload,
  Modal
} from "antd";
import "../user_management.css"
import { USER_MANAGEMENT } from "../../../_constants/userManagement.constants";
import moment from "moment";
import AreaService from "../../../services/areaService";
import UploadService from "../../../services/upload.service";
import { PlusOutlined } from '@ant-design/icons';
import UserManagementService from "../../../services/userManagementService";
import { errorNotificationAlert } from "../../notificationAlert/notificationAlert";
import { modalConfirmation, modalInfo } from "../../modalCustom/modalCustom";

const baseUrl = "/dashboard/usermanagement";

class UserManagementForm extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      locale: "id",
      locale_string: {},
      headerLabel: "",
      form: {
        principalId: null,
        principalName: null,
        userLevel: null,
        username: null,
        currentPassword: "",
        password: "",
        confirmPassword: "",
        firstName: null,
        lastName: null,
        email: null,
        phoneNumber: null,
        placeOfBirth: null,
        dateOfBirth: null,
        gender: null,
        address1: null,
        address2: null,
        address3: null,
        address4: null,
        rt_rw: null,
        provinceId: null,
        kabupatenId: null,
        kecamatanId: null,
        kelurahanId: null,
        postalCode: null,
        nik: null,
        imageNik: null
      },
      userLevelList: [],
      rt: null,
      rw: null,
      provinsiList: [],
      kabupatenList: [],
      kecamatanList: [],
      kelurahanList: [],
      fileList: [],
      requiredPasswordField: false,
      editPage: false,
      enableChangePassword: false,
      previewVisible: false,
      fieldEdit: false,
    };
  }

  componentDidMount() {
    let token = this.props.token;
    this.getLocaleString();
    // To get params id from Route
    const params = this.props.match.params;
    this.chooseHeaderLaber(params.id);
    this.initialListValue(token);

    if (params.id !== 'add') {
      UserManagementService.getUserDetailByPrincipalId(token, params.id).then((response) => {
        let principalStaffDetail = response.data.additionalEntity.result;

        AreaService.getKabupaten(token, principalStaffDetail.province_id)
          .then((responseAreaService) => {
            this.setState({
              kabupatenList: responseAreaService.data.additionalEntity.result,
            })
          })
          .catch(
            (error) => {
              this.handleErrorGetKabupaten();
            });

        AreaService.getKecamatan(token, principalStaffDetail.kabupaten_id)
          .then((responseAreaService) => {
            this.setState({
              kecamatanList: responseAreaService.data.additionalEntity.result,
            })
          })
          .catch(
            (error) => {
              this.handleErrorGetKecamatan();
            });

        AreaService.getKelurahan(token, principalStaffDetail.kecamatan_id)
          .then((responseAreaService) => {
            this.setState({
              kelurahanList: responseAreaService.data.additionalEntity.result,
            })
          })
          .catch(
            (error) => {
              this.handleErrorGetKelurahan();
            });

        let splitedRTRW = principalStaffDetail.rt_rw?.split("/") || [null, null];
        let splitedAddress = principalStaffDetail.address.split(";");

        this.setState({
          form:
          {
            ...this.state.form,
            principalId: principalStaffDetail.principal_id,
            principalName: principalStaffDetail.principal_name,
            userLevel: principalStaffDetail.level,
            username: principalStaffDetail.gromart_user_name,
            currentPassword: "",
            password: "",
            confirmPassword: "",
            firstName: principalStaffDetail.first_name,
            lastName: principalStaffDetail.last_name,
            email: principalStaffDetail.email,
            phoneNumber: principalStaffDetail.phone,
            placeOfBirth: principalStaffDetail.place_of_birth,
            dateOfBirth: principalStaffDetail.date_of_birth,
            gender: principalStaffDetail.gender,
            address1: splitedAddress[0],
            address2: splitedAddress[1],
            address3: splitedAddress[2],
            address4: splitedAddress[3],
            rt_rw: principalStaffDetail.rt_rw,
            provinceId: principalStaffDetail.province_id,
            kabupatenId: principalStaffDetail.kabupaten_id,
            kecamatanId: principalStaffDetail.kecamatan_id,
            kelurahanId: principalStaffDetail.kelurahan_id,
            postalCode: principalStaffDetail.zip_code,
            nik: principalStaffDetail.ktp,
            imageNik: principalStaffDetail.ktp_image
          },

          fileList: [
            {
              uid: "-1",
              name: 'Preview File',
              status: 'done',
              url: principalStaffDetail.ktp_image
            }
          ],

          requiredPasswordField: false,
          editPage: true,
          enableChangePassword: false,
          fieldEdit: true
        });
        this.formRef.current.setFieldsValue({
          principalName: principalStaffDetail.principal_name,
          userLevel: principalStaffDetail.level,
          username: principalStaffDetail.gromart_user_name,
          currentPassword: "",
          password: "",
          confirmPassword: "",
          firstName: principalStaffDetail.first_name,
          lastName: principalStaffDetail.last_name,
          email: principalStaffDetail.email,
          phoneNumber: principalStaffDetail.phone,
          placeOfBirth: principalStaffDetail.place_of_birth,
          dateOfBirth: moment(principalStaffDetail.date_of_birth, "YYYY-MM-DD"),
          gender: principalStaffDetail.gender,
          address1: splitedAddress[0],
          address2: splitedAddress[1],
          address3: splitedAddress[2],
          address4: splitedAddress[3],
          rt: splitedRTRW[0],
          rw: splitedRTRW[1],
          provinceId: principalStaffDetail.province_id,
          kabupatenId: principalStaffDetail.kabupaten_id,
          kecamatanId: principalStaffDetail.kecamatan_id,
          kelurahanId: principalStaffDetail.kelurahan_id,
          postalCode: principalStaffDetail.zip_code,
          nik: principalStaffDetail.ktp,
          imageNik: principalStaffDetail.ktp_image
        });
      }).catch((error) => {
        errorNotificationAlert(
          USER_MANAGEMENT.USER_MANAGEMENT_ERROR_LABEL,
          USER_MANAGEMENT.USER_MANAGEMENT_ERROR_GET_USERDETAIL
        )
      });
    } else {
      this.setState({
        form:
        {
          ...this.state.form,
          principalId: this.props.userPrincipalId,
          principalName: this.props.userPrincipalName,
          gender: 1,
        },
        requiredPasswordField: true,
        editPage: false
      });
      this.formRef.current.setFieldsValue({
        principalName: this.props.userPrincipalName,
        gender: 1,
      });
    }
  }

  initialListValue = (token) => {
    this.setState({
      userLevelList: [
        { label: "Admin", value: "0" },
        { label: "Staff", value: "1" }
      ]
    });

    this.getProvinsiList(token);
  }

  getLocaleString = () => {
    if (this.state.locale === "id") {
      this.setState({ locale_string: LOCALIZATION_STRING_ID });
    } else {
      this.setState({ locale_string: LOCALIZATION_STRING_EN });
    }
  };

  chooseHeaderLaber(urlParams) {
    if (urlParams !== "add") {
      this.setState({ headerLabel: USER_MANAGEMENT.USER_MANAGEMENT_FORM_EDIT_HEADER });
    } else {
      this.setState({ headerLabel: USER_MANAGEMENT.USER_MANAGEMENT_FORM_ADD_HEADER });
    }
  }

  handleSubmit() {
    this.formRef.current.submit();
  }

  handleErrorSaveOrUpdate = (error) => {
    modalInfo(
      USER_MANAGEMENT.USER_MANAGEMENT_MODAL_TITLE_INFO_ALERT,
      error.response.data.description,
      this.state.locale_string.okButton
    );
  }

  saveUser(params, token, dataForm) {
    if (params.id !== "add") {
      dataForm.lastName = dataForm.lastName || null;
      UserManagementService.updateUser(token, dataForm).then((response) => {
        modalInfo(
          USER_MANAGEMENT.USER_MANAGEMENT_MODAL_TITLE_INFO,
          USER_MANAGEMENT.USER_MANAGEMENT_MODAL_CONTENT_INFO_SUCCESS_UPDATEUSER,
          this.state.locale_string.okButton
        );
        this.backToBasePage();
      }).catch((error) => {
        this.handleErrorSaveOrUpdate(error);
      });
    } else {
      UserManagementService.save(token, dataForm).then((response) => {
        modalInfo(
          USER_MANAGEMENT.USER_MANAGEMENT_MODAL_TITLE_INFO,
          USER_MANAGEMENT.USER_MANAGEMENT_MODAL_CONTENT_INFO_SUCCESS_ADDUSER,
          this.state.locale_string.okButton
        );
        this.backToBasePage();
      }).catch((error) => {
        this.handleErrorSaveOrUpdate(error);
      });
    }
  }

  submitSuccessForm() {
    const params = this.props.match.params;
    let token = this.props.token;
    let dataForm = this.state.form;
    modalConfirmation(
      USER_MANAGEMENT.USER_MANAGEMENT_MODAL_TITLE_CONFIRMATION,
      USER_MANAGEMENT.USER_MANAGEMENT_MODAL_CONTENT_CONFIRMATION_SAVE,
      this.state.locale_string.yesButton,
      this.state.locale_string.noButton,
      () => this.saveUser(params, token, dataForm)
    );
  }

  getProvinsiList(token) {
    AreaService.getProvinsi(token)
      .then((response) => {
        this.setState({
          provinsiList: response.data.additionalEntity.result,
        });
      })
      .catch((error) => {
        errorNotificationAlert(
          USER_MANAGEMENT.USER_MANAGEMENT_ERROR_LABEL,
          USER_MANAGEMENT.USER_MANAGEMENT_ERROR_GET_PROVINCE
        )
      });
  }

  handleErrorGetKabupaten = () => {
    errorNotificationAlert(
      USER_MANAGEMENT.USER_MANAGEMENT_ERROR_LABEL,
      USER_MANAGEMENT.USER_MANAGEMENT_ERROR_GET_KABUPATEN
    )
  }

  handleErrorGetKecamatan = () => {
    errorNotificationAlert(
      USER_MANAGEMENT.USER_MANAGEMENT_ERROR_LABEL,
      USER_MANAGEMENT.USER_MANAGEMENT_ERROR_GET_KECAMATAN
    )
  }

  handleErrorGetKelurahan = () => {
    errorNotificationAlert(
      USER_MANAGEMENT.USER_MANAGEMENT_ERROR_LABEL,
      USER_MANAGEMENT.USER_MANAGEMENT_ERROR_GET_KELURAHAN
    )
  }

  getKabupatenListByProvinceId(token, provinceId) {
    AreaService.getKabupaten(token, provinceId)
      .then((response) => {
        this.setState({
          form: {
            ...this.state.form,
            provinceId: parseInt(provinceId),
            kabupatenId: null,
            kecamatanId: null,
            kelurahanId: null
          },
          kabupatenList: response.data.additionalEntity.result,
          kecamatanList: [],
          kelurahanList: []
        })
      })
      .catch((error) => {
        this.handleErrorGetKabupaten();
      });
  }

  getKecamatanListByKabupatenId(token, kabupatenId) {
    AreaService.getKecamatan(token, kabupatenId)
      .then((response) => {
        this.setState({
          form: {
            ...this.state.form,
            kabupatenId: parseInt(kabupatenId),
            kecamatanId: null,
            kelurahanId: null
          },
          kecamatanList: response.data.additionalEntity.result,
          kelurahanList: []
        })
      })
      .catch((error) => {
        this.handleErrorGetKecamatan();
      });
  }

  getKelurahanListByKecamatanId(token, kecamatanId) {
    AreaService.getKelurahan(token, kecamatanId)
      .then((response) => {
        this.setState({
          form: {
            ...this.state.form,
            kecamatanId: parseInt(kecamatanId),
            kelurahanId: null
          },
          kelurahanList: response.data.additionalEntity.result,
        })
      })
      .catch((error) => {
        this.handleErrorGetKelurahan();
      });
  }

  handleChange(event) {
    let fieldName = event.target.name;
    let fieldValue = event.target.value;
    this.setState({ form: { ...this.state.form, [fieldName]: fieldValue } });
  }

  handleChangeSelect(event, fieldName) {
    let fieldValue = event;
    this.setState({ form: { ...this.state.form, [fieldName]: fieldValue } });
  }

  handleChangeDate(dateValue, fieldName) {
    let fieldValue = null;
    if (dateValue !== null) {
      fieldValue = moment(dateValue).format("YYYY-MM-DD");
    }
    this.setState({ form: { ...this.state.form, [fieldName]: fieldValue } });
  }

  handleChangeRTRW(event) {
    let rt = this.state.rt;
    let rw = this.state.rw;
    if (rt === null || rt === undefined) {
      rt = ""
    }

    if (rw === null || rw === undefined) {
      rw = ""
    }

    if (event.target.name === "rt") {
      rt = event.target.value;
      this.setState({ rt: rt });
      this.setState({ form: { ...this.state.form, rt_rw: rt + "/" + rw } });
    }

    if (event.target.name === "rw") {
      rw = event.target.value;
      this.setState({ rw: rw });
      this.setState({ form: { ...this.state.form, rt_rw: rt + "/" + rw } });
    }

    if (rt === "" && rw === "") {
      this.setState({ form: { ...this.state.form, rt_rw: null } });
    }
  }

  handleChangeProvinsi(event) {
    let token = this.props.token;
    this.getKabupatenListByProvinceId(token, event);
    this.formRef.current.setFieldsValue(
      {
        kabupatenId: null,
        kecamatanId: null,
        kelurahanId: null
      });
  }

  handleChangeKabupaten(event) {
    let token = this.props.token;
    this.getKecamatanListByKabupatenId(token, event);
    this.formRef.current.setFieldsValue(
      {
        kecamatanId: null,
        kelurahanId: null
      });
  }

  handleChangeKecamatan(event) {
    let token = this.props.token;
    this.getKelurahanListByKecamatanId(token, event);
    this.formRef.current.setFieldsValue(
      {
        kelurahanId: null
      });
  }

  handleChangeKelurahan(event) {
    this.setState({
      form: {
        ...this.state.form,
        kelurahanId: parseInt(event)
      },
    })
  }

  handleChangeFile(event) {
    if (event.fileList.length === 0) {
      this.setState({ form: { ...this.state.form, imageNik: null } });
      this.formRef.current.setFieldsValue({
        imageNik: null,
      });
    }
    this.setState({ fileList: event.fileList });
  }

  uploadButtonComponent() {
    return (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    )
  }

  validationFile(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      this.setState({
        fileList: [
          {
            uid: file.uid,
            name: USER_MANAGEMENT.USER_MANAGEMENT_FORM_ERROR_FILETYPE_VALIDATION,
            status: 'error',
          }
        ]
      });
    }
    const isSizeLessThan2MB = file.size / 1024 / 1024 < 2;
    if (!isSizeLessThan2MB) {
      this.setState({
        fileList: [
          {
            uid: file.uid,
            name: USER_MANAGEMENT.USER_MANAGEMENT_FORM_ERROR_FILESIZE_VALIDATION,
            status: 'error',
          }
        ]
      });
    }
    return (isJpgOrPng && isSizeLessThan2MB) || Upload.LIST_IGNORE;
  }

  handleUploadFile(event) {
    let token = this.props.token;
    UploadService.uploadFile(token, event.file).then(
      response => {
        event.onSuccess("File Upload Success");
        this.setState({ form: { ...this.state.form, imageNik: response.data.additionalEntity.result.path } });
        this.formRef.current.setFieldsValue({
          imageNik: response.data.additionalEntity.result.path,
        });
      }
    ).catch(error => {
      errorNotificationAlert(
        USER_MANAGEMENT.USER_MANAGEMENT_ERROR_LABEL,
        USER_MANAGEMENT.USER_MANAGEMENT_ERROR_UPLOAD
      )
      event.onError("File Upload Failed");
      this.setState({ form: { ...this.state.form, imageNik: null } });
      this.formRef.current.setFieldsValue({
        imageNik: null,
      });
    })
  }

  handleDownload() {
    window.open(this.state.form.imageNik, "_blank");
  }

  handleCancel() {
    this.setState({ previewVisible: false });
  }

  handlePreview() {
    this.setState({ previewVisible: true });
  }

  backToBasePage() {
    this.props.history.push(baseUrl);
  }

  handleCancelBack() {
    modalConfirmation(
      USER_MANAGEMENT.USER_MANAGEMENT_MODAL_TITLE_CONFIRMATION,
      USER_MANAGEMENT.USER_MANAGEMENT_MODAL_CONTENT_CONFIRMATION_CANCEL_SAVE,
      this.state.locale_string.yesButton,
      this.state.locale_string.noButton,
      () => this.backToBasePage()
    );
  }

  currentPasswordComponentField() {
    return (
      <Row>
        <Col xs={12} md={3}>
          <div className="formTitle required">
            Current Password
          </div>
        </Col>
        <Col xs={7} md={4}>
          <Form.Item
            name="currentPassword"
            rules={[
              {
                required: this.state.requiredPasswordField,
                message: USER_MANAGEMENT.USER_MANAGEMENT_FORM_ERROR_CURRENT_PASSWORD,
                validateTrigger: "onSubmit"
              },
            ]}
          >
            <Input value={this.state.form.currentPassword}
              name="currentPassword"
              type="password"
              onChange={(event) => this.handleChange(event)} />
          </Form.Item>
        </Col>
        <Col xs={3} md={3}>
          <Button
            danger
            className="buttonCancelMUIStyle"
            onClick={() => this.handleButtonCancelChangePassword()}
          >
            {this.state.locale_string.cancelButton}
          </Button>
        </Col>
      </Row>
    )
  }

  passwordComponentField() {
    return (
      <Row>
        <Col xs={12} md={3}>
          <div className="formTitle required">
            Password
          </div>
        </Col>
        <Col xs={12} md={7}>
          <Form.Item
            name="password"
            rules={[
              {
                required: this.state.requiredPasswordField,
                message: USER_MANAGEMENT.USER_MANAGEMENT_FORM_ERROR_PASSWORD,
                validateTrigger: "onSubmit"
              },
              {
                pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/),
                message: USER_MANAGEMENT.USER_MANAGEMENT_FORM_ERROR_PATTERN_PASSWORD,
              },
            ]}
          >
            <Input value={this.state.form.password}
              name="password"
              type="password"
              onChange={(event) => this.handleChange(event)} />
          </Form.Item>
        </Col>
      </Row>
    )
  }

  confirmationPasswordComponentField() {
    return (
      <Row>
        <Col xs={12} md={3}>
          <div className="formTitle required">
            Confirmation Password
          </div>
        </Col>
        <Col xs={12} md={7}>
          <Form.Item
            name="confirmPassword"
            rules={[
              {
                required: this.state.requiredPasswordField,
                message: USER_MANAGEMENT.USER_MANAGEMENT_FORM_ERROR_CONFIRMATION_PASSWORD,
                validateTrigger: "onSubmit"
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(USER_MANAGEMENT.USER_MANAGEMENT_FORM_ERROR_NOT_MATCH_PASSWORD));
                },
              }),
            ]}
          >
            <Input value={this.state.form.confirmPassword}
              name="confirmPassword"
              type="password"
              onChange={(event) => this.handleChange(event)} />
          </Form.Item>
        </Col>
      </Row>
    )
  }

  changePasswordButtonField() {
    return (
      <Row>
        <Col xs={12} md={3}>
          <div className="formTitle">
            Password
          </div>
        </Col>
        <Col xs={7} md={4}>
          <Form.Item
            name="currentPassword"
            rules={[
              {
                required: this.state.requiredPasswordField,
                message: USER_MANAGEMENT.USER_MANAGEMENT_FORM_ERROR_CURRENT_PASSWORD,
                validateTrigger: "onSubmit"
              },
            ]}
          >
            <Input value={this.state.form.currentPassword}
              name="currentPassword"
              type="password"
              disabled />
          </Form.Item>
        </Col>
        <Col xs={3} md={3}>
          <Button
            danger
            className="buttonCancelMUIStyle"
            onClick={() => this.handleButtonChangePassword()}
          >
            {this.state.locale_string.changePasswordButton}
          </Button>
        </Col>
      </Row>
    )
  }

  handleButtonChangePassword() {
    this.setState(
      {
        form: {
          ...this.state.form,
          currentPassword: "",
          password: "",
          confirmPassword: ""
        },
        enableChangePassword: true,
        requiredPasswordField: true
      });
    this.formRef.current.setFieldsValue({
      currentPassword: "",
      password: "",
      confirmPassword: ""
    });
  }

  handleButtonCancelChangePassword() {
    this.setState({
      form: {
        ...this.state.form,
        currentPassword: "",
        password: "",
        confirmPassword: ""
      },
      enableChangePassword: false,
      requiredPasswordField: false
    });
    this.formRef.current.setFieldsValue({
      currentPassword: "",
      password: "",
      confirmPassword: ""
    });
    this.formRef.current.validateFields(["currentPassword"]);
  }

  editPageComponentField() {
    if (this.state.enableChangePassword) {
      return (
        <>
          {this.currentPasswordComponentField()}
          {this.passwordComponentField()}
          {this.confirmationPasswordComponentField()}
        </>
      )
    }

    return (
      <>
        {this.changePasswordButtonField()}
      </>
    )
  }

  render() {
    const { Option } = Select;
    const { form } = this.state;
    return (
      <>
        <Row className="justify-content-md-center">
          <Col xs md={12} style={{ marginTop: "10px" }}>
            <div className="pageTitle">
              {this.state.headerLabel}
            </div>
            <div className="white-box white-box-usermanagement">
              <div>
                <Form
                  ref={this.formRef}
                  onFinish={() => this.submitSuccessForm()}
                  initialValues={{ form }}
                >
                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle">
                        Principal Name
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Item
                        name="principalName"
                        rules={[
                          {
                            required: true,
                            message: USER_MANAGEMENT.USER_MANAGEMENT_FORM_ERROR_PRINCIPAL_NAME,
                          },
                        ]}
                      >
                        <Input name="principalName"
                          value={this.state.form.principalName}
                          disabled />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle required">
                        User Level
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Item
                        name="userLevel"
                        rules={[
                          {
                            required: true,
                            message: USER_MANAGEMENT.USER_MANAGEMENT_FORM_ERROR_USERLEVEL,
                          },
                        ]}
                      >

                        <Select name="userLevel"
                          placeholder={USER_MANAGEMENT.USER_MANAGEMENT_FORM_PLACEHOLDER_USERLEVEL}
                          onChange={(event) => this.handleChangeSelect(event, "userLevel")}
                        >
                          {this.state.userLevelList.map(
                            (userLevel, index) => (
                              <Option value={userLevel.value} key={index}>
                                {userLevel.label}
                              </Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle required">
                        Username
                      </div>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="username"
                        rules={[
                          {
                            required: true,
                            message: USER_MANAGEMENT.USER_MANAGEMENT_FORM_ERROR_USERNAME,
                            validateTrigger: "onSubmit"
                          },
                          {
                            pattern: new RegExp(/^(?=.*[a-z])(?=.*\d)[a-z\d]{8,}$/),
                            message: USER_MANAGEMENT.USER_MANAGEMENT_FORM_ERROR_PATTERN_USERNAME,
                          },
                        ]}
                      >
                        <Input value={this.state.form.username}
                          name="username"
                          onChange={(event) => this.handleChange(event)} 
                          disabled={this.state.fieldEdit}/>
                      </Form.Item>
                    </Col>
                  </Row>

                  {this.state.editPage ?
                    <>
                      {this.editPageComponentField()}
                    </>
                    :
                    <>
                      {this.passwordComponentField()}
                      {this.confirmationPasswordComponentField()}
                    </>
                  }

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle required">
                        First Name
                      </div>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            message: USER_MANAGEMENT.USER_MANAGEMENT_FORM_ERROR_FIRSTNAME,
                            validateTrigger: "onSubmit"
                          },
                          {
                            pattern: new RegExp(/^[A-Z]{1}[a-z]+$/),
                            message: USER_MANAGEMENT.USER_MANAGEMENT_FORM_ERROR_PATTERN_FIRSTNAME,
                          },
                        ]}
                      >
                        <Input value={this.state.form.firstName}
                          name="firstName"
                          onChange={(event) => this.handleChange(event)} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle">
                        Last Name
                      </div>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="lastName"
                        rules={[
                          {
                            pattern: new RegExp(/^[A-Z]{1}[a-z]+$/),
                            message: USER_MANAGEMENT.USER_MANAGEMENT_FORM_ERROR_PATTERN_LASTNAME,
                          }
                        ]}
                      >
                        <Input value={this.state.form.lastName}
                          name="lastName"
                          onChange={(event) => this.handleChange(event)} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle required">
                        Email
                      </div>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: USER_MANAGEMENT.USER_MANAGEMENT_FORM_ERROR_NULL_EMAIL,
                            validateTrigger: "onSubmit"
                          },
                          {
                            type: "email",
                            message: USER_MANAGEMENT.USER_MANAGEMENT_FORM_ERROR_VALID_EMAIL,
                          },
                        ]}
                      >
                        <Input value={this.state.form.email}
                          name="email"
                          onChange={(event) => this.handleChange(event)} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle required">
                        Phone Number
                      </div>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="phoneNumber"
                        rules={[
                          {
                            required: true,
                            message: USER_MANAGEMENT.USER_MANAGEMENT_FORM_ERROR_NULL_PHONENUMBER,
                            validateTrigger: "onSubmit"
                          },
                          {
                            pattern: new RegExp(/^(\+62|62|0)8[1-9][0-9]{8,11}$/),
                            message: USER_MANAGEMENT.USER_MANAGEMENT_FORM_ERROR_VALID_PHONENUMBER,
                          },
                        ]}
                      >
                        <Input value={this.state.form.phoneNumber}
                          maxLength={16}
                          name="phoneNumber"
                          onChange={(event) => this.handleChange(event)} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle">
                        Place of Birth
                      </div>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="placeOfBirth"
                      >
                        <Input value={this.state.form.placeOfBirth}
                          name="placeOfBirth"
                          onChange={(event) => this.handleChange(event)} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle">
                        Date of Birth
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Item
                        name="dateOfBirth"
                      >
                        <DatePicker
                          onChange={(event) => this.handleChangeDate(event, "dateOfBirth")} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle">
                        Gender
                      </div>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="gender"
                      >
                        <Radio.Group name="gender" onChange={(event) => this.handleChange(event)}>
                          <Radio value={"1"}>Laki-laki</Radio>
                          <Radio value={"2"}>Perempuan</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle">
                        Address
                      </div>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="address1"
                      >
                        <Input value={this.state.form.address1}
                          name="address1"
                          onChange={(event) => this.handleChange(event)} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="address2"
                      >
                        <Input value={this.state.form.address2}
                          name="address2"
                          onChange={(event) => this.handleChange(event)} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="address3"
                      >
                        <Input value={this.state.form.address3}
                          name="address3"
                          onChange={(event) => this.handleChange(event)} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="address4"
                      >
                        <Input value={this.state.form.address4}
                          name="address4"
                          onChange={(event) => this.handleChange(event)} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle">
                        RT/RW
                      </div>
                    </Col>
                    <Col xs={12} md={7}>
                      <Row>
                        <Col xs={4} md={3}>
                          <Form.Item
                            name="rt"
                            rules={[
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value && getFieldValue("rw")) {
                                    return Promise.reject(new Error(USER_MANAGEMENT.USER_MANAGEMENT_FORM_ERROR_RT));
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <Input value={this.state.rt}
                              name="rt"
                              maxLength={3}
                              onChange={(event) => this.handleChangeRTRW(event)} />
                          </Form.Item>
                        </Col>
                        <Col className="slashStyle" xs={1} md={1}>
                          /
                        </Col>
                        <Col xs={4} md={3}>
                          <Form.Item
                            name="rw"
                            rules={[
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value && getFieldValue("rt")) {
                                    return Promise.reject(new Error(USER_MANAGEMENT.USER_MANAGEMENT_FORM_ERROR_RW));
                                  }
                                  return Promise.resolve();
                                },
                              }),
                            ]}
                          >
                            <Input value={this.state.rw}
                              name="rw"
                              maxLength={3}
                              onChange={(event) => this.handleChangeRTRW(event)} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle required">
                        Provinsi
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Item
                        name="provinceId"
                        rules={[
                          {
                            required: true,
                            message: USER_MANAGEMENT.USER_MANAGEMENT_FORM_ERROR_PROVINSI,
                          },
                        ]}
                      >
                        <Select name="provinceId"
                          placeholder={USER_MANAGEMENT.USER_MANAGEMENT_FORM_PLACEHOLDER_PROVINSI}
                          onChange={(event) => this.handleChangeProvinsi(event)}
                        >
                          {this.state.provinsiList.map(
                            (provinsiData, index) => (
                              <Option value={provinsiData.pickerCode} key={`${provinsiData.pickerCode}-${index}`}>
                                {provinsiData.pickerDescription}
                              </Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle required">
                        Kabupaten
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Item
                        name="kabupatenId"
                        rules={[
                          {
                            required: true,
                            message: USER_MANAGEMENT.USER_MANAGEMENT_FORM_ERROR_KABUPATEN,
                          },
                        ]}
                      >
                        <Select name="kabupatenId"
                          placeholder={USER_MANAGEMENT.USER_MANAGEMENT_FORM_PLACEHOLDER_KABUPATEN}
                          onChange={(event) => this.handleChangeKabupaten(event)}
                        >
                          {this.state.kabupatenList.map(
                            (kabupatenData, index) => (
                              <Option value={kabupatenData.id} key={`${kabupatenData.id}-${index}`}>
                                {kabupatenData.kabupatenName}
                              </Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle required">
                        Kecamatan
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Item
                        name="kecamatanId"
                        rules={[
                          {
                            required: true,
                            message: USER_MANAGEMENT.USER_MANAGEMENT_FORM_ERROR_KECAMATAN,
                          },
                        ]}
                      >
                        <Select name="kecamatanId"
                          placeholder={USER_MANAGEMENT.USER_MANAGEMENT_FORM_PLACEHOLDER_KECAMATAN}
                          onChange={(event) => this.handleChangeKecamatan(event)}
                        >
                          {this.state.kecamatanList.map(
                            (kecamatanData, index) => (
                              <Option value={kecamatanData.id} key={`${kecamatanData.id}-${index}`}>
                                {kecamatanData.kecamatanName}
                              </Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle required">
                        Kelurahan
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Item
                        name="kelurahanId"
                        rules={[
                          {
                            required: true,
                            message: USER_MANAGEMENT.USER_MANAGEMENT_FORM_ERROR_KELURAHAN,
                          },
                        ]}
                      >
                        <Select name="kelurahanId"
                          placeholder={USER_MANAGEMENT.USER_MANAGEMENT_FORM_PLACEHOLDER_KELURAHAN}
                          onChange={(event) => this.handleChangeKelurahan(event)}
                        >
                          {this.state.kelurahanList.map(
                            (kelurahanData, index) => (
                              <Option value={kelurahanData.id} key={`${kelurahanData.id}-${index}`}>
                                {kelurahanData.kelurahanName}
                              </Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle required">
                        Postal Code
                      </div>
                    </Col>
                    <Col xs={5} md={2}>
                      <Form.Item
                        name="postalCode"
                        rules={[
                          {
                            required: true,
                            message: USER_MANAGEMENT.USER_MANAGEMENT_FORM_ERROR_POSTAL_CODE,
                            validateTrigger: "onSubmit"
                          },
                        ]}
                      >
                        <Input value={this.state.form.postalCode}
                          name="postalCode"
                          maxLength={5}
                          onChange={(event) => this.handleChange(event)} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle required">
                        NIK
                      </div>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="nik"
                        rules={[
                          {
                            required: true,
                            message: USER_MANAGEMENT.USER_MANAGEMENT_FORM_ERROR_NIK,
                            validateTrigger: "onSubmit"
                          },
                        ]}
                      >
                        <Input value={this.state.form.nik}
                          name="nik"
                          maxLength={16}
                          onChange={(event) => this.handleChange(event)} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle required">
                        Upload Foto NIK
                      </div>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="imageNik"
                        rules={[
                          {
                            required: true,
                            message: USER_MANAGEMENT.USER_MANAGEMENT_FORM_ERROR_FOTO_NIK,
                            validateTrigger: "onSubmit"
                          },
                        ]}
                      >
                        <Upload
                          maxCount={1}
                          accept="image/jpeg,image/png"
                          beforeUpload={(event) => this.validationFile(event)}
                          customRequest={(event) => this.handleUploadFile(event)}
                          listType="picture-card"
                          fileList={this.state.fileList}
                          showUploadList={{ showDownloadIcon: true, showPreviewIcon: true }}
                          onDownload={() => this.handleDownload()}
                          onChange={(event) => this.handleChangeFile(event)}
                          onPreview={() => this.handlePreview()}
                        >
                          {this.state.fileList.length >= 1 ? null : this.uploadButtonComponent()}
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Divider className="dividerStyle" />

                  <Form.Item>
                    <Space size="large" className="buttonKeepRight">
                      <Button
                        danger
                        className="buttonCancelMUIStyle"
                        onClick={() => this.handleCancelBack()}
                      >
                        {this.state.locale_string.cancelButton}
                      </Button>

                      <Button className="buttonCreateMUIStyle"
                        type="primary"
                        onClick={() => this.handleSubmit()}
                        danger
                      >
                        {this.state.locale_string.createButton}
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>

                <Modal
                  visible={this.state.previewVisible}
                  title={this.state.locale_string.nikModalTitle}
                  footer={null}
                  onCancel={() => this.handleCancel()}
                >
                  <img alt="Preview Foto NIK" style={{ width: '100%' }} src={this.state.form.imageNik} />
                </Modal>

              </div>
            </div>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.userData.token,
    userID: state.userData.userID,
    status: state.userData.status,
  };
};

export default compose(connect(mapStateToProps), withRouter)
  ((UserManagementForm));