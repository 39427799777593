import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = { voucherList: [] };

const voucherReducer = createSlice({
    name:"Voucher",
    initialState: INITIAL_STATE,
    reducers: {
        setVoucherList(state, action) {
            state.voucherList = action.payload.voucherList;
        }
    }
});

export const voucherReducerAction = voucherReducer.actions;

export default voucherReducer.reducer;