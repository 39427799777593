import React from "react";
import styles from "./loginV2.module.css";
import Address from "./../../support/homepage/Address.png";

class Footer extends React.Component {
  render() {
    return (
      <>
        <div className={styles.footer}>
          <div className={styles.footerTop}>
            <div className={styles.footerLeft}>
              <img
                alt={"Address"}
                src={Address}
                className={styles.iconAddress}
              />
            </div>
            <div className={styles.footerRight}>
              {/*<img alt={"GooglePlay"} src={GooglePlay} className={styles.iconGooglePlay}/>*/}
            </div>
          </div>
          <div className={styles.footerBottom}>
            <hr className={styles.line} />
            Gromart.club | Conditions of Use | © 2015 - 2020 Gromart.club
          </div>
        </div>
      </>
    );
  }
}

export default Footer;
