import React from "react";
import PropTypes from "prop-types";
import style from "./dashboard.module.css";
import Logo from "./../../support/images/Logo@2x.png";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Collapse from "@material-ui/core/Collapse";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RedeemIcon from "@material-ui/icons/Redeem";
import DescriptionIcon from "@material-ui/icons/Description";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import SettingsIcon from '@material-ui/icons/Settings';
import { KeyboardDatePicker } from "@material-ui/pickers";
import { connect } from "react-redux";
import axios from "axios";
import DashboardService from "./../../services/dashboardService";
import {
  LOCALIZATION_STRING_EN,
  LOCALIZATION_STRING_ID,
} from "./../../localization_string/localization_global";
import { FILE_TYPE } from "../../_constants/file_type";
import ContentDashboard from "../dashboard/contentDashboard";
import Voucher from "../voucher/voucher";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import VoucherService from "../../services/voucherService";
import { userReducerAction } from "../../store/reducers/userReducer";
import { distributorReducerAction } from "../../store/reducers/distributorReducer";
import UserManagement from "../user_management/user_management";
import UserProfileForm from "../user_profile/user_profile.form"
import UserManagementForm from "../user_management/form/user_management.form";
import UserProfileChangePasswordForm from "../user_profile/user_profile.changepassword"
import {
  Switch,
  Route,
  Link,
  withRouter,
  Redirect
} from "react-router-dom";
import { compose } from "redux";
import UserProfileService from "../../services/userProfileService";
import { errorNotificationAlert } from "../notificationAlert/notificationAlert";
import { DASHBOARD } from "../../_constants/dashboard.constants";
import { ROUTES } from "../../_constants/routes.constants";
import DailyTransaction from "../report/report_daily_transaction";
import DailyTransactionDetail from "../report/report_daily_transaction_detail";
import DailyTransactionSeller from "../report/report_daily_transaction_seller";
import DailyTransactionBuyerSeller from "../report/report_daily_transaction_buyer_seller";
import FulfillmentRate from "../report/fulfillment_rate";
import ActiveBuyer from "../report/active_buyer";
import ActiveSeller from "../report/active_seller";
import CustomerRegistration from "../report/customer_registration";

const drawerWidth = 320;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "white",
    color: "black",
    padding: "10px 0",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
    color: "black",
  },
  menuButtonIconClosed: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: "rotate(0deg)",
  },
  menuButtonIconOpen: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    transform: "rotate(180deg)",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    zIndex: 1000
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    display: "block",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(6 + 1),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(8 + 1),
    },
  },
  toolbar: {
    display: "flex",
    margin: "20px 0",
    alignItems: "center",
    marginTop: theme.spacing(),
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: "#f2f2f2",
    minHeight: "100%",
  },
  grow: {
    flexGrow: 1,
  },
});

class Dashboard extends React.Component {
  state = {
    open: true,
    openMulti: false,
    openMultiVoucher: false,
    openMultiSystemConfiguration: false,
    anchorEl: null,
    left: false,
    selectedDateDari: null,
    selectedDateSampai: null,

    distributorId: "",
    formatChoosen: FILE_TYPE.PDF,
    totalTransactionData: {},
    totalVoucherData: {
      distributedVoucher: 0,
      unDistributedVoucher: 0,
      redeemedVoucher: 0,
      totalVoucher: 0,
    },
    failedTransactionCounterData: 0,
    failedTransactionDetailData: {},
    dropSizeSKUData: {},
    dropSizeInvoiceData: {},
    chartData: {
      datasets: [
        {
          backgroundColor: ["#404040"],
          data: [1],
        },
      ],
    },
    failedTransactionLimit: null,
    locale: "id",
    locale_string: {},
    failedTransactionRejected: null,
    sellerList: [],
    voucherList: [],
    sellerId: "",
    sellerName: "",
    promoCode: "",
    voucherReportIsOpen: false,
    userPrincipalId: "",
    userPrincipalName: "",
    userFirstName: "",
    userLastName: "",
    userLevel: "",
  };

  getLocaleString = () => {
    if (this.state.locale === "id") {
      this.setState({ locale_string: LOCALIZATION_STRING_ID });
    } else {
      this.setState({ locale_string: LOCALIZATION_STRING_EN });
    }
  };

  getToday = () => {
    return Date.now();
  };

  getTotalTransaction = (startDate, endDate, token) => {
    DashboardService.getTotalTransaction(startDate, endDate, token)
      .then((response) => {
        this.setState({
          totalTransactionData: response.data.additionalEntity.result,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getFailedTransactionCounter = (startDate, endDate, token) => {
    DashboardService.getFailedTransactionCounter(startDate, endDate, token)
      .then((response) => {
        this.setState({
          failedTransactionCounterData: response.data.additionalEntity.result,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getFailedTransactionDetail = (startDate, endDate, token) => {
    DashboardService.getFailedTransactionDetail(startDate, endDate, token)
      .then((response) => {
        let data = response.data.additionalEntity.result;
        if (
          data.creditLimit +
          data.invoiceLimit +
          data.stock +
          data.promotionBudget +
          data.rejectedBySystem +
          data.rejectedByUser +
          data.allocation ===
          0
        ) {
          this.setState({
            failedTransactionDetailData: response.data.additionalEntity.result,
            failedTransactionLimit: data.creditLimit + data.invoiceLimit,
            chartData: {
              datasets: [
                {
                  backgroundColor: ["#404040"],
                  data: [1],
                },
              ],
            },
          });
        } else {
          this.setState({
            failedTransactionDetailData: response.data.additionalEntity.result,
            failedTransactionLimit: data.creditLimit + data.invoiceLimit,
            failedTransactionRejected:
              data.rejectedBySystem + data.rejectedByUser,
            chartData: {
              datasets: [
                {
                  backgroundColor: ["#FFA836", "#FFF142", "#9BE9F2", "#DC143C"],
                  data: [
                    data.stock,
                    data.creditLimit + data.invoiceLimit,
                    data.allocation,
                    data.rejectedBySystem + data.rejectedByUser,
                  ],
                },
              ],
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getDropSizeSKU = (startDate, endDate, token) => {
    DashboardService.getDropSizeSKU(startDate, endDate, token)
      .then((response) => {
        this.setState({
          dropSizeSKUData: response.data.additionalEntity.result,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getDropSizeInvoice = (startDate, endDate, token) => {
    DashboardService.getDropSizeInvoice(startDate, endDate, token)
      .then((response) => {
        this.setState({
          dropSizeInvoiceData: response.data.additionalEntity.result,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  interceptError = () => {
    let self = this;
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (!error.response) {
          return Promise.reject(error);
        }

        if (error.response) {
          if (error.response.status === 401) {
            self.props.dispatch(userReducerAction.onLogout());
            self.props.history.push("/");
          } else if (error.response.status === 500) {
            console.log("There is a problem with our server");
          }
          self.setState({ message: "There's problem with our server" });
        } else if (error.response.status === 400) {
          console.log(error.response.data);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        return Promise.reject(error);
      }
    );
  };

  componentDidMount() {
    this.interceptError();
    let today = moment(this.getToday()).format("YYYY-MM-DD");
    this.setState({ selectedDateDari: today, selectedDateSampai: today });
    let token = this.props.token;
    this.getLocaleString();
    this.getUserDetail(token);
    this.getDashboardData(today, today, token);
    this.getDistributorList(token);
    this.getDistributorVoucherList(token);
  }

  getUserDetail(token) {
    let authClientId = this.props.authClientId;
    UserProfileService.getUserDetail(token, authClientId).then((response) => {
      let userDetailResponse = response.data.additionalEntity.result;
      this.setState({
        userPrincipalId: userDetailResponse.principal_id,
        userPrincipalName: userDetailResponse.principal_name,
        userFirstName: userDetailResponse.first_name,
        userLastName: userDetailResponse.last_name,
        userLevel: userDetailResponse.level,
      });
      this.props.dispatch(userReducerAction.setUserProfile(userDetailResponse));
    }).catch((error) => {
      console.log(error);
      
      if (error.response.status === 401) {
        errorNotificationAlert(
          DASHBOARD.ERROR_TITLE,
          DASHBOARD.ERROR_RESPONSE_401
        )
        return;
      }

      errorNotificationAlert(
        DASHBOARD.ERROR_TITLE,
        DASHBOARD.ERROR_RESPONSE
      );
    });
  }

  getDistributorList = (token) => {
    DashboardService.getDistributorList(token)
      .then((response) => {
        let data = response.data.additionalEntity.result;
        this.props.dispatch(distributorReducerAction.setDistributorList(data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getDashboardData = (start, end, token) => {
    this.getTotalTransaction(start, end, token);
    this.getTotalVoucher(start, end, token);
    this.getFailedTransactionCounter(start, end, token);
    this.getFailedTransactionDetail(start, end, token);
    this.getDropSizeSKU(start, end, token);
    this.getDropSizeInvoice(start, end, token);
  };

  handleLogout = () => {
    this.props.dispatch(userReducerAction.onLogout());
  };

  opensideBar = () => {
    this.setState({ left: true });
  };

  closesideBar = () => {
    this.setState({ left: false });
  };

  changeDistributorId = (event) => {
    this.setState({ distributorId: event.target.value });
  };

  changeSellerId = (event) => {
    let sellerEventIndex = event.target.selectedIndex;
    let sellerEventName = event.target[sellerEventIndex].text;
    this.setState({ sellerId: event.target.value, sellerName: sellerEventName });
    this.getVoucherList(this.props.token, event.target.value);
  };

  changePromoCode = (event) => {
    this.setState({ promoCode: event.target.value });
  };

  getDistributorVoucherList = (token) => {
    VoucherService.pickDistributor(token)
      .then((response) => {
        this.setState({
          sellerList: response.data.additionalEntity.result,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getVoucherList = (token, sellerId) => {
    VoucherService.pickVoucher(token, sellerId)
      .then((response) => {
        this.setState({
          voucherList: response.data.additionalEntity.result,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  changeFormatChoosen = (event) => {
    this.setState({ formatChoosen: event.target.value });
  };

  handleDateChangeDari = (date) => {
    let today = moment(this.getToday()).format("YYYY-MM-DD");
    let token = this.props.token;
    if (moment(date).isValid()) {
      let from = moment(date).format("YYYY-MM-DD");
      this.setState({ selectedDateDari: from });
      let until = this.state.selectedDateSampai
        ? this.state.selectedDateSampai
        : today;
      this.getDashboardData(from, until, token);
    } else {
    }
  };
  
  handleDateChangeSampai = (date) => {
    let today = moment(this.getToday()).format("YYYY-MM-DD");
    let token = this.props.token;
    if (moment(date).isValid()) {
      let until = moment(date).format("YYYY-MM-DD");
      this.setState({ selectedDateSampai: until });
      let from = this.state.selectedDateDari
        ? this.state.selectedDateDari
        : today;
      this.getDashboardData(from, until, token);
    } else {
      console.log("Invalid Date");
    }
  };

  handleDrawerOpen = () => {
    if (this.state.openMulti || this.state.openMultiVoucher || this.state.openMultiSystemConfiguration) {
      this.setState({
        open: !this.state.open,
        openMultiVoucher: false,
        openMulti: false,
        openMultiSystemConfiguration: false
      });
    } else {
      this.setState({ open: !this.state.open });
    }
  };

  handleDrawerClose = () => {
    this.setState({ openMulti: false });
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  // angel

  getTotalVoucher = (startDate, endDate, token) => {
    DashboardService.getTotalVoucher(startDate, endDate, token)
      .then((response) => {
        this.setState({
          totalVoucherData: response.data.additionalEntity.result,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // angel

  openVoucherReportFilter = () => {
    this.resetWhenOpenVoucher();
    this.setState({ voucherReportIsOpen: true });
  };

  closeVoucherReportFilter = () => {
    this.setState({ voucherReportIsOpen: false });
  };

  downloadVoucherReport = () => {
    if (this.state.formatChoosen === FILE_TYPE.PDF) {
      this.downloadVoucherReportPdf();
    } else if (this.state.formatChoosen === FILE_TYPE.CSV) {
      this.downloadVoucherReportCsv();
    }
    this.closeVoucherReportFilter();
  };

  downloadVoucherReportPdf = () => {
    VoucherService.downloadVoucherReportPdfReport(
      this.props.token,
      this.state.selectedDateDari,
      this.state.selectedDateSampai,
      this.state.sellerId,
      this.state.promoCode
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        let todayPrintVoucher = moment(this.getToday()).format("DDMMYYYY").toString();
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${todayPrintVoucher}_Voucher_${this.state.sellerName}.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(error);
        alert(this.state.locale_string.dashboardReportNotAvailable);
      });
  };

  downloadVoucherReportCsv = () => {
    VoucherService.downloadVoucherReportCsvReport(
      this.props.token,
      this.state.selectedDateDari,
      this.state.selectedDateSampai,
      this.state.sellerId,
      this.state.promoCode
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        let todayPrintVoucher = moment(this.getToday()).format("DDMMYYYY").toString();
        link.href = url;
        link.setAttribute("download", `${todayPrintVoucher}_Voucher_${this.state.sellerName}.csv`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(error);
        alert(this.state.locale_string.dashboardReportNotAvailable);
      });
  };
  // angel

  handleClickMulti = () => {
    this.setState({ openMulti: !this.state.openMulti, open: true });
  };

  handleClickMultiVoucher = () => {
    this.setState({
      openMultiVoucher: !this.state.openMultiVoucher,
      open: true,
    });
  };

  handleClickSystemConfiguration = () => {
    this.setState(
      {
        openMultiSystemConfiguration: !this.state.openMultiSystemConfiguration,
        open: true,
      });
  };

  resetWhenOpenVoucher = () => {
    this.setState({
      sellerId: "",
      sellerName: this.state.locale_string.allDistributor,
      promoCode: ""
    });
  }

  getNameUserLevel = (userLevel) => {
    let userLevelLabel = "";
    if (userLevel === "0") {
      userLevelLabel = "Admin"
    }

    if (userLevel === "1") {
      userLevelLabel = "Staff"
    }

    return userLevelLabel;
  }

  swicthRoute = () => {
    // Get Path From This Route use withRouter
    let path = this.props.match.path;

    return (
      <Switch>
        <Route exact path={path}>
          <ContentDashboard
            totalVoucherData={this.state.totalVoucherData}
            totalTransactionData={this.state.totalTransactionData}
            chartData={this.state.chartData}
            failedTransactionCounterData={this.state.failedTransactionCounterData}
            failedTransactionLimit={this.state.failedTransactionLimit}
            failedTransactionDetailData={this.state.failedTransactionDetailData}
            failedTransactionRejected={this.state.failedTransactionRejected}
            dropSizeSKUData={this.state.dropSizeSKUData}
            dropSizeInvoiceData={this.state.dropSizeInvoiceData}
          />
        </Route>
        <Route path={`${path}/${ROUTES.VOUCHER}`}>
          <Voucher openVoucherReportFilter={this.openVoucherReportFilter} />
        </Route>
        <Route path={`${path}/${ROUTES.DAILY_TRANSACTION}`}>
          <DailyTransaction />
        </Route>
        <Route path={`${path}/${ROUTES.DAILY_TRANSACTION_DETAIL}`}>
          <DailyTransactionDetail />
        </Route>
        <Route path={`${path}/${ROUTES.DAILY_TRANSACTION_SELLER}`}>
          <DailyTransactionSeller />
        </Route>
        <Route path={`${path}/${ROUTES.DAILY_TRANSACTION_BUYER_SELLER}`}>
          <DailyTransactionBuyerSeller />
        </Route>
        <Route path={`${path}/${ROUTES.FULFILLMENT_RATE}`}>
          <FulfillmentRate />
        </Route>
        <Route path={`${path}/${ROUTES.ACTIVE_BUYER}`}>
          <ActiveBuyer />
        </Route>
        <Route path={`${path}/${ROUTES.ACTIVE_SELLER}`}>
          <ActiveSeller />
        </Route>
        <Route path={`${path}/${ROUTES.CUSTOMER_REGISTRATION}`}>
          <CustomerRegistration />
        </Route>
        <Route exact path={`${path}/${ROUTES.USERPROFILE}`}>
          <UserProfileForm />
        </Route>
        <Route path={`${path}/${ROUTES.USERPROFILE_CHANGEPASSWORD}`}>
          <UserProfileChangePasswordForm />
        </Route>
        {this.props.level === "0" &&
          <>
            <Route exact path={`${path}/${ROUTES.USERMANAGEMENT}`}>
              <UserManagement />
            </Route>
            <Route path={`${path}/${ROUTES.USERMANAGEMENT_ID}`}>
              <UserManagementForm userPrincipalId={this.state.userPrincipalId} userPrincipalName={this.state.userPrincipalName} />
            </Route>
          </>
        }
      </Switch>
    )
  }

  render() {
    if (this.props.token === "") {
      return <Redirect to="/" />;
    }

    const { classes } = this.props;

    // Get url From This Route use withRouter
    const url = this.props.match.url;
    const isExactDashboard = this.props.match.isExact;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={classes.appBar}
          foojon={classNames(classes.appBar, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          <Toolbar disableGutters={true}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classes.menuButton}
            >
              <MenuIcon
                classes={{
                  root: this.state.open
                    ? classes.menuButtonIconOpen
                    : classes.menuButtonIconClosed,
                }}
              />
            </IconButton>
            <div>
              <img alt="" src={Logo} className={style.headerLogo} />
            </div>
            <div
              style={{
                display: isExactDashboard ? "" : "none",
              }}
              className={style.periodContainerHeader}
            >
              <div className={style.dashboardTopPeriodCaptionHeader}>
                {this.state.locale_string.dashboardPeriode}
              </div>
              <div className={style.dashboardTopPickerContainerHeader}>
                <div className={style.dashboardPickerAHeader}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/DD/YYYY"
                    id="date-picker-dari"
                    label={this.state.locale_string.dashboardDateFrom}
                    value={this.state.selectedDateDari}
                    onChange={this.handleDateChangeDari}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </div>
                <div className={style.dashboardPickerBHeader}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/DD/YYYY"
                    id="date-picker-sampai"
                    label={this.state.locale_string.dashboardDateUntil}
                    value={this.state.selectedDateSampai}
                    onChange={this.handleDateChangeSampai}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    minDate={this.state.selectedDateDari}
                  />
                </div>
              </div>
            </div>
            <div className={style.profileContainerHeader}>
              <div className={style.profileDetailContinerHeader}>
                <div className={style.profileCompanyHeader}>
                  {this.props.principalName}
                </div>
                <div className={style.profileDetailHeader}>
                  {this.props.firstName} {this.props.lastName} - {this.getNameUserLevel(this.props.level)}
                </div>
              </div>
              <div
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginLeft: "auto",
                  marginRight: "20px",
                  color: "#dd4c5c",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={this.handleLogout}
              >
                {this.state.locale_string.dashboardLogout}
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: this.state.open,
            [classes.drawerClose]: !this.state.open,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open,
            }),
          }}
          open={this.state.open}
        >
          <div className={classes.toolbar} />
          <Link to={`${url}`} style={{ textDecoration: "none" }}>
            <ListItem button>
              <ListItemIcon>
                <HomeOutlinedIcon />
              </ListItemIcon>
              <Typography
                variant="inherit"
                noWrap
                style={{ color: "#dd4c5c", fontWeight: "bold" }}
              >
                <b>{this.state.locale_string.dashboardHome}</b>
              </Typography>
            </ListItem>
          </Link>
          <React.Fragment>
            <ListItem button onClick={this.handleClickMulti}>
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <Typography
                variant="inherit"
                noWrap
                style={{ color: "#dd4c5c", fontWeight: "bold" }}
              >
                <b>{this.state.locale_string.dashboardSideReport}</b>
              </Typography>
              <div style={{ marginLeft: "auto" }}>
                {this.state.openMulti ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </div>
            </ListItem>
            <Collapse in={this.state.openMulti} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* Daily Transaction */}
                <Link to={`${url}/${ROUTES.DAILY_TRANSACTION}`} style={{ color: "inherit", textDecoration: "none" }}>
                  <ListItem button>
                    <Typography variant="inherit" noWrap>
                      <b>{this.state.locale_string.dashboardDailyTransaction}</b>
                    </Typography>
                  </ListItem>
                </Link>

                {/* Daily Transaction Detail */}
                <Link to={`${url}/${ROUTES.DAILY_TRANSACTION_DETAIL}`} style={{ color: "inherit", textDecoration: "none" }}>
                  <ListItem button>
                    <Typography variant="inherit" noWrap>
                      <b>{this.state.locale_string.dashboardDailyTransactionDetail}</b>
                    </Typography>
                  </ListItem>
                </Link>

                {/* Daily Transaction By Seller */}
                <Link to={`${url}/${ROUTES.DAILY_TRANSACTION_SELLER}`} style={{ color: "inherit", textDecoration: "none" }}>
                  <ListItem button>
                    <Typography variant="inherit" noWrap>
                      <b>{this.state.locale_string.dashboardDailyTransactionBySeller}</b>
                    </Typography>
                  </ListItem>
                </Link>

                {/* Daily Transaction By Seller */}
                <Link to={`${url}/${ROUTES.DAILY_TRANSACTION_BUYER_SELLER}`} style={{ color: "inherit", textDecoration: "none" }}>
                  <ListItem button>
                    <Typography variant="inherit" noWrap>
                      <b>{this.state.locale_string.dashboardDailyTransactionByBuyerSeller}</b>
                    </Typography>
                  </ListItem>
                </Link>

                {/* Fulfillment Rate */}
                <Link to={`${url}/${ROUTES.FULFILLMENT_RATE}`} style={{ color: "inherit", textDecoration: "none" }}>
                  <ListItem button>
                    <Typography variant="inherit" noWrap>
                      <b>{this.state.locale_string.dashboardFulfillmentRate}</b>
                    </Typography>
                  </ListItem>
                </Link>

                {/* Active Buyer */}
                <Link to={`${url}/${ROUTES.ACTIVE_BUYER}`} style={{ color: "inherit", textDecoration: "none" }}>
                  <ListItem button>
                    <Typography variant="inherit" noWrap>
                      <b>{this.state.locale_string.dashboardSideActiveBuyer}</b>
                    </Typography>
                  </ListItem>
                </Link>

                {/* Active Seller */}
                <Link to={`${url}/${ROUTES.ACTIVE_SELLER}`} style={{ color: "inherit", textDecoration: "none" }}>
                  <ListItem button>
                    <Typography variant="inherit" noWrap>
                      <b>{this.state.locale_string.dashboardSideActiveSeller}</b>
                    </Typography>
                  </ListItem>
                </Link>

                {/* Customer Registration */}
                <Link to={`${url}/${ROUTES.CUSTOMER_REGISTRATION}`} style={{ color: "inherit", textDecoration: "none" }}>
                  <ListItem button>
                    <Typography variant="inherit" noWrap>
                      <b>{this.state.locale_string.dashboardCustomerRegistration}</b>
                    </Typography>
                  </ListItem>
                </Link>
              </List>
            </Collapse>
          </React.Fragment>
          <React.Fragment>
            <ListItem button onClick={this.handleClickMultiVoucher}>
              <ListItemIcon>
                <RedeemIcon />
              </ListItemIcon>
              <Typography
                variant="inherit"
                noWrap
                style={{ color: "#dd4c5c", fontWeight: "bold" }}
              >
                <b>{this.state.locale_string.dashboardVoucher}</b>
              </Typography>
              <div style={{ marginLeft: "auto" }}>
                {this.state.openMultiVoucher ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </div>
            </ListItem>
            <Collapse
              in={this.state.openMultiVoucher}
              timeout="auto"
              unmountOnExit
            >
              <Link to={`${url}/${ROUTES.VOUCHER}`} style={{ color: "inherit", textDecoration: "none" }}>
                <List component="div" disablePadding>
                  <ListItem button>
                    <Typography variant="inherit" noWrap>
                      <b>{this.state.locale_string.dashboardVoucherList}</b>
                    </Typography>
                  </ListItem>
                </List>
              </Link>
            </Collapse>
          </React.Fragment>

          <React.Fragment>
            <ListItem button onClick={this.handleClickSystemConfiguration}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <Typography
                variant="inherit"
                noWrap
                style={{ color: "#dd4c5c", fontWeight: "bold" }}
              >
                <b>{this.state.locale_string.systemConfiguration}</b>
              </Typography>
              <div style={{ marginLeft: "auto" }}>
                {this.state.openMultiSystemConfiguration ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </div>
            </ListItem>
            <Collapse
              in={this.state.openMultiSystemConfiguration}
              timeout="auto"
              unmountOnExit
            >

              <List component="div" disablePadding>
                {this.props.level === "0" &&
                  <Link to={`${url}/${ROUTES.USERMANAGEMENT}`} style={{ color: "inherit", textDecoration: "none" }}>
                    <ListItem button>
                      <Typography variant="inherit" noWrap>
                        <b>{this.state.locale_string.userManagement}</b>
                      </Typography>
                    </ListItem>
                  </Link>
                }

                <Link to={`${url}/${ROUTES.USERPROFILE}`} style={{ color: "inherit", textDecoration: "none" }}>
                  <ListItem button>
                    <Typography variant="inherit" noWrap>
                      <b>{this.state.locale_string.userProfile}</b>
                    </Typography>
                  </ListItem>
                </Link>
              </List>
            </Collapse>
          </React.Fragment>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div 
            style={{
              display: isExactDashboard ? "" : "none",
            }}
            className={style.periodContainerMobile}
          >
            <div className={style.dashboardContainerMobile}>
              <div className={style.periodMobileCaption}>
                {this.state.locale_string.dashboardPeriode}
              </div>
              <div className={style.flexPickerMobile}>
                <div>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/DD/YYYY"
                    id="date-picker-dari"
                    label={this.state.locale_string.dashboardDateFrom}
                    value={this.state.selectedDateDari}
                    onChange={this.handleDateChangeDari}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </div>
                <div>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/DD/YYYY"
                    id="date-picker-sampai"
                    label={this.state.locale_string.dashboardDateUntil}
                    value={this.state.selectedDateSampai}
                    onChange={this.handleDateChangeSampai}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    minDate={this.state.selectedDateDari}
                  />
                </div>
              </div>
            </div>
          </div>
          {this.swicthRoute()}
        </main>

        {/* Voucher Report dialog*/}
        <Dialog
          open={this.state.voucherReportIsOpen}
          onClose={this.closeVoucherReportFilter}
        >
          <DialogContent>
            <div className={style.dialogSectionName}>
              {this.state.locale_string.dashboardPeriode}
            </div>
            <div className={style.dialogDatePicker}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/DD/YYYY"
                id="date-picker-dari"
                value={this.state.selectedDateDari}
                onChange={this.handleDateChangeDari}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <div style={{ width: "70px", textAlign: "center" }}> -</div>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/DD/YYYY"
                id="date-picker-sampai"
                value={this.state.selectedDateSampai}
                onChange={this.handleDateChangeSampai}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                minDate={this.state.selectedDateDari}
              />
            </div>
            <div className={style.dialogSectionName}>
              {this.state.locale_string.distributor}
            </div>
            <Select
              native
              onChange={this.changeSellerId}
              placeholder="Select Distributor"
              className={style.dialogDatePicker}
            >
              <option value="">
                {this.state.locale_string.allDistributor}
              </option>
              {this.state.sellerList.map((seller, index) => (
                <option value={seller.id} key={index}>
                  {seller.name}
                </option>
              ))}
            </Select>
            {/* angel */}
            <div className={style.dialogSectionName}>
              {this.state.locale_string.voucherName}
            </div>
            <Select
              native
              value={this.state.promoCode}
              onChange={this.changePromoCode}
              className={style.dialogDatePicker}
              disabled={this.state.sellerId ? false : true}
            >
              <option value="">{this.state.locale_string.allVoucher}</option>
              {this.state.voucherList.map((voucher, index) => (
                <option value={voucher.promoCode} key={index}>
                  {voucher.name}
                </option>
              ))}
            </Select>
            {/* angel */}
            <div className={style.dialogSectionName}>
              {this.state.locale_string.formatChoosen}
            </div>
            <div className={style.dialogBottomSection}>
              <Select
                native
                value={this.state.formatChoosen}
                onChange={this.changeFormatChoosen}
              >
                <option value={FILE_TYPE.PDF}>
                  {this.state.locale_string.pdf}
                </option>
                <option value={FILE_TYPE.CSV}>
                  {this.state.locale_string.csv}
                </option>
              </Select>
              <Button
                onClick={this.downloadVoucherReport}
                variant="contained"
                color="secondary"
              >
                {this.state.locale_string.download}
              </Button>
            </div>
          </DialogContent>
          <DialogActions />
        </Dialog>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    token: state.userData.token,
    userID: state.userData.userID,
    status: state.userData.status,
    distributorList: state.distributor.distributorList,
    voucherList: state.voucher.voucherList,
    authClientId: state.userData.authClientId,
    principalName: state.userData.principalName,
    firstName: state.userData.firstName,
    lastName: state.userData.lastName,
    level: state.userData.level,
  };
};

export default compose(connect(mapStateToProps), withRouter)
  (withStyles(styles, { withTheme: true })(Dashboard)
  );
