import { apiServer } from "./../config/AppConfig";
import axios from "axios";

function setHeaderToken(token) {
  return {
    "X-AUTH-TOKEN-GC": token,
  };
}

const VoucherService = {
  pickDistributor(token) {
    return axios({
      method: "get",
      url: apiServer + "/voucher/distributor/picker",
      headers: setHeaderToken(token),
      crossOrigin: true,
    });
  },

  pickVoucher(token, sellerId, isActive) {
    if (isActive) {
      return axios({
        method: "get",
        url: apiServer + "/voucher/promotion/picker",
        params: {
          sellerId: sellerId,
          isActive: "Y",
        },
        headers: setHeaderToken(token),
        crossOrigin: true,
      });
    } else {
      return axios({
        method: "get",
        url: apiServer + "/voucher/promotion/picker",
        params: {
          sellerId: sellerId,
        },
        headers: setHeaderToken(token),
        crossOrigin: true,
      });
    }
  },

  getVoucherDetail(token, promoCode) {
    return axios({
      method: "get",
      url: apiServer + "/voucher/promotion/detail",
      params: {
        promoCode: promoCode,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
    });
  },

  getVoucherCode(token, promoCode, pageSize, pageIndex, orderBy) {
    if (orderBy === "") orderBy = null;
    return axios({
      method: "get",
      url: apiServer + "/voucher/codes/list",
      params: {
        promoCode: promoCode,
        pageIndex: pageIndex,
        pageSize: pageSize,
        orderBy: orderBy,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
    });
  },

  sendVoucher(token, promoCode, sellerId) {
    return axios({
      method: "post",
      url: apiServer + "/voucher/send",
      params: {
        promoCode: promoCode,
        sellerId: sellerId,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
    });
  },

  patchAutoDistribute(token, promoCode, sellerId, autoDistribute) {
    return axios({
      method: "patch",
      url: apiServer + "/voucher/update/autoDistribute",
      params: {
        promoCode: promoCode,
        sellerId: sellerId,
        autoDistribute: autoDistribute,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
    });
  },

  downloadVoucherReportPdfReport(
    token,
    startDate,
    endDate,
    sellerId,
    promoCode
  ) {
    if (sellerId === "") sellerId = null;
    if (promoCode === "") promoCode = null;
    return axios({
      method: "get",
      url: apiServer + "/voucher/report/pdf",
      params: {
        startDate: startDate,
        endDate: endDate,
        sellerId: sellerId,
        promoCode: promoCode,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadVoucherReportCsvReport(
    token,
    startDate,
    endDate,
    sellerId,
    promoCode
  ) {
    if (sellerId === "") sellerId = null;
    if (promoCode === "") promoCode = null;
    return axios({
      method: "get",
      url: apiServer + "/voucher/report/csv",
      params: {
        startDate: startDate,
        endDate: endDate,
        sellerId: sellerId,
        promoCode: promoCode,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },
};

export default VoucherService;
