import React from "react";
import {
  LOCALIZATION_STRING_EN,
  LOCALIZATION_STRING_ID,
} from "../../localization_string/localization_global";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import {
  Form,
  Space,
  Divider,
  Select,
}
  from "antd";
import Button from "@material-ui/core/Button";
import CircularProgress from '@material-ui/core/CircularProgress';
import { compose } from "redux";
import style from "./report.module.css"
import { FILE_TYPE } from "../../_constants/file_type";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import ReportService from "../../services/reportService";
import { GetApp, Block } from '@material-ui/icons';

class DailyTransaction extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      locale: "id",
      locale_string: {},
      headerLabel: "",
      selectedDateDari: null,
      selectedDateSampai: null,
      principalId: "",
      distributorId: "",
      formatChoosen: FILE_TYPE.PDF,
      disableButtonDownload: false,
      distributorList: [],
      principalList: [],
      loadingPrincipalList: false,
      loadingDistributorList: false,
    };
  }

  async componentDidMount() {
    let today = moment(this.getToday()).format("YYYY-MM-DD");
    await this.getLocaleString();
    if(this.props.isMitrago) {
      await this.getAllPrincipal(this.props.token);
      await this.getDistributorListByPrincipal(this.props.token, "allPrincipal");
    } else {
      await this.getAllDistributorList(this.props.token);
    }
    this.setState({ 
      headerLabel: this.state.locale_string.dashboardDailyTransaction,
      selectedDateDari: today,
      selectedDateSampai: today,
    });
  }

  getToday = () => {
    return Date.now();
  };

  getLocaleString = async () => {
    if (this.state.locale === "id") {
      this.setState({ locale_string: LOCALIZATION_STRING_ID });
    } else {
      this.setState({ locale_string: LOCALIZATION_STRING_EN });
    }
  };

  getAllDistributorList = async (token) => {
    this.setState({ loadingDistributorList: true });
    ReportService.getAllDistributorList(token)
      .then((response) => {
        let data = response.data.additionalEntity.result;
        this.setState({
          distributorList: data,
          loadingDistributorList: false,
        })
      })
      .catch((error) => {
        this.setState({ loadingDistributorList: false });
        console.log(error);
      });
  };

  getAllPrincipal = async (token) => {
    this.setState({ loadingPrincipalList: true });
    ReportService.getAllPrincipal(token)
      .then((response) => {
        let data = response.data.additionalEntity.result;
        this.setState({
          principalList: data,
          loadingPrincipalList: false,
        })
      })
      .catch((error) => {
        this.setState({ loadingPrincipalList: false });
        console.log(error);
      });
  }

  getDistributorListByPrincipal = async (token, principalId) => {
    this.setState({ loadingDistributorList: true });
    ReportService.getDistributorListByPrincipal(token, principalId)
      .then((response) => {
        let data = response.data.additionalEntity.result;
        this.setState({
          distributorList: data,
          loadingDistributorList: false,
        })
      })
      .catch((error) => {
        this.setState({ loadingDistributorList: false });
        console.log(error);
      });
  }

  handleDateChangeDari = (date) => {
    if (moment(date).isValid()) {
      let from = moment(date).format("YYYY-MM-DD");
      let until = moment(this.state.selectedDateSampai).format("YYYY-MM-DD");
      if (moment(until).isBefore(from)) {
        until = from;
      }
      this.setState({ 
        selectedDateDari: from,
        selectedDateSampai: until,
      });
    }
  };
  
  handleDateChangeSampai = (date) => {
    if (moment(date).isValid()) {
      let until = moment(date).format("YYYY-MM-DD");
      this.setState({ selectedDateSampai: until });
    }
  };

  changeDistributorId = (event) => {
    this.setState({ distributorId: event });
  };

  changePrincipalId = (event) => {
    this.setState({ 
      distributorList: [],
      principalId: event,
    }, () => {
      let principalId = event ? event : "allPrincipal";
      this.getDistributorListByPrincipal(this.props.token, principalId);
      this.setState({ distributorId: "" });
    });
  };

  changeFormatChoosen = (event) => {
    this.setState({ formatChoosen: event });
  };

  downloadDailyTransactionReport = () => {
    if (this.state.formatChoosen === FILE_TYPE.PDF) {
      this.downloadDailyTransactionPdf();
    } else if (this.state.formatChoosen === FILE_TYPE.CSV) {
      this.downloadDailyTransactionCsv();
    } else if (this.state.formatChoosen === FILE_TYPE.EXCEL) {
      this.downloadDailyTransactionExcel();
    }
  };

  downloadDailyTransactionPdf = () => {
    this.setState({ disableButtonDownload: true });
    ReportService.downloadDailyTransactionPdfReport(
      this.props.token,
      this.state.selectedDateDari,
      this.state.selectedDateSampai,
      this.state.distributorId,
      this.state.principalId,
    )
      .then((response) => {
        this.setState({ disableButtonDownload: false });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Daily Transaction.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        console.log(error);
        alert(this.state.locale_string.downloadFailed);
        this.setState({ disableButtonDownload: false });
      });
  };

  downloadDailyTransactionCsv = () => {
    this.setState({ disableButtonDownload: true });
    ReportService.downloadDailyTransactionCsvReport(
      this.props.token,
      this.state.selectedDateDari,
      this.state.selectedDateSampai,
      this.state.distributorId,
      this.state.principalId,
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Daily Transaction.csv");
        document.body.appendChild(link);
        link.click();
        this.setState({ disableButtonDownload: false });
      })
      .catch((error) => {
        console.log(error);
        alert(this.state.locale_string.downloadFailed);
        this.setState({ disableButtonDownload: false });
      });
  };

  downloadDailyTransactionExcel = () => {
    this.setState({ disableButtonDownload: true });
    ReportService.downloadDailyTransactionExcelReport(
      this.props.token,
      this.state.selectedDateDari,
      this.state.selectedDateSampai,
      this.state.distributorId,
      this.state.principalId,
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Daily Transaction.xlsx");
        document.body.appendChild(link);
        link.click();
        this.setState({ disableButtonDownload: false });
      })
      .catch((error) => {
        console.log(error);
        alert(this.state.locale_string.downloadFailed);
        this.setState({ disableButtonDownload: false });
      });
  };

  render() {
    const { form } = this.state;
    const { Option } = Select;

    return (
      <>
        <Row className="justify-content-md-center">
          <Col xs={12} md={12} style={{ marginTop: "10px" }}>
            <Row>
              <Col xs={12} md={5}>
                <div className="pageTitle">
                  {this.state.headerLabel}
                </div>
              </Col>
            </Row>
            <div className="white-box white-box-usermanagement">
              <div>
                <Form
                  initialValues={{ form }}
                  ref={this.formRef}
                  onFinish={() => this.submitSuccessForm()}
                >
                  {/* Periode */}
                  <div className={style.dialogSectionName}>
                    {this.state.locale_string.dashboardPeriode}
                  </div>
                  <div className={style.dialogDatePicker}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/DD/YYYY"
                      id="date-picker-dari"
                      value={this.state.selectedDateDari}
                      onChange={this.handleDateChangeDari}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                    <div style={{ width: "70px", textAlign: "center" }}> -</div>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/DD/YYYY"
                      id="date-picker-sampai"
                      value={this.state.selectedDateSampai}
                      onChange={this.handleDateChangeSampai}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      minDate={this.state.selectedDateDari}
                    />
                  </div>

                  {/* Principal */}
                  {this.props.isMitrago && (
                    <React.Fragment>
                      <div className={style.dialogSectionName}>
                        {this.state.locale_string.principal}
                      </div>
                      <Select
                        loading={this.state.loadingPrincipalList}
                        listHeight={224}
                        style={{ width: 560 }}
                        value={this.state.principalId}
                        onChange={this.changePrincipalId}
                        className={style.dialogDatePicker}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option value="">
                          {this.state.locale_string.allPrincipal}
                        </Option>
                        {this.state.principalList.map((principal, index) => (
                          <Option value={principal.id} key={index}>
                            {principal.principalName}
                          </Option>
                        ))}
                      </Select>
                    </React.Fragment>
                  )}

                  {/* Distributor */}
                  <div className={style.dialogSectionName}>
                    {this.state.locale_string.distributor}
                  </div>
                  <Select
                    loading={this.state.loadingDistributorList}
                    listHeight={this.props.isMitrago ? 160 : 224}
                    style={{ width: 560 }}
                    value={this.state.distributorId}
                    onChange={this.changeDistributorId}
                    className={style.dialogDatePicker}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value="">
                      {this.state.locale_string.allDistributor}
                    </Option>
                    {this.state.distributorList.map((distributor, index) => (
                      <Option value={distributor.id} key={index}>
                        {distributor.distributorName}
                      </Option>
                    ))}
                  </Select>

                  {/* Format */}
                  <div className={style.dialogSectionName}>
                    {this.state.locale_string.formatChoosen}
                  </div>
                  <div className={style.dialogBottomSection}>
                    <Select
                      value={this.state.formatChoosen}
                      onChange={this.changeFormatChoosen}
                    >
                      <Option value={FILE_TYPE.PDF}>
                        {this.state.locale_string.pdf}
                      </Option>
                      <Option value={FILE_TYPE.CSV}>
                        {this.state.locale_string.csv}
                      </Option>
                      <Option value={FILE_TYPE.EXCEL}>
                        {this.state.locale_string.excel}
                      </Option>
                    </Select>
                  </div>

                  <Divider className="dividerStyle" />
                  <Form.Item>
                    <Space size="large" className="buttonKeepRight">
                      <Button
                        onClick={() => this.downloadDailyTransactionReport()}
                        variant="contained"
                        color="secondary"
                        disabled={this.state.disableButtonDownload}
                        startIcon={this.state.disableButtonDownload ? <CircularProgress size={20} /> : (this.state.disableButtonDownload ? <Block /> : <GetApp />)}
                      >
                        {this.state.locale_string.download}
                      </Button>                      
                    </Space>
                  </Form.Item>
                </Form>

              </div>
            </div>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.userData.token,
    isMitrago: state.userData.isMitrago,
  };
};

export default compose(connect(mapStateToProps))
  ((DailyTransaction));