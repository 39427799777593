export const USER_MANAGEMENT = {
  USER_MANAGEMENT_NIK: 'NIK',
  USER_MANAGEMENT_FIRSTNAME: 'First Name',
  USER_MANAGEMENT_LASTNAME: 'Last Name',
  USER_MANAGEMENT_USERNAME: 'Username',
  USER_MANAGEMENT_LEVEL: 'Level',
  USER_MANAGEMENT_GENDER: 'Gender',
  USER_MANAGEMENT_EMAIL: 'Email',
  USER_MANAGEMENT_PHONE: 'Phone',
  USER_MANAGEMENT_STATUS: 'Status',
  USER_MANAGEMENT_ACTION: 'Action',

  USER_MANAGEMENT_FORM_ADD_HEADER: "Buat User Baru",
  USER_MANAGEMENT_FORM_EDIT_HEADER: "Ubah User",

  USER_MANAGEMENT_FORM_PLACEHOLDER_USERLEVEL: "Pilih User Level",
  USER_MANAGEMENT_FORM_PLACEHOLDER_PROVINSI: "Pilih Provinsi",
  USER_MANAGEMENT_FORM_PLACEHOLDER_KABUPATEN: "Pilih Kabupaten",
  USER_MANAGEMENT_FORM_PLACEHOLDER_KECAMATAN: "Pilih Kecamatan",
  USER_MANAGEMENT_FORM_PLACEHOLDER_KELURAHAN: "Pilih Kelurahan",
  
  USER_MANAGEMENT_FORM_ERROR_PRINCIPAL_NAME: "Principal Name tidak boleh Kosong",
  USER_MANAGEMENT_FORM_ERROR_USERLEVEL: "Silahkan pilih user level",
  USER_MANAGEMENT_FORM_ERROR_USERNAME: "Silahkan masukan username",
  USER_MANAGEMENT_FORM_ERROR_PATTERN_USERNAME : "Silahkan masukan username minimal 8 karakter, huruf kecil dengan kombinasi 1 huruf dan 1 angka",
  USER_MANAGEMENT_FORM_ERROR_PASSWORD: "Silahkan masukan password",
  USER_MANAGEMENT_FORM_ERROR_PATTERN_PASSWORD: "Silahkan masukan password minimal 8 karakter, kombinasi 1 huruf besar, 1 huruf kecil, 1 angka dan 1 special karakter",
  USER_MANAGEMENT_FORM_ERROR_CONFIRMATION_PASSWORD: "Silahkan masukan confirmation password",
  USER_MANAGEMENT_FORM_ERROR_NOT_MATCH_PASSWORD: "Password dan confirmation password tidak sama",
  USER_MANAGEMENT_FORM_ERROR_FIRSTNAME: "First Name tidak boleh kosong",
  USER_MANAGEMENT_FORM_ERROR_PATTERN_FIRSTNAME : "Silahkan masukan First Name huruf pertama besar, hanya huruf diperbolehkan",
  USER_MANAGEMENT_FORM_ERROR_PATTERN_LASTNAME : "Silahkan masukan Last Name huruf pertama besar, hanya huruf diperbolehkan",
  USER_MANAGEMENT_FORM_ERROR_NULL_EMAIL: "Email tidak boleh kosong",
  USER_MANAGEMENT_FORM_ERROR_VALID_EMAIL: "Silahkan Masukan Email yang valid (e.g: nama@alamatemail.com)",
  USER_MANAGEMENT_FORM_ERROR_NULL_PHONENUMBER: "Phone Number tidak boleh kosong",
  USER_MANAGEMENT_FORM_ERROR_VALID_PHONENUMBER: "Silahkan Masukan Phone Number yang valid (e.g: +628180808888)",
  USER_MANAGEMENT_FORM_ERROR_RT: "Silahkan Isi RT",
  USER_MANAGEMENT_FORM_ERROR_RW: "Silahkan Isi RW",
  USER_MANAGEMENT_FORM_ERROR_PROVINSI: "Silahkan pilih provinsi",
  USER_MANAGEMENT_FORM_ERROR_KABUPATEN: "Silahkan pilih kabupaten",
  USER_MANAGEMENT_FORM_ERROR_KECAMATAN: "Silahkan pilih kecamatan",
  USER_MANAGEMENT_FORM_ERROR_KELURAHAN: "Silahkan pilih kelurahan",
  USER_MANAGEMENT_FORM_ERROR_POSTAL_CODE: "Postal Code tidak boleh kosong",
  USER_MANAGEMENT_FORM_ERROR_NIK: "NIK tidak boleh kosong",
  USER_MANAGEMENT_FORM_ERROR_FOTO_NIK: "Pastikan format foto yang diupload sudah benar (.jpg, .png) dan maximum 2MB",
  USER_MANAGEMENT_FORM_ERROR_CURRENT_PASSWORD: "Silahkan masukan current password",

  USER_MANAGEMENT_FORM_ERROR_FILETYPE_VALIDATION: "Tipe File Salah",
  USER_MANAGEMENT_FORM_ERROR_FILESIZE_VALIDATION: "File lebih dari 2MB",

  USER_MANAGEMENT_MODAL_TITLE_CONFIRMATION_DELETE: "Apakah anda yakin mau hapus user ",
  USER_MANAGEMENT_MODAL_CONTENT_CONFIRMATION_DELETE: "Aksi ini tidak bisa dibatalkan",

  USER_MANAGEMENT_ERROR_LABEL : "Alert",
  USER_MANAGEMENT_ERROR_GET_USERDETAIL : "Gagal mendapatkan user detail",
  USER_MANAGEMENT_ERROR_GET_PROVINCE : "Gagal mendapatkan list provinsi",
  USER_MANAGEMENT_ERROR_GET_KABUPATEN : "Gagal mendapatkan list kabupaten",
  USER_MANAGEMENT_ERROR_GET_KECAMATAN : "Gagal mendapatkan list kecamatan",
  USER_MANAGEMENT_ERROR_GET_KELURAHAN : "Gagal mendapatkan list kelurahan",
  USER_MANAGEMENT_ERROR_UPLOAD : "Gagal Upload File",

  USER_MANAGEMENT_MODAL_TITLE_CONFIRMATION: "Konfirmasi ",
  USER_MANAGEMENT_MODAL_CONTENT_CONFIRMATION_SAVE: "Apakah anda yakin mau simpan user ini ?",
  USER_MANAGEMENT_MODAL_CONTENT_CONFIRMATION_CANCEL_SAVE: "Apakah anda yakin mau batal simpan user ini ?",

  USER_MANAGEMENT_MODAL_TITLE_INFO: "Notifikasi",
  USER_MANAGEMENT_MODAL_CONTENT_INFO_SUCCESS_DEACTIVATE: " berhasil di deaktivasi",

  USER_MANAGEMENT_MODAL_TITLE_INFO_ALERT: "Alert",
  USER_MANAGEMENT_MODAL_CONTENT_INFO_FAILED_DEACTIVATE: " gagal di deaktivasi",

  USER_MANAGEMENT_MODAL_CONTENT_INFO_SUCCESS_ADDUSER: "User berhasil di tambahkan",
  USER_MANAGEMENT_MODAL_CONTENT_INFO_FAILED_ADDUSER: "User gagal di tambahkan",

  USER_MANAGEMENT_MODAL_CONTENT_INFO_SUCCESS_UPDATEUSER: "User berhasil di simpan",
};