export const LOCALIZATION_STRING_ID = {
  loginTitle: "d a s h b o a r d",
  loginUsernameCaption: "Email",
  loginPasswordCaption: "Kata Sandi",
  loginButton: "Masuk",
  loginErrorNoUsernamePassword: "Username dan Password tidak boleh kosong",
  loginErrorNoUsername: "Username tidak boleh kosong",
  loginErrorNoPassword: "Password tidak boleh kosong",
  loginErrorWrongUsername: "Email / Kata Sandi yang dimasukkan salah.",
  loginErrorNoConnection:
    "Tidak ada koneksi internet, harap periksa sambungan anda.",
  dashboardPeriode: "Periode",
  dashboardDateFrom: "Dari",
  dashboardDateUntil: "Sampai",
  dashboardLogout: "Keluar",
  dashboardTotalTransactionTitle: "Transaksi Total",
  dashboardTotalTransactionCaption: "Transaksi Total",
  dashboardMillion: "Juta",
  dashboardTotalAmount: "Jumlah Total",
  dashboardTransactionFailedTitle: "Transaksi Gagal",
  dashboardTransactionFailedCaption: "Transaksi Gagal",
  dashboardStockout: "Stockout",
  dashboardCreditLimit: "Credit Limit",
  dashboardAllocation: "Alokasi",
  dashboardRejected: "Ditolak Toko",
  dashboardDropSizeTitle: "Drop Size",
  dashboardJumlahSKU: "Jumlah SKU",
  dashboardRataInvoice: "Rata-rata Nilai Invoice",
  dashboardSideReport: "Laporan",
  dashboardDailyTransaction: "Daftar Transaksi Harian",
  dashboardDailyTransactionDetail: "Daftar Transaksi Harian Detail",
  dashboardDailyTransactionBySeller: "Daftar Transaksi Harian Per Seller",
  dashboardDailyTransactionByBuyerSeller:
    "Daftar Transaksi Harian Per Buyer dan Seller",
  dashboardSideActiveBuyer: "Daftar Pembeli Aktif",
  dashboardSideActiveSeller: "Daftar Penjual Aktif",
  dashboardFulfillmentRate: "Fulfillment Rate",
  dashboardCustomerRegistration: "Registrasi Customer",
  dashboardReportNotAvailable: "Fitur Laporan Belum Tersedia",
  downloadFailed: "Gagal Unduh",
  allDistributor: "Semua Merchant",
  allPrincipal: "Semua Principal",
  downloadPdf: "Unduh Pdf",
  downloadCsv: "Unduh Csv",
  downloadExcel: "Unduh Excel",
  formatChoosen: "Pilihan Format",
  voucher: "Voucher",
  voucherList: "Daftar Voucher",
  download: "Unduh",
  distributor: "Merchant",
  principal: "Principal",
  pdf: "PDF",
  csv: "CSV",
  excel: "EXCEL",
  dashboardVoucher: "Voucher",
  dashboardVoucherList: "Daftar Voucher",
  dashboardVoucherTotal: "Voucher Total",
  dashboardVoucherDistributed: "Voucher Terdistribusi",
  dashboardVoucherUndistributed: "Voucher Belum Terdistribusi",
  dashboardVoucherRedeemed: "Voucher Ditukarkan",
  dashboardHome: "Dashboard",
  voucherName: "Nama Voucher",
  voucherDetail: "Detail Voucher",
  activePromo: "Hanya tampilkan promo yang aktif",
  voucherDistribution: "Distribusi Voucher",
  autoDistribution: "Distribusi Otomatis",
  withScheduler: "Menggunakan Scheduler",
  tnc: "Syarat dan Ketentuan",
  of: " dari ",
  redeemed: "Redeemed",
  voucherCode: "Tabel Voucher",
  downloadVoucher: "Unduh Laporan",
  sendVoucher: "Kirim Voucher",
  validDistrict: "Kabupaten",
  validSubDistrict: "Kecamatan",
  validUrbanVillage: "Kelurahan",
  emptyAlert: "Mohon pilih distributor dan voucher",
  allVoucher: "Semua Voucher",
  systemConfiguration: "Konfigurasi Sistem",
  userManagement: "Manajemen Pengguna",
  userProfile: "Profil Pengguna",

  searchFieldUserManagement: "Cari berdasarkan Nama atau Username...",
  createNewUser: "BUAT USER BARU",
  createButton: "SIMPAN",
  cancelButton: "BATAL",
  activeGromateButton: "AKTIVASI GROMATE",
  changePasswordButton: "UBAH PASSWORD",
  editButton: "UBAH",
  deleteButton: "HAPUS",
  nikModalTitle: "Foto NIK",

  yesButton: "Ya",
  noButton: "Tidak",
  okButton: "Ok",
};
