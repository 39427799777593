import React from "react";
import styles from "./loginV2.module.css";
import LoginService from "./../../services/loginService";
import { connect } from "react-redux";
import {
  LOCALIZATION_STRING_EN,
  LOCALIZATION_STRING_ID,
} from "./../../localization_string/localization_global";
import { LOGIN_PAGE } from "../../_constants/loginPage.constants";
import HomePage from "./homePage";
import Feature from "./feature";
import ContactUs from "./contactUs";
import Logo from "./../../support/homepage/Logo.png";
import Login from "./../../support/homepage/Login@2x.png";
import { Navbar, Nav, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Footer from "./footer";
import { userReducerAction } from "../../store/reducers/userReducer"
import CircularProgress from '@material-ui/core/CircularProgress';

class LoginV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      locale: "id",
      usernameInput: "",
      passwordInput: "",
      loginPage: LOGIN_PAGE.HOME,
      locale_string: {},
      dialogErrorIsOpen1: false,
      dialogErrorIsOpen2: false,
      closeDialogError: false,
      isLoading: false,
    };
  }

  getLocaleString = () => {
    if (this.state.locale === "id") {
      this.setState({ locale_string: LOCALIZATION_STRING_ID });
    } else {
      this.setState({ locale_string: LOCALIZATION_STRING_EN });
    }
  };

  componentDidMount() {
    this.getLocaleString();
  }

  onLogin = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    let username = this.state.usernameInput;
    let password = this.state.passwordInput;
    if (username === "" && password === "") {
      this.setState({
        error: this.state.locale_string.loginErrorNoUsernamePassword,
      });
    } else if (username === "") {
      this.setState({ error: this.state.locale_string.loginErrorNoUsername });
    } else if (password === "") {
      this.setState({ error: this.state.locale_string.loginErrorNoPassword });
    } else {
      LoginService.loginNgChat(username, password)
        .then((response) => {
          this.setState({ isLoading: false });
          if (response.data.status === 1000037) {
            this.openDialogError1(true);
            return;
          }

          if (response.data.status !== 1) {
            this.openDialogError1(true);
            return;
          }

          if (response.data.status === 1) {
            let data = response.data.data;
            this.props.dispatch(userReducerAction.getLoginData(data));
            this.props.history.push("/dashboard");
            return;
          }

          throw Error;
        })
        .catch((error) => {
            this.setState({ isLoading: false });
            this.openDialogError2(true)
        });
    }
  };

  getComponent = () => {
    switch (this.state.loginPage) {
      case LOGIN_PAGE.FEATURE:
        return (
          <>
            <Feature /> <Footer />
          </>
        );
      case LOGIN_PAGE.CONTACT_US:
        return (
          <>
            <ContactUs /> <Footer />
          </>
        );
      default:
        return <HomePage />;
    }
  };

  onClickLogo = () => {
    this.setState({ loginPage: LOGIN_PAGE.HOME });
  };

  onClickFeature = () => {
    this.setState({ loginPage: LOGIN_PAGE.FEATURE });
  };

  onClickContactUs = () => {
    this.setState({ loginPage: LOGIN_PAGE.CONTACT_US });
  };

  onChangeUserName = (e) => {
    this.setState({
      usernameInput: e.target.value,
    });
  };

  onChangePassword = (e) => {
    this.setState({
      passwordInput: e.target.value,
    });
  };

  openDialogError1 = () => {
    this.setState({ dialogErrorIsOpen1: true });
  };

  openDialogError2 = () => {
    this.setState({ dialogErrorIsOpen2: true });
  };

  closeDialogError = () => {
    this.setState({ dialogErrorIsOpen1: false });
    this.setState({ dialogErrorIsOpen2: false });
  };

  render() {
    return (
      <div className={styles.container}>
        <Navbar collapseOnSelect expand="xl">
          <Navbar.Brand>
            <Link to="/" className="nav-link">
              <img
                alt=""
                height="50vw"
                src={Logo}
                className="d-inline-block align-top"
                onClick={() => this.onClickLogo()}
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            style={{ margin: "0 2rem" }}
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ms-auto">
              {this.state.loginPage === LOGIN_PAGE.HOME ? (
                <div className={styles.headerRightMenu}>
                  <div
                    className={styles.feature}
                    onClick={() => this.onClickFeature()}
                  >
                    Feature
                  </div>
                  <div
                    className={styles.contactUs}
                    onClick={() => this.onClickContactUs()}
                  >
                    Contact us
                  </div>
                </div>
              ) : this.state.loginPage === LOGIN_PAGE.FEATURE ? (
                <div className={styles.headerRightMenu}>
                  <div
                    className={styles.featureUnderline}
                    onClick={() => this.onClickFeature()}
                  >
                    Feature
                  </div>
                  <div
                    className={styles.contactUs}
                    onClick={() => this.onClickContactUs()}
                  >
                    Contact us
                  </div>
                </div>
              ) : (
                <div className={styles.headerRightMenu}>
                  <div
                    className={styles.feature}
                    onClick={() => this.onClickFeature()}
                  >
                    Feature
                  </div>
                  <div
                    className={styles.contactUsUnderline}
                    onClick={() => this.onClickContactUs()}
                  >
                    Contact us
                  </div>
                </div>
              )}
              <form onSubmit={this.onLogin}>
                <div className={styles.loginForm}>
                  <div className={styles.loginUsernameContainer}>
                    <FormControl
                      type="text"
                      value={this.state.usernameInput}
                      onChange={(e) => this.onChangeUserName(e)}
                      inputProps={{ className: styles.loginUsernameInputText }}
                      variant={"outlined"}
                      placeholder={"Username"}
                      className={styles.loginUsernameInputText}
                    />
                  </div>
                  <div className={styles.loginPasswordContainer}>
                    <FormControl
                      type="password"
                      value={this.state.passwordInput}
                      onChange={(e) => this.onChangePassword(e)}
                      inputProps={{ className: styles.loginPasswordInputText }}
                      variant={"outlined"}
                      placeholder={"Password"}
                      className={styles.loginPasswordInputText}
                    />
                  </div>
                  {this.state.isLoading ? (
                    <div className={styles.iconLogin}>
                      <CircularProgress style={{ color: '#9a0c19' }} />
                    </div>
                  ) : (
                    <input
                      type="image"
                      alt={"login"}
                      src={Login}
                      className={styles.iconLogin}
                      onClick={this.onLogin}
                    />
                  )}
                </div>
              </form>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className={styles.body}>{this.getComponent()}</div>
        <Dialog
          open={this.state.dialogErrorIsOpen1}
          onClose={this.closeDialogError}
        >
          <DialogContent className={styles.error}>
            <div>ERROR!</div>
            <div>({this.state.locale_string.loginErrorWrongUsername})</div>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.dialogErrorIsOpen2}
          onClose={this.closeDialogError}
        >
          <DialogContent className={styles.error}>
            <div>ERROR!</div>
            <div>({this.state.locale_string.loginErrorNoConnection})</div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.userData.token,
    userID: state.userData.userID,
    status: state.userData.status,
  };
};

export default connect(mapStateToProps)(LoginV2);
