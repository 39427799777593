import React from 'react'
import styles from './inputOTPCode.module.css'
import {TextField} from "@material-ui/core";

class InputOTPCode extends React.Component{
    render(){
        return(
            <div className={styles.container}>
                <div className={styles.marginTop}></div>
                <div className={styles.formContainer}>
                    <div className={styles.inputOTPCaption}><p>Masukkan kode yang dikirimkan ke</p>
                        <p>email yang ditulis</p></div>
                    <div className={styles.otpCodeContainer}>
                        <div className={styles.otpCodeTextContainer}>
                            <TextField className={styles.otpCodeTextField} placeholder={0} inputProps={{ maxLength: 1, style: {fontSize: 40} }}/>
                        </div>
                        <div className={styles.otpCodeTextContainer}>
                            <TextField className={styles.otpCodeTextField} placeholder={0} inputProps={{ maxLength: 1,  style: {fontSize: 40}  }}/>
                        </div>
                        <div className={styles.otpCodeTextContainer}>
                            <TextField className={styles.otpCodeTextField} placeholder={0} inputProps={{ maxLength: 1,  style: {fontSize: 40}  }}/>
                        </div>
                        <div className={styles.otpCodeTextContainer}>
                            <TextField className={styles.otpCodeTextField} placeholder={0} inputProps={{ maxLength: 1,  style: {fontSize: 40}  }}/>
                        </div>
                    </div>
                    <div className={styles.resendOTPCaption}>
                        Tunggu 50 detik untuk mengirim ulang kode
                    </div>
                    <div className={styles.sendOTPButtonContainer}>
                        <input type="button" value="Masuk" className={styles.otpSendButton}/>
                    </div>
                </div>
            </div>
        )
    }
}
export default InputOTPCode
