import { createSlice } from "@reduxjs/toolkit";
import { LOCALIZATION_STRING_EN, LOCALIZATION_STRING_ID } from "../../localization_string/localization_global";

const INITIAL_STATE = { locale: "", locale_string: {} };

const localeReducer = createSlice({
    name: "Locale",
    initialState: INITIAL_STATE,
    reducers: {
        setLocaleEN(state) {
            state.locale = "en";
            state.locale_string = { LOCALIZATION_STRING_EN };
        },
        setLocaleID(state) {
            state.locale = "id";
            state.locale_string = { LOCALIZATION_STRING_ID };
        }
    }
});

export const localeReducerAction = localeReducer.actions;

export default localeReducer.reducer;