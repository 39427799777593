import React, { Component } from "react";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import { Box, Center, Flex, Spacer } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { Select } from "antd";

class CustomPagination extends Component {
  constructor(props) {
    super(props);
    this.state = { pageSizeOptions: [10, 20, 50, 100], totalPageCount: 1 };
  }

  async componentDidMount() {
    this.getPaging(this.props.pagination.total, this.props.pagination.pageSize);
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.pagination !== this.props.pagination) {
      this.getPaging(
        this.props.pagination.total,
        this.props.pagination.pageSize
      );
    }
  }

  getPaging = async (total, pageSize) => {
    let totalPageCount = Math.ceil(total / pageSize);
    this.setState({ totalPageCount });
  };

  handlePagination = (currentIndex, currentSize) => {
    this.props.onPagination(currentIndex, currentSize);
  };

  render() {
    return (
      <>
        <Flex bg="#EDF0F5" pl="10" pr="10">
          <Center p="10" color="#808495">
            {this.props.pagination.current} of {this.state.totalPageCount}
          </Center>
          <Center p="10">
            <Select
              defaultValue={this.props.pagination.pageSize}
              onChange={(value) => this.handlePagination(1, Number(value))}
            >
              {this.state.pageSizeOptions.map((pageSize) => (
                <Select.Option key={pageSize} value={pageSize}>
                  {pageSize}
                </Select.Option>
              ))}
            </Select>
          </Center>
          <Spacer />
          <Center p="10" color="#808495">
            Page {this.props.pagination.current}
          </Center>
          <Box p="10" borderLeft="1px solid rgba(0,0,0, .2)">
            <Button
              marginY="1"
              padding="0"
              border="none"
              backgroundColor="transparent"
              _active={{ backgroundColor: "#EDF2F7" }}
              onClick={() =>
                this.handlePagination(
                  this.props.pagination.current - 1,
                  this.props.pagination.pageSize
                )
              }
              disabled={this.props.pagination.current === 1}
            >
              <ArrowLeftIcon />
            </Button>
          </Box>
          <Box p="10" borderLeft="1px solid rgba(0,0,0, .2)">
            <Button
              marginY="1"
              padding="0"
              border="none"
              backgroundColor="transparent"
              _active={{ backgroundColor: "#EDF2F7" }}
              onClick={() =>
                this.handlePagination(
                  this.props.pagination.current + 1,
                  this.props.pagination.pageSize
                )
              }
              disabled={
                this.props.pagination.current === this.state.totalPageCount
              }
            >
              <ArrowRightIcon />
            </Button>
          </Box>
        </Flex>
      </>
    );
  }
}

export default CustomPagination;
