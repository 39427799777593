import React from "react";
import {
  LOCALIZATION_STRING_EN,
  LOCALIZATION_STRING_ID,
} from "../../localization_string/localization_global";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import {
  Form,
  Space,
  Input,
  Button,
  Divider
}
  from "antd";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { USER_PROFILE } from "../../_constants/userProfile.constants";
import UserProfileService from "../../services/userProfileService";
import { errorNotificationAlert } from "../notificationAlert/notificationAlert";
import { modalConfirmation, modalInfo } from "../modalCustom/modalCustom";

const baseUrl = "/dashboard/userprofile"

class UserProfileChangePasswordForm extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      locale: "id",
      locale_string: {},
      headerLabel: "",
      form: {
        user_id: null,
        principalName: null,
        username: null,
        old_password: null,
        new_password: null,
        confirm_new_password: null
      }
    };
  }

  componentDidMount() {
    let token = this.props.token;
    let authClientId = this.props.authClientId;
    this.getLocaleString();
    UserProfileService.getUserDetail(token, authClientId).then((response) => {
      let userDetailResponse = response.data.additionalEntity.result;
      this.setState({
        form: {
          ...this.state.form,
          user_id: userDetailResponse.principal_id,
          username: userDetailResponse.gromart_user_name,
        }
      });
      this.formRef.current.setFieldsValue({
        principalName: userDetailResponse.principal_name,
        username: userDetailResponse.gromart_user_name
      });
    }).catch((error) => {
      errorNotificationAlert(
        USER_PROFILE.USER_PROFILE_GET_DETAIL_USER_FAILED_TITLE,
        USER_PROFILE.USER_PROFILE_GET_DETAIL_USER_FAILED_CONTENT
      );
    })
  }

  getLocaleString = () => {
    if (this.state.locale === "id") {
      this.setState({ locale_string: LOCALIZATION_STRING_ID });
    } else {
      this.setState({ locale_string: LOCALIZATION_STRING_EN });
    }
  };

  handleChange(event) {
    let fieldName = event.target.name;
    let fieldValue = event.target.value;
    this.setState({ form: { ...this.state.form, [fieldName]: fieldValue } });
  }

  cancelEditPassword() {
    this.props.history.push(baseUrl);
  }

  handleCancelEditPasswordConfirmation() {
    modalConfirmation(
      USER_PROFILE.USER_PROFILE_MODAL_TITLE_CONFIRMATION,
      USER_PROFILE.USER_PROFILE_MODAL_CONTENT_CONFIRMATION_CANCEL_EDIT_PASSWORD,
      this.state.locale_string.yesButton,
      this.state.locale_string.noButton,
      () => this.cancelEditPassword()
    );
  }

  updatePasswordUser() {
    let token = this.props.token;
    let dataForm = this.state.form;
    UserProfileService.updatePassword(token, dataForm).then((response) => {
      modalInfo(
        USER_PROFILE.USER_PROFILE_MODAL_TITLE_INFO,
        USER_PROFILE.USER_PROFILE_MODAL_CONTENT_INFO_SUCCESS_UPDATE_PASSWORD,
        this.state.locale_string.okButton
      );
      this.cancelEditPassword();
    }).catch((error) => {
      modalInfo(
        USER_PROFILE.USER_PROFILE_MODAL_TITLE_INFO_ALERT,
        error.response.data.description,
        this.state.locale_string.okButton
      );
    });
  }

  submitSuccessForm() {
    modalConfirmation(
      USER_PROFILE.USER_PROFILE_MODAL_TITLE_CONFIRMATION,
      USER_PROFILE.USER_PROFILE_MODAL_CONTENT_CONFIRMATION_USER_EDIT_PASSWORD,
      this.state.locale_string.yesButton,
      this.state.locale_string.noButton,
      () => this.updatePasswordUser()
    );
  }

  render() {
    const { form } = this.state;
    return (
      <>
        <Row className="justify-content-md-center">
          <Col xs={12} md={12} style={{ marginTop: "10px" }}>

            <div className="pageTitle">
              {USER_PROFILE.USER_PROFILE_FORM_EDIT_HEADER}
            </div>

            <div className="white-box white-box-usermanagement">
              <div>
                <Form
                  initialValues={{ form }}
                  ref={this.formRef}
                  onFinish={() => this.submitSuccessForm()}
                >

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle">
                        Principal Name
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: USER_PROFILE.USER_PROFILE_FORM_ERROR_PRINCIPAL_NAME,
                          },
                        ]}
                        name="principalName"
                      >
                        <Input
                          name="principalName"
                          value={this.state.form.principalName}
                          onChange={(event) => this.handleChange(event)}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle">
                        Username
                      </div>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="username"
                        rules={[
                          {
                            message: USER_PROFILE.USER_PROFILE_FORM_ERROR_USERNAME,
                            required: true,
                            pattern: new RegExp(/^(?=.*[a-z])(?=.*\d)[a-z\d]{8,}$/),
                            validateTrigger: "onSubmit"
                          },
                        ]}
                      >
                        <Input
                          value={this.state.form.username}
                          name="username"
                          onChange={(event) => this.handleChange(event)}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle">
                        Kata sandi sekarang
                      </div>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="old_password"
                        rules={[
                          {
                            message: USER_PROFILE.USER_PROFILE_CHANGE_PASSWORD_FORM_ERROR_CURRENT_PASSWORD,
                            required: true,
                            validateTrigger: "onSubmit"
                          },
                        ]}
                      >
                        <Input
                          value={this.state.form.old_password}
                          name="old_password"
                          onChange={(event) => this.handleChange(event)}
                          type="password"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle">
                        Kata sandi baru
                      </div>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="new_password"
                        rules={[
                          {
                            message: USER_PROFILE.USER_PROFILE_CHANGE_PASSWORD_FORM_ERROR_PASSWORD,
                            required: true,
                            validateTrigger: "onSubmit"
                          },
                          {
                            pattern: new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/),
                            message: USER_PROFILE.USER_PROFILE_FORM_ERROR_PATTERN_PASSWORD,
                          },
                        ]}
                      >
                        <Input
                          value={this.state.form.new_password}
                          name="new_password"
                          onChange={(event) => this.handleChange(event)}
                          type="password"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={3}>
                      <div className="formTitle">
                        Konfirmasi kata sandi baru
                      </div>
                    </Col>
                    <Col xs={12} md={7}>
                      <Form.Item
                        name="confirm_new_password"
                        rules={[
                          {
                            message: USER_PROFILE.USER_PROFILE_CHANGE_PASSWORD_FORM_ERROR_CONFIRMATION_PASSWORD,
                            required: true,
                            validateTrigger: "onSubmit"
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue("new_password") === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(USER_PROFILE.USER_PROFILE_CHANGE_PASSWORD_FORM_ERROR_NOT_MATCH_PASSWORD));
                            },
                          }),
                        ]}
                      >
                        <Input
                          value={this.state.form.confirm_new_password}
                          name="confirm_new_password"
                          onChange={(event) => this.handleChange(event)}
                          type="password"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Divider className="dividerStyle" />
                  <Form.Item>
                    <Space size="large" className="buttonKeepRight">
                      <Button
                        onClick={() => this.handleCancelEditPasswordConfirmation()}
                        className="buttonCancelMUIStyle"
                        danger
                      >
                        {this.state.locale_string.cancelButton}
                      </Button>
                      <Button
                        type="primary"
                        danger
                        className="buttonCreateMUIStyle"
                        htmlType="submit"
                      >
                        {this.state.locale_string.createButton}
                      </Button>
                    </Space>
                  </Form.Item>

                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.userData.token,
    userID: state.userData.userID,
    status: state.userData.status,
    authClientId: state.userData.authClientId,
  };
};

export default compose(connect(mapStateToProps), withRouter)
  ((UserProfileChangePasswordForm));