import { Modal } from "antd";

export const modalConfirmation = (title, content, okText, cancelText, okFunction) => {
  const { confirm } = Modal;
  confirm({
    title: title,
    content: content,
    okText: okText,
    icon: null,
    cancelText: cancelText,
    centered: true,
    okType: "danger",
    onOk() { okFunction() },
  });
}

export const modalInfo = (title, content, okText) => {
  const { success } = Modal;
  success({
    title: title,
    content: content,
    okText: okText,
    icon: null,
    centered: true,
    okType: "danger",
  });
}