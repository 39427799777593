import React from 'react'
import styles from './contactUs.module.css'
import {MdMail, MdLocationOn, MdPhone, MdAccessTime} from 'react-icons/md'

class ContactUs extends React.Component {

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.left}>
                    <p>Welcome to the</p>
                    <p>Gromart Contact Center</p>
                    <p>We're here to help</p>
                </div>
                <div className={styles.right}>
                    <div className={styles.table}>
                        <div className={styles.content}>
                            <div className={styles.icon}>
                                <MdMail size={30}/>
                            </div>
                            <div>sales@gromart.net</div>
                        </div>

                        <div className={styles.content}>
                            <div className={styles.icon}>
                                <MdPhone size={30}/>
                            </div>
                            <div>
                                <span>+62 21 2222 0138 (CS)</span><br/>
                                <span>+62 21 2222 0139 (CS)</span><br/>
                                <span>+62 812 9357 1403 (WA)</span><br/>
                            </div>
                        </div>

                        <div className={styles.content}>
                            <div className={styles.icon}>
                                <MdAccessTime size={30}/>
                            </div>
                            <div>08:30 - 17:30 WIB</div>
                        </div>

                        <div className={styles.content}>
                            <div className={styles.icon}>
                                <MdLocationOn size={30}/>
                            </div>
                            <div>
                                <span>PT. Mitrada Prima Nusantara</span><br/>
                                <span>Scientia Business Park Tower 2 Lt. 2</span><br/>
                                <span>Boulevard Gading Serpong Blok O/2</span><br/>
                                <span>Tangerang 15810, Indonesia</span><br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContactUs;
