import { apiServer } from "./../config/AppConfig";
import axios from "axios";

function setHeaderToken(token) {
  return {
    "X-AUTH-TOKEN-GC": token,
  };
}

const DashboardService = {
  getDistributorList(token) {
    return axios({
      method: "get",
      url: apiServer + "/lo/sif/distributor/allRealActiveList",
      timeout: 5000,
      headers: setHeaderToken(token),
      crossOrigin: true,
    });
  },

  getTotalVoucher(startDate, endDate, token) {
    return axios({
      method: "get",
      url: apiServer + "/voucher/dashboard/total",
      timeout: 5000,
      headers: setHeaderToken(token),
      crossOrigin: true,
      params: {
        startDate: startDate,
        endDate: endDate,
      },
    });
  },

  getTotalTransaction(startDate, endDate, token) {
    return axios({
      method: "get",
      url: apiServer + "/lo/report/totalTransaction",
      timeout: 5000,
      headers: setHeaderToken(token),
      crossOrigin: true,
      params: {
        startDate: startDate,
        endDate: endDate,
      },
    });
  },

  getFailedTransactionCounter(startDate, endDate, token) {
    return axios({
      method: "get",
      url: apiServer + "/lo/report/failed/count",
      timeout: 5000,
      headers: setHeaderToken(token),
      crossOrigin: true,
      params: {
        startDate: startDate,
        endDate: endDate,
      },
    });
  },
  getFailedTransactionDetail(startDate, endDate, token) {
    return axios({
      method: "get",
      url: apiServer + "/lo/report/failed/detail",
      timeout: 5000,
      params: {
        startDate: startDate,
        endDate: endDate,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
    });
  },
  getDropSizeSKU(startDate, endDate, token) {
    return axios({
      method: "get",
      url: apiServer + "/lo/report/dropSize/sku",
      timeout: 5000,
      params: {
        startDate: startDate,
        endDate: endDate,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
    });
  },
  getDropSizeInvoice(startDate, endDate, token) {
    return axios({
      method: "get",
      url: apiServer + "/lo/report/dropSize/invoice",
      timeout: 5000,
      params: {
        startDate: startDate,
        endDate: endDate,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
    });
  },

  downloadDailyTransactionPdfReport(token, startDate, endDate, sellerId) {
    if (sellerId === "") sellerId = null;
    return axios({
      method: "get",
      url: apiServer + "/lo/report/dailyTransaction/pdf",
      params: {
        startDate: startDate,
        endDate: endDate,
        sellerId: sellerId,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadDailyTransactionCsvReport(token, startDate, endDate, sellerId) {
    if (sellerId === "") sellerId = null;
    return axios({
      method: "get",
      url: apiServer + "/lo/report/dailyTransaction/csv",
      params: {
        startDate: startDate,
        endDate: endDate,
        sellerId: sellerId,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadDailyTransactionDetailPdfReport(token, startDate, endDate, sellerId) {
    if (sellerId === "") sellerId = null;
    return axios({
      method: "get",
      url: apiServer + "/lo/report/dailyTransaction/detail/pdf",
      params: {
        startDate: startDate,
        endDate: endDate,
        sellerId: sellerId,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadDailyTransactionDetailCsvReport(token, startDate, endDate, sellerId) {
    if (sellerId === "") sellerId = null;
    return axios({
      method: "get",
      url: apiServer + "/lo/report/dailyTransaction/detail/csv",
      params: {
        startDate: startDate,
        endDate: endDate,
        sellerId: sellerId,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadDailyTransactionBySellerPdfReport(
    token,
    startDate,
    endDate,
    sellerId
  ) {
    if (sellerId === "") sellerId = null;
    return axios({
      method: "get",
      url: apiServer + "/lo/report/dailyTransaction/seller/pdf",
      params: {
        startDate: startDate,
        endDate: endDate,
        sellerId: sellerId,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadDailyTransactionBySellerCsvReport(
    token,
    startDate,
    endDate,
    sellerId
  ) {
    if (sellerId === "") sellerId = null;
    return axios({
      method: "get",
      url: apiServer + "/lo/report/dailyTransaction/seller/csv",
      params: {
        startDate: startDate,
        endDate: endDate,
        sellerId: sellerId,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadActiveBuyerPdfReport(token, startDate, endDate) {
    return axios({
      method: "get",
      url: apiServer + "/lo/report/buyer/active/pdf",
      params: {
        startDate: startDate,
        endDate: endDate,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadActiveBuyerCsvReport(token, startDate, endDate) {
    return axios({
      method: "get",
      url: apiServer + "/lo/report/buyer/active/csv",
      params: {
        startDate: startDate,
        endDate: endDate,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadActiveSellerPdfReport(token, startDate, endDate) {
    return axios({
      method: "get",
      url: apiServer + "/lo/report/seller/active/pdf",
      params: {
        startDate: startDate,
        endDate: endDate,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadActiveSellerCsvReport(token, startDate, endDate) {
    return axios({
      method: "get",
      url: apiServer + "/lo/report/seller/active/csv",
      params: {
        startDate: startDate,
        endDate: endDate,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadFulfillmentRatePdfReport(token, startDate, endDate, sellerId) {
    if (sellerId === "") sellerId = null;
    return axios({
      method: "get",
      url: apiServer + "/lo/report/fulfillmentRate/pdf",
      params: {
        startDate: startDate,
        endDate: endDate,
        sellerId: sellerId,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadFulfillmentRateCsvReport(token, startDate, endDate, sellerId) {
    if (sellerId === "") sellerId = null;
    return axios({
      method: "get",
      url: apiServer + "/lo/report/fulfillmentRate/csv",
      params: {
        startDate: startDate,
        endDate: endDate,
        sellerId: sellerId,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadDailyTransactionByBuyerSellerPdfReport(
    token,
    startDate,
    endDate,
    sellerId
  ) {
    if (sellerId === "") sellerId = null;
    return axios({
      method: "get",
      url: apiServer + "/lo/report/dailyTransaction/buyerSeller/pdf",
      params: {
        startDate: startDate,
        endDate: endDate,
        sellerId: sellerId,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },

  downloadDailyTransactionByBuyerSellerCsvReport(
    token,
    startDate,
    endDate,
    sellerId
  ) {
    if (sellerId === "") sellerId = null;
    return axios({
      method: "get",
      url: apiServer + "/lo/report/dailyTransaction/buyerSeller/csv",
      params: {
        startDate: startDate,
        endDate: endDate,
        sellerId: sellerId,
      },
      headers: setHeaderToken(token),
      crossOrigin: true,
      responseType: "blob", // important
    });
  },
};

export default DashboardService;
